import * as actions from './actions';

export default (state, action) => {
    switch (action.type) {
        case actions.SET_SELECTED_SITE:
            return {
                ...state,
                selected_site: action.payload,
            };
        case actions.SET_SECONDARY_SITE:
            return {
                ...state,
                secondary_site: action.payload,
            };
        case actions.SET_FILTERED_SITES:
            return {
                ...state,
                filtered_sites: action.payload,
            };
        case actions.SET_CATEGORY:
            return {
                ...state,
                category: action.payload,
            };
        default:
            return state;
    }
};
