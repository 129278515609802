import * as actions from './actions';

export default (state, action) => {
    switch (action.type) {
        case actions.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case actions.SET_CACHED_SET:
            return {
                ...state,
                cache: action.payload,
            };
        case actions.SET_DB_QUERY:
            return {
                ...state,
                query: action.payload,
            };
        default:
            return state;
    }
};
