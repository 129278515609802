import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Badge,
    Button,
    Collapse,
    Container,
    Form,
    Modal,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
    SoilAvailabilityEditForm,
    SoilAvailabilityForm,
} from '../Forms/AvailabilityRequest/Availability';
import { AvailabilityFormProvider } from '../Forms/AvailabilityRequest/context/AvailabilityContext';
import {
    SoilRequestEditForm,
    SoilRequestForm,
} from '../Forms/AvailabilityRequest/Request';
import { RequestFormProvider } from '../Forms/AvailabilityRequest/context/RequestContext';
import { SoilInput } from '../Forms/Shared/Inputs/SoilInput';
import { CY_TO_TN_FACTOR } from '../../utils';
import {
    availabilityAmountFormatter,
    numberCellFormatter,
    requestAmountFormatter,
} from './helpers';
import { runQuery } from '../../api';
import { GlobalContext } from '../../context/GlobalState';
import { API_URL } from '../../api/queries';

export const getSoilInputs = (s, a, fetchStockpile) => {
    const v = new Map();

    if (a) {
        s.soil_type_availabilities.map(
            ({
                chem_char,
                soil_type,
                amount,
                adjusted_amount,
                stockpile_amount,
            }) => {
                let final_amount = amount;
                if (fetchStockpile === true) final_amount = stockpile_amount;
                if (s.satisfied === true) final_amount = adjusted_amount;
                const n = new Map(v.get(soil_type.name));
                n.has(chem_char.name) && !final_amount
                    ? n.delete(chem_char.name)
                    : n.set(
                          chem_char.name,
                          (n.get(chem_char.name) || 0) + final_amount
                      );

                v.set(soil_type.name, n);
            }
        );
    } else {
        s.soil_type_requests.map(
            ({
                chem_char,
                soil_type,
                amount,
                adjusted_amount,
                stockpile_amount,
            }) => {
                let final_amount = amount;
                if (fetchStockpile === true) final_amount = stockpile_amount;
                if (s.satisfied === true) final_amount = adjusted_amount;
                const n = new Map(v.get(soil_type.name));
                n.has(chem_char.name) && !final_amount
                    ? n.delete(chem_char.name)
                    : n.set(
                          chem_char.name,
                          (n.get(chem_char.name) || 0) + final_amount
                      );

                v.set(soil_type.name, n);
            }
        );
    }

    return v;
};

export const SiteAvailabilityRequest = ({ site }) => {
    /**
     * INFO: react-boostrap-table-next
     * can't have two columns with the same
     * dataField or it makes a key conflict
     * and makes noise in the console.
     */

    const { setQuery } = useContext(GlobalContext);

    const modes = {
        CREATING_AVAILABILITY: 'CREATING_AVAILABILITY',
        CREATING_REQUEST: 'CREATING_REQUEST',
        EDITING_REQUEST: 'EDITING_REQUEST',
        DELETING_REQUEST: 'DELETING_REQUEST',
        EDITING_AVAILABILITY: 'EDITING_AVAILABILITY',
        DELETING_AVAILABILITY: 'DELETING_AVAILABILITY',
        APPROVING_REQUEST: 'APPROVING_REQUEST',
        APPROVING_AVAILABILITY: 'APPROVING_AVAILABILITY',
        SATISFYING_REQUEST: 'SATISFYING_REQUEST',
        SATISFYING_AVAILABILITY: 'SATISFYING_AVAILABILITY',
    };
    const [modal, setModal] = useState(false);
    const [mode, setMode] = useState(null);
    const [data, setData] = useState(null);
    const [soil, setSoil] = useState(new Set());
    const [deferred, setDeferred] = useState(false);
    const [approval_type, setApprovalType] = useState(null);
    const [approval_date, setApprovalDate] = useState(
        new Date().toISOString().split('T')[0]
    );
    const [satisfied_date, setSatisfiedDate] = useState(
        new Date().toISOString().split('T')[0]
    );
    const [csb_eligible, setCsbEligible] = useState(false);
    const [stockpile_eligible, setStockpileEligible] = useState(false);

    site.dupe_id = site.id;

    const { availabilities, requests } = site;

    if (requests) {
        requests.map((r) => {
            r.soil_requested_tn = (r.soil_requested * CY_TO_TN_FACTOR).toFixed(
                2
            );
            return r;
        });
    }
    if (availabilities) {
        availabilities.map((r) => {
            r.soil_available_tn = (r.soil_available * CY_TO_TN_FACTOR).toFixed(
                2
            );
            return r;
        });
    }

    const editButtonFormatter = (cell, row) => {
        return (
            <Button
                onClick={() => {
                    setData(row);
                    setDeferred(row.deferred);
                    setMode(
                        row.soil_type_availabilities
                            ? modes.EDITING_AVAILABILITY
                            : modes.EDITING_REQUEST
                    );
                    setModal(true);
                }}
                variant='info'
                id={`edit-button-${cell}`}
            >
                Edit
            </Button>
        );
    };

    const deleteButtonFormatter = (cell, row) => {
        return (
            <Button
                variant='danger'
                onClick={() => {
                    setData(row);
                    setMode(
                        row.soil_type_availabilities
                            ? modes.DELETING_AVAILABILITY
                            : modes.DELETING_REQUEST
                    );
                    setModal(true);
                }}
                id={`delete-button-${cell}`}
            >
                Delete
            </Button>
        );
    };

    // On QEP Approval, an Availability/Request's Amount of Soil
    // is Updated through the API and is Allowed to Be Counted Toward
    // the Available Balances.
    const approveButtonFormatter = (cell, row) => {
        if (row.oer_approval && row.vendor_approval && !row.deferred)
            return <p>Site Approved</p>;

        let label = 'OER';

        if (row.oer_approval) {
            label = 'Vendor';
        }

        if (row.vendor_approval) {
            label = 'Deferred';
        }
        return (
            <Button
                onClick={() => {
                    setData(row);
                    setApprovalType(
                        row.vendor_approval
                            ? 'deferred'
                            : row.oer_approval
                            ? 'vendor'
                            : 'oer'
                    );
                    setMode(
                        row.soil_type_availabilities
                            ? modes.APPROVING_AVAILABILITY
                            : modes.APPROVING_REQUEST
                    );
                    setModal(true);
                }}
                variant='primary'
                id={`approve-button-${row.id}`}
            >
                {label} Approve
            </Button>
        );
    };

    // When an Availability or Request is Marked as Satisfied
    // We Want to Use the ID on the Backend to Make the Soil
    // Requested/Availabile Appear as the Difference in the
    // Adjustment Amount Entered
    const satisfiedButtonFormatter = (cell, row) => {
        if (row.satisfied) return <p>Satisfied</p>;
        return (
            <Button
                onClick={() => {
                    if (row.vendor_approval !== true) return;
                    setData(row);
                    setMode(
                        row.soil_type_availabilities
                            ? modes.SATISFYING_AVAILABILITY
                            : modes.SATISFYING_REQUEST
                    );
                    setModal(true);
                }}
                variant='primary'
                id={`satisfy-button-${row.id}`}
                disabled={row.vendor_approval !== true}
            >
                Satisfy
            </Button>
        );
    };

    const approvalDateFormatter = (cell, row) => {
        if (row.vendor_approval === true) {
            return new Date(row.vendor_approval_date)
                .toISOString()
                .split('T')[0];
        }

        if (row.oer_approval === true) {
            return new Date(row.oer_approval_date).toISOString().split('T')[0];
        }

        return null;
    };

    const dateFormatter = (cell) =>
        !cell ? null : new Date(cell).toISOString().split('T')[0];
    /* 
    Table Will Have a Column for Dates and Total Soil
    and Will Also Include a Dropdown for the
    Specific Types of Soils and Quantities
    */
    let requestColumns = [
        {
            dataField: 'soil_requested',
            text: 'Amount (CY)',
            formatter: requestAmountFormatter,
        },
        {
            dataField: 'soil_requested_tn',
            text: 'Amount (TN)',
            formatter: numberCellFormatter,
        },
        {
            dataField: 'created',
            text: 'Created At',
            sort: true,
            formatter: dateFormatter,
        },
        {
            dataField: 'expiration_date',
            text: 'Expiration Date',
            formatter: dateFormatter,
        },
        {
            dataField: 'modified',
            text: 'Last Updated',
            formatter: dateFormatter,
        },
        {
            dataField: 'vendor_approval',
            text: 'Approve',
            formatter: approveButtonFormatter,
        },
        {
            dataField: 'vendor_approval_date',
            text: 'Approval Date',
            formatter: approvalDateFormatter,
        },
        {
            dataField: 'satisfied',
            text: 'Satisfied',
            formatter: satisfiedButtonFormatter,
        },
        {
            dataField: 'id',
            text: 'Edit',
            formatter: editButtonFormatter,
        },
        {
            dataField: 'dupe_id',
            text: 'Delete',
            formatter: deleteButtonFormatter,
        },
    ];

    let availabilityColumns = [
        {
            dataField: 'soil_available',
            text: 'Amount (CY)',
            formatter: availabilityAmountFormatter,
        },
        {
            dataField: 'soil_available_tn',
            text: 'Amount (TN)',
            formatter: numberCellFormatter,
        },
        {
            dataField: 'created',
            text: 'Created At',
            sort: true,
            formatter: dateFormatter,
        },
        {
            dataField: 'excavation_date',
            text: 'Expiration Date',
            formatter: dateFormatter,
        },
        {
            dataField: 'modified',
            text: 'Last Updated',
            formatter: dateFormatter,
        },
        {
            dataField: 'id',
            text: 'Edit',
            formatter: editButtonFormatter,
        },
        {
            dataField: 'vendor_approval',
            text: 'Approve',
            formatter: approveButtonFormatter,
        },
        {
            dataField: 'vendor_approval_date',
            text: 'Approval Date',
            formatter: approvalDateFormatter,
        },
        {
            dataField: 'satisfied',
            text: 'Satisfied',
            formatter: satisfiedButtonFormatter,
        },
        {
            dataField: 'dupe_id',
            text: 'Delete',
            formatter: deleteButtonFormatter,
        },
    ];

    const modalRenderSwitch = () => {
        if (!modal) return;
        switch (mode) {
            case modes.CREATING_AVAILABILITY:
                return (
                    <Modal show={modal} onHide={() => setModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Availability</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AvailabilityFormProvider>
                                <SoilAvailabilityForm
                                    sid={site.id}
                                    afterSubmit={() => setModal(false)}
                                />
                            </AvailabilityFormProvider>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant='danger'
                                onClick={() => {
                                    setModal(false);
                                    setMode(null);
                                }}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                );
            case modes.CREATING_REQUEST:
                return (
                    <Modal show={modal} onHide={() => setModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <RequestFormProvider>
                                <SoilRequestForm
                                    sid={site.id}
                                    afterSubmit={() => setModal(false)}
                                />
                            </RequestFormProvider>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant='danger'
                                onClick={() => {
                                    setModal(false);
                                    setMode(null);
                                }}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                );
            case modes.APPROVING_AVAILABILITY:
                return (
                    <Modal show={modal} onHide={() => setModal(false)}>
                        <Form
                            onSubmit={async (e) => {
                                e.preventDefault();
                                try {
                                    const form = {
                                        data: {
                                            approval_type: approval_type,
                                            approval_date: approval_date,
                                            csb_eligible: csb_eligible,
                                            stockpile_eligible:
                                                stockpile_eligible,
                                            soil_types: Array.from(
                                                soil.entries()
                                            ).map(([k, v]) => [
                                                k,
                                                Array.from(v),
                                            ]),
                                        },
                                    };

                                    if (approval_type === 'vendor') {
                                        form.data.deferred = deferred;
                                    }

                                    let url = `${API_URL}/availabilities/approve/${data.id}`;

                                    const res = await fetch(url, {
                                        method: 'POST',
                                        credentials: 'include',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify(form),
                                    });

                                    const json = await res.json();

                                    const da = await runQuery();
                                    await setQuery(da);

                                    setModal(false);
                                } catch (err) {
                                    console.log(err);
                                }
                            }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Approve Availability</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {approval_type === 'oer' ? (
                                    <Form.Group>
                                        <Form.Label>CSB Eligible</Form.Label>
                                        <div>
                                            <Form.Check
                                                inline
                                                id='csb-eligibility-true'
                                                type='radio'
                                                name='csb-eligible'
                                                label='Yes'
                                                onChange={() =>
                                                    setCsbEligible(true)
                                                }
                                                defaultChecked={csb_eligible}
                                            />
                                            <Form.Check
                                                inline
                                                id='csb-eligibility-false'
                                                type='radio'
                                                name='csb-eligible'
                                                label='No'
                                                onChange={() =>
                                                    setCsbEligible(false)
                                                }
                                                defaultChecked={!csb_eligible}
                                            />
                                        </div>
                                    </Form.Group>
                                ) : (
                                    []
                                )}
                                {approval_type === 'vendor' ||
                                approval_type === 'deferred' ? (
                                    <Form.Group>
                                        <Form.Label>
                                            Stockpile Eligible
                                        </Form.Label>
                                        <div>
                                            <Form.Check
                                                inline
                                                id='stockpile-eligibility-true'
                                                name='stockpile-eligible'
                                                type='radio'
                                                label='Yes'
                                                onChange={() =>
                                                    setStockpileEligible(true)
                                                }
                                                defaultChecked={
                                                    stockpile_eligible
                                                }
                                            />
                                            <Form.Check
                                                inline
                                                id='stockpile-eligibility-false'
                                                name='stockpile-eligible'
                                                type='radio'
                                                label='No'
                                                onChange={() =>
                                                    setStockpileEligible(false)
                                                }
                                                defaultChecked={
                                                    !stockpile_eligible
                                                }
                                            />
                                        </div>
                                    </Form.Group>
                                ) : (
                                    []
                                )}
                                <div className='d-flex flex-row flex-wrap'>
                                    <SoilInput
                                        defaultValues={getSoilInputs(
                                            data,
                                            true,
                                            approval_type === 'deferred'
                                        )}
                                        onChange={(v) => setSoil(v)}
                                    />
                                </div>
                                <Form.Label htmlFor='approval_date'>
                                    Approval Date
                                </Form.Label>
                                <Form.Control
                                    id='approval_date'
                                    type='date'
                                    onChange={(e) =>
                                        setApprovalDate(e.target.value)
                                    }
                                    defaultValue={
                                        new Date().toISOString().split('T')[0]
                                    }
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                {approval_type === 'vendor' ? (
                                    <Form.Check
                                        id='defer_approval'
                                        name='defer_approval'
                                        label='Defer Approval'
                                        onChange={(e) =>
                                            setDeferred(e.target.checked)
                                        }
                                        style={{ marginRight: 'auto' }}
                                    />
                                ) : (
                                    []
                                )}
                                <Button variant='danger' type='submit'>
                                    Submit
                                </Button>
                                <Button
                                    variant='secondary'
                                    onClick={() => {
                                        setSoil(new Set());
                                        setDeferred(false);
                                        setApprovalType(null);
                                        setApprovalDate(null);
                                        setModal(false);
                                        setMode(null);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                );
            case modes.APPROVING_REQUEST:
                return (
                    <Modal show={modal} onHide={() => setModal(false)}>
                        <Form
                            onSubmit={async (e) => {
                                e.preventDefault();

                                try {
                                    const form = {
                                        data: {
                                            approval_type: approval_type,
                                            approval_date: approval_date,
                                            csb_eligible: csb_eligible,
                                            stockpile_eligible:
                                                stockpile_eligible,
                                            soil_types: Array.from(
                                                soil.entries()
                                            ).map(([k, v]) => [
                                                k,
                                                Array.from(v),
                                            ]),
                                        },
                                    };

                                    // FIXME: Change Approval Types to Constants to Avoid Typos
                                    if (approval_type === 'vendor') {
                                        form.data.deferred = deferred;
                                    }

                                    let url = `${API_URL}/requests/approve/${data.id}`;

                                    const res = await fetch(url, {
                                        method: 'POST',
                                        credentials: 'include',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify(form),
                                    });

                                    const json = await res.json();

                                    const da = await runQuery();
                                    await setQuery(da);

                                    setModal(false);
                                } catch (err) {
                                    console.log(err);
                                }
                            }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Approve Request</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group>
                                    {approval_type === 'oer' ? (
                                        <Form.Group>
                                            <Form.Label>
                                                CSB Eligible
                                            </Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    id='csb-eligibility-true'
                                                    type='radio'
                                                    name='csb-eligible'
                                                    label='Yes'
                                                    onChange={() =>
                                                        setCsbEligible(true)
                                                    }
                                                    defaultChecked={
                                                        csb_eligible
                                                    }
                                                />
                                                <Form.Check
                                                    inline
                                                    id='csb-eligibility-false'
                                                    type='radio'
                                                    name='csb-eligible'
                                                    label='No'
                                                    onChange={() =>
                                                        setCsbEligible(false)
                                                    }
                                                    defaultChecked={
                                                        !csb_eligible
                                                    }
                                                />
                                            </div>
                                        </Form.Group>
                                    ) : (
                                        []
                                    )}
                                    {approval_type === 'vendor' ||
                                    approval_type === 'deferred' ? (
                                        <Form.Group>
                                            <Form.Label>
                                                Stockpile Eligible{' '}
                                                {deferred ? (
                                                    <Badge>⚠ Deferred</Badge>
                                                ) : (
                                                    []
                                                )}
                                            </Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    id='stockpile-eligibility-true'
                                                    name='stockpile-eligible'
                                                    type='radio'
                                                    label='Yes'
                                                    onChange={() =>
                                                        setStockpileEligible(
                                                            true
                                                        )
                                                    }
                                                    defaultChecked={
                                                        stockpile_eligible
                                                    }
                                                />
                                                <Form.Check
                                                    inline
                                                    id='stockpile-eligibility-false'
                                                    name='stockpile-eligible'
                                                    type='radio'
                                                    label='No'
                                                    onChange={() =>
                                                        setStockpileEligible(
                                                            false
                                                        )
                                                    }
                                                    defaultChecked={
                                                        !stockpile_eligible
                                                    }
                                                />
                                            </div>
                                        </Form.Group>
                                    ) : (
                                        []
                                    )}
                                    <Form.Label>
                                        Soil Amounts Approved
                                    </Form.Label>
                                    <div className='d-flex flex-row flex-wrap'>
                                        <SoilInput
                                            defaultValues={getSoilInputs(
                                                data,
                                                false,
                                                approval_type === 'deferred'
                                            )}
                                            onChange={(v) => setSoil(v)}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label htmlFor='approval-date'>
                                        Approval Date
                                    </Form.Label>
                                    <Form.Control
                                        id='approval-date'
                                        type='date'
                                        onChange={(e) =>
                                            setApprovalDate(e.target.value)
                                        }
                                        defaultValue={
                                            new Date()
                                                .toISOString()
                                                .split('T')[0]
                                        }
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                {approval_type === 'vendor' ? (
                                    <Form.Check
                                        id='defer_approval'
                                        name='defer_approval'
                                        label='Defer Approval'
                                        onChange={(e) =>
                                            setDeferred(e.target.checked)
                                        }
                                        style={{ marginRight: 'auto' }}
                                    />
                                ) : (
                                    []
                                )}
                                <Button variant='danger' type='submit'>
                                    Submit
                                </Button>
                                <Button
                                    variant='secondary'
                                    onClick={() => {
                                        setSoil(new Set());
                                        setDeferred(false);
                                        setApprovalType(null);
                                        setApprovalDate(null);
                                        setModal(false);
                                        setMode(null);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                );
            case modes.EDITING_AVAILABILITY:
                return (
                    <Modal show={modal} onHide={() => setModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Availability</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AvailabilityFormProvider>
                                <SoilAvailabilityEditForm
                                    availability={data}
                                    afterSubmit={() => setModal(false)}
                                />
                            </AvailabilityFormProvider>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant='danger'
                                onClick={() => {
                                    setSoil(new Set());
                                    setModal(false);
                                    setMode(null);
                                }}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                );
            case modes.EDITING_REQUEST:
                return (
                    <Modal show={modal} onHide={() => setModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <RequestFormProvider>
                                <SoilRequestEditForm
                                    request={data}
                                    afterSubmit={() => setModal(false)}
                                />
                            </RequestFormProvider>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant='danger'
                                onClick={() => {
                                    setSoil(new Set());
                                    setModal(false);
                                    setMode(null);
                                }}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                );
            case modes.DELETING_AVAILABILITY:
                return (
                    <Modal show={modal} onHide={() => setModal(false)}>
                        <Form
                            onSubmit={async (e) => {
                                e.preventDefault();
                                try {
                                    let url = `${API_URL}/availabilities/${data.id}`;

                                    const res = await fetch(url, {
                                        method: 'DELETE',
                                        credentials: 'include',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                    });

                                    const json = await res.json();

                                    const da = await runQuery();
                                    await setQuery(da);

                                    setModal(false);
                                } catch (err) {
                                    console.log(err);
                                }
                            }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Deleting Availability</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are You Sure You Want to Delete this
                                Availability? This Action Cannot Be Undone.
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='danger' type='submit'>
                                    Delete
                                </Button>
                                <Button
                                    variant='info'
                                    onClick={() => {
                                        setModal(false);
                                        setMode(null);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                );
            case modes.DELETING_REQUEST:
                return (
                    <Modal show={modal} onHide={() => setModal(false)}>
                        <Form
                            onSubmit={async (e) => {
                                e.preventDefault();
                                try {
                                    let url = `${API_URL}/requests/${data.id}`;

                                    const res = await fetch(url, {
                                        method: 'DELETE',
                                        credentials: 'include',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                    });

                                    const json = await res.json();

                                    const da = await runQuery();
                                    await setQuery(da);

                                    setModal(false);
                                } catch (err) {
                                    console.log(err);
                                }
                            }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Request</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are You Sure You Want to Delete this Request?
                                This Action Cannot Be Undone.
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='danger' type='submit'>
                                    Delete
                                </Button>
                                <Button
                                    variant='info'
                                    onClick={() => {
                                        setModal(false);
                                        setMode(null);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                );
            case modes.SATISFYING_AVAILABILITY:
                return (
                    <Modal show={modal} onHide={() => setModal(false)}>
                        <Form
                            onSubmit={async (e) => {
                                e.preventDefault();

                                try {
                                    const form = {
                                        data: {
                                            satisfied_date: satisfied_date,
                                            soil_types: Array.from(
                                                soil.entries()
                                            ).map(([k, v]) => [
                                                k,
                                                Array.from(v),
                                            ]),
                                        },
                                    };

                                    let url = `${API_URL}/availabilities/satisfy/${data.id}`;

                                    const res = await fetch(url, {
                                        method: 'POST',
                                        credentials: 'include',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify(form),
                                    });

                                    const json = await res.json();

                                    const da = await runQuery();
                                    await setQuery(da);

                                    setModal(false);
                                } catch (err) {
                                    console.log(err);
                                }
                            }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Satisfy Availability</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group>
                                    <Form.Label>
                                        Soil Amounts Approved
                                    </Form.Label>
                                    <div className='d-flex flex-row flex-wrap'>
                                        <SoilInput
                                            defaultValues={getSoilInputs(
                                                data,
                                                true
                                            )}
                                            onChange={(v) => setSoil(v)}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label htmlFor='satisfied-date'>
                                        Satisfied Date
                                    </Form.Label>
                                    <Form.Control
                                        id='satisfied-date'
                                        type='date'
                                        onChange={(e) =>
                                            setSatisfiedDate(e.target.value)
                                        }
                                        defaultValue={
                                            new Date()
                                                .toISOString()
                                                .split('T')[0]
                                        }
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check
                                        id='verify-zero'
                                        name='verify-zero'
                                        label='The balances in this form have been adjusted to reflect the actual amount of soil
                                        from this record used in transfers.'
                                        required
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='danger' type='submit'>
                                    Submit
                                </Button>
                                <Button
                                    variant='secondary'
                                    onClick={() => {
                                        setSoil(new Set());
                                        setSatisfiedDate(null);
                                        setModal(false);
                                        setMode(null);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                );

            case modes.SATISFYING_REQUEST:
                return (
                    <Modal show={modal} onHide={() => setModal(false)}>
                        <Form
                            onSubmit={async (e) => {
                                e.preventDefault();

                                try {
                                    const form = {
                                        data: {
                                            satisfied_date: satisfied_date,
                                            soil_types: Array.from(
                                                soil.entries()
                                            ).map(([k, v]) => [
                                                k,
                                                Array.from(v),
                                            ]),
                                        },
                                    };

                                    let url = `${API_URL}/requests/satisfy/${data.id}`;

                                    const res = await fetch(url, {
                                        method: 'POST',
                                        credentials: 'include',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify(form),
                                    });

                                    const json = await res.json();

                                    const da = await runQuery();
                                    await setQuery(da);

                                    setModal(false);
                                } catch (err) {
                                    console.log(err);
                                }
                            }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Satisfy Request</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group>
                                    <Form.Label>
                                        Soil Amounts Approved
                                    </Form.Label>
                                    <div className='d-flex flex-row flex-wrap'>
                                        <SoilInput
                                            defaultValues={getSoilInputs(data)}
                                            onChange={(v) => setSoil(v)}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label htmlFor='satisfied-date'>
                                        Satisfied Date
                                    </Form.Label>
                                    <Form.Control
                                        id='satisfied-date'
                                        type='date'
                                        onChange={(e) =>
                                            setSatisfiedDate(e.target.value)
                                        }
                                        defaultValue={
                                            new Date()
                                                .toISOString()
                                                .split('T')[0]
                                        }
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check
                                        id='verify-zero'
                                        name='verify-zero'
                                        label='The balances in this form have been adjusted to reflect the actual amount of soil
                                        from this record used in transfers.'
                                        required
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='danger' type='submit'>
                                    Submit
                                </Button>
                                <Button
                                    variant='secondary'
                                    onClick={() => {
                                        setSoil(new Set());
                                        setSatisfiedDate(null);
                                        setModal(false);
                                        setMode(null);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                );
            default:
                return;
        }
    };

    return (
        <>
            <Container fluid className={`${site.stockpile ? 'd-none' : 'p-0'}`}>
                {/* TODO: Add IDs to Modals or Something for Submissions */}
                {modalRenderSwitch()}
                <div className='d-flex d-flex justify-content-between'>
                    <h2>Availabilities </h2>
                    <Button
                        onClick={() => {
                            setMode(modes.CREATING_AVAILABILITY);
                            setModal(true);
                        }}
                        className='align-self-end mb-2'
                        disabled={site.stockpile}
                    >
                        Create Availability
                    </Button>
                </div>
                <ToolkitProvider
                    bootstrap4
                    keyField='id'
                    data={availabilities}
                    columns={availabilityColumns}
                >
                    {(props) => (
                        <BootstrapTable
                            striped
                            hover
                            condensed
                            responsive
                            pagination={paginationFactory()}
                            noDataIndication='No Availabilities Found.'
                            {...props.baseProps}
                        />
                    )}
                </ToolkitProvider>
            </Container>

            <Container fluid className='p-0'>
                <div className='d-flex justify-content-between'>
                    <h2>Requests </h2>
                    <Button
                        onClick={() => {
                            setModal(true);
                            setMode(modes.CREATING_REQUEST);
                        }}
                        className='align-self-end mb-2'
                    >
                        Create Request
                    </Button>
                </div>
                <ToolkitProvider
                    bootstrap4
                    keyField='id'
                    data={requests}
                    columns={requestColumns}
                >
                    {(props) => (
                        <BootstrapTable
                            striped
                            hover
                            condensed
                            responsive
                            pagination={paginationFactory()}
                            noDataIndication='No Requests Found.'
                            {...props.baseProps}
                        />
                    )}
                </ToolkitProvider>
            </Container>
        </>
    );
};

SiteAvailabilityRequest.propTypes = {
    site: PropTypes.object.isRequired,
};
