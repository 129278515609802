import React from 'react';
import { SiteProfileForm } from '../SiteProfile';
import { NewSiteFormProvider } from './context/NewSiteContext';

export const NewSiteForm = () => {
    return (
        <>
            <NewSiteFormProvider>
                <SiteProfileForm />
            </NewSiteFormProvider>
        </>
    );
};
