import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useAuthentication } from '../../hooks';
import { Header } from '../Header';
import { GlobalProvider } from '../../context/GlobalState';
import { Loading } from '../Loading';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const logged = useAuthentication();
    return (
        <Route
            {...rest}
            render={(props) => {
                if (logged === true) {
                    return (
                        <>
                            <GlobalProvider>
                                <Loading>
                                    <Header />
                                    <div className='csb-wrapper position-relative'>
                                        <Component {...props} />
                                    </div>
                                </Loading>
                            </GlobalProvider>
                        </>
                    );
                }
                if (logged === false) {
                    return <Redirect to={'/login'} />;
                }
            }}
        />
    );
};

ProtectedRoute.propTypes = {
    component: PropTypes.func,
};
