export const isNull = (o) => o === null || o === '';
export const generateFormID = (id, new_site) =>
    `table-form-${new_site ? 'new' : 'api'}-${id}`;
export const nullDate = (d) =>
    d === '' || d === null || d === undefined || d === '0000-00-00';
export const CY_TO_TN_FACTOR = 1.5;
export const CyToTn = (n) => (n * CY_TO_TN_FACTOR).toFixed(2);
export const TnToCy = (n) => (n / CY_TO_TN_FACTOR).toFixed(2);
export const sortSiteProgramTypes = (types) => {
    let c = new Set(types);
    if (!c) return null;
    let ret;
    if (c.has('Other')) {
        c.delete('Other');
        ret = `${Array.from(c).sort().toString()},Other`;
    } else {
        ret = Array.from(c).sort().toString();
    }

    return ret;
};
export const deepCopy = (o) => {
    return JSON.parse(JSON.stringify(o));
};
export const statusOptions = {
    C: 'Completed',
    A: 'Active',
    X: 'Cancelled',
    H: 'On Hold',
};
export const boroughOptions = {
    MN: 'Manhattan',
    BX: 'Bronx',
    BK: 'Brooklyn',
    QN: 'Queens',
    SI: 'Staten Island',
};
