import { useRef, useState, useEffect } from 'react';
import { userAuthenticated } from '../api/auth';

export const useFirstRenderEffect = (func) => {
    const firstRender = useRef(true);

    useEffect(() => {
        if (!firstRender.current) func();
        else firstRender.current = false;
    }, [func]);

    return firstRender.current;
};

export const useAuthentication = () => {
    const [auth, setAuth] = useState(null);

    useEffect(() => {
        let cancelled = false;
        const f = async () => {
            const d = await userAuthenticated();
            if (!cancelled) setAuth(d);
        };
        f();
        return () => (cancelled = true);
    });
    return auth;
};

//this function will check what is the user type
// export const AdminUser = () => {
//     const [isAdmin, setIsAdmin] = useState(false);

//     useEffect(()=>{
//         let cancelled = false;
//         const f = async ()=>{
//             const result = await isUserAdmin();
//             if(result){setIsAdmin(true);}
//         }
//         f();
//         return () => (cancelled = true);
//     });

//     return isAdmin;
// };
