import React from 'react';
import { useHistory } from 'react-router-dom';
import { attemptLogout } from '../../api';
import { Button } from 'react-bootstrap';

export const LogoutButton = () => {
    const history = useHistory();
    return (
        <Button
            onClick={async () => {
                await attemptLogout();
                history.push('/login');
            }}
        >
            Logout
        </Button>
    );
};
