import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { Marker, Popup } from 'react-leaflet';
import { NewSiteFormContext } from '../NewSite/context/NewSiteContext';
import { ManualLocationInput } from '../Shared/Inputs/ManualLocationInput';
import { ProgramTypeInput } from '../Shared/Inputs/ProgramTypeInput';
import { SiteTypeInput } from '../Shared/Inputs/SiteTypeInput';
import { GlobalContext } from '../../../context/GlobalState';
import { runQuery } from '../../../api';
import { getBorough, constructBBL } from '../../../utils/addressTool';
import { API_URL } from '../../../api/queries';


export const SiteProfileForm = ({ afterSubmit }) => {
    const [formVaild, setFormVaild] = useState(false);
    const [phoneNumberCheck,setPhoneNumberCheck] = useState(false);
    const [manualLoc, setManualLoc] = useState(false);
    const [manualGeo, setManualGeo] = useState(false);
    const [invalidTMP, setInvalidTMP] = useState(false);
    const history = useHistory();
    const {
        setQuery,
        query: { sites },
        cache,
    } = useContext(GlobalContext);
    const state = useContext(NewSiteFormContext);
    const [inputCoords,setInputCoords] = useState({lat:'',lng:''});
    
    function hanldeInputCoords(event){
        const {name,value} = event.target
        // console.log(name);
        // console.log(value);
        if(value){setFormVaild(false);}
        setInputCoords(oldData=>{
            return {
                ...oldData,
                [name]:value,
            }
        });
    }
    const usedTMPs = new Set();
    sites.forEach(({ meta: { oer_proj_num } }) => usedTMPs.add(oer_proj_num));
    const checkTMP = async (tmp) => {
        // Default Conditions
        state.setProjectName('');
        state.setProject(tmp);
        state.setAddress('');
        state.setBorough('');
        state.setBBL('');
        // state.setCoords(['0','0']);
        setInvalidTMP(false);

        // Don't Continue if Number Isn't Formatted Correctly
        if (!/\d\dTMP\d\d\d\d\w/.test(tmp)) {
            setInvalidTMP(true);
            console.log("in regEx")
            return;
        }

        // Check if TMP Already in Use
        if (usedTMPs.has(tmp)) {
            setInvalidTMP(true);
            console.log("in alreay use ")
            return;
        }
        
        // Search EPIC Data for TMP Number
        const res = Object.values(cache).filter((obj) =>
            obj.ProjectNumbers.find((n) => n.Number === tmp)
        );
        console.log("after search epic")
        console.log(res)
        if (res.length > 0) {
            const name = res[0].Name;
            const addr = `${res[0].Address.StreetNumber} ${res[0].Address.StreetName}`;
            const bib = res[0].BBLs[0].BibNumber
                ? res[0].BBLs[0].BibNumber[0]
                : constructBBL(res[0].BBLs[0]);
            const coords = [`${res[0].Address.Latitude}`,`${res[0].Address.Longitude}`]; //getting geo infor from epic
            
            if(coords[0]==='undefined' || coords[1]==='undefined'){
                console.log('no geo information')
                //set flag to true let user input geo data
                //alert(`do you pick an address on the Epic site for this ${tmp}`);
                setFormVaild(true);
                setManualGeo(true);
            }else{
                state.setCoords(coords);
            }
            //copy the epic geo values to display on the input area
            setInputCoords({lat:coords[0],lng:coords[1]});
            
            state.setProjectName(name);
            state.setProject(tmp);
            state.setAddress(addr);
            state.setBorough(getBorough(bib[0]));
            state.setBBL(bib);
        } else {
            setInvalidTMP(true);
        }
    };
    
    return (
        <div className='container'>
            <Form
                id='site_profile_form'
                onSubmit={async (e) => {
                    e.preventDefault();

                    if (invalidTMP) return;
                    // console.log(state.contact.phone.length);
                    if (state.contact.phone.length<10 || state.contact.phone.match(/([A-Z,a-z])\w+/g)){
                        alert("Invalid phone number, please check the phone number field");
                        //setPhoneNumberCheck(true);
                        return;
                    }
                    try {
                        const form = {
                            data: {
                                ...state,
                                lat: manualGeo ? inputCoords.lat : state.coords[0],
                                lng: manualGeo ? inputCoords.lng : state.coords[1],
                                site_types: Array.from(state.site_types),
                                program_types: Array.from(state.program_types),
                            },
                        };

                        if(form.data.lat.match(/([A-Z,a-z])\w+/g) || form.data.lng.match(/([A-Z,a-z])\w+/g)){
                            alert("Invalid Latitude or Longitude, please check the Latitude/Longitude fields");
                            return;
                        }
                        // debonce setting
                        let btn = document.getElementById("subbtn")
                        btn.disabled=true
                        btn.innerText="Sending....."
                        let url = `${API_URL}/sites/`;

                        const res = await fetch(url, {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(form),
                        });

                        const json = await res.json();

                        const data = await runQuery();
                        await setQuery(data);

                        // console.log(json.site);
                        history.push(`/sites/${json.site.meta.oer_proj_num}`);

                        if (afterSubmit) afterSubmit(json.site.id);
                        btn.disabled=false
                        btn.innerText="Submit"
                    } catch (err) {
                        alert('Error Submitting Form, please check if your input fields');
                        // console.log(err);
                        let btn = document.getElementById("subbtn")
                        btn.disabled=false
                        btn.innerText="Submit"
                    }
                }}
            >
                <h1>Site Profile Form</h1>
                <Form.Switch
                    className='my-auto'
                    id='stockpile'
                    label='This Site is a Stockpile'
                    value={state.stockpile}
                    onChange={() => state.setStockpile(!state.stockpile)}
                />
                <div>
                    <Form.Switch
                        id='pending'
                        label='Pending Site'
                        value={state.pending}
                        onChange={() => state.setPending(!state.pending)}
                    />
                    <h3>Contact Information</h3>

                    <Form.Label htmlFor='pc_fname'>
                        Primary Contact First Name
                    </Form.Label>
                    <Form.Control
                        id='pc_fname'
                        type='text'
                        value={state.contact.firstname}
                        onChange={(e) => state.setFirstName(e.target.value)}
                        required
                    ></Form.Control>

                    <Form.Label htmlFor='pc_lname'>
                        Primary Contact Last Name
                    </Form.Label>
                    <Form.Control
                        id='pc_lname'
                        type='text'
                        value={state.contact.lastname}
                        onChange={(e) => state.setLastName(e.target.value)}
                        required
                    ></Form.Control>

                    <Form.Label htmlFor='pc_phone'>
                        Primary Contact Phone Number
                    </Form.Label>
                    <Form.Group>
                        <Form.Control
                            id='pc_phone'
                            type='text'
                            value={state.contact.phone}
                            onChange={(e) => state.setPhone(e.target.value)}
                            required
                            isInvalid={phoneNumberCheck}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                                Invalid phone number, please check your input number.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <h3>Site Information</h3>

                    <Form.Label htmlFor='oer_proj'>
                        OER Project Number
                    </Form.Label>
                    <Form.Group>
                        <Form.Control
                            id='oer_proj'
                            type='text'
                            value={state.oer_proj}
                            onChange={(e) => checkTMP(e.target.value)}
                            required
                            isInvalid={invalidTMP}
                        />
                        <Form.Control.Feedback type='invalid'>
                            TMP Number Does Not Exist or is Already In Use
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Label html='project_name'>Site Name</Form.Label>
                    <Form.Control
                        id='project_name'
                        type='text'
                        value={state.project_name}
                        // onChange={(e) => state.setProjectName(e.target.value)}
                        readOnly
                        required
                    />

                    <Form.Label htmlFor='address_input'>Address</Form.Label>
                    <Form.Control
                        id='address_input'
                        type='text'
                        value={state.address}
                        // onChange={(e) => state.setAddress(e.target.value)}
                        readOnly
                        required
                    />

                    <Form.Label htmlFor='borough_input'>Borough</Form.Label>
                    <Form.Control
                        id='borough_input'
                        as='select'
                        value={state.borough}
                        // onChange={(e) => state.setBorough(e.target.value)}
                        readOnly
                        required
                    >
                        <option value=''>Borough</option>
                        <option value='MN'>Manhattan</option>
                        <option value='BX'>Bronx</option>
                        <option value='BK'>Brooklyn</option>
                        <option value='QN'>Queens</option>
                        <option value='SI'>Staten Island</option>
                    </Form.Control>
                    
                    <Form.Label htmlFor='borough_input'>Latitude</Form.Label>
                    <Form.Group>
                        <Form.Control
                            id='lat_input'
                            type='text'
                            name='lat'
                            value={inputCoords.lat}
                            onChange={hanldeInputCoords}
                            placeholder='please manually enter latitude, if need'
                            required
                            isInvalid={formVaild}
                        />
                        <Form.Control.Feedback type='invalid'>
                        If no latitude self-populates in the field above, please confirm that the Location Info (in EPIC: Property Summary, Property Info, Location Info) has been updated.<br/>
                        After the Location Info has been updated, please allow one day for EPIC to sync with the CSB App. Alternatively, you may copy and paste the latitude from EPIC above.<br/>
                        <b>If there is no Location Info available in EPIC, please contact your supervisor.</b>
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Label htmlFor='borough_input'>Longitude</Form.Label>
                    <Form.Group>
                        <Form.Control
                            id='lng_input'
                            type='text'
                            name='lng'
                            value={inputCoords.lng}
                            onChange={hanldeInputCoords}
                            placeholder='please manually enter longitude, if need'
                            required
                            isInvalid={formVaild}
                        />
                        <Form.Control.Feedback type='invalid'>
                        If no longitude self-populates in the field above, please confirm that the Location Info (in EPIC: Property Summary, Property Info, Location Info) has been updated.<br/>
                        After the Location Info has been updated, please allow one day for EPIC to sync with the CSB App. Alternatively, you may copy and paste the longitude from EPIC above.<br/>
                        <b>If there is no Location Info available in EPIC, please contact your supervisor.</b>
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Switch
                        type='switch'
                        id='manual_location'
                        label='Manual Location'
                        checked={manualLoc}
                        onChange={() => setManualLoc((currVal) => !currVal)}
                    />

                    <Form.Control
                        type='number'
                        id='lat'
                        value={state.coords[0]}
                        readOnly
                        hidden
                        required
                    />
                    <Form.Control
                        type='number'
                        id='lng'
                        value={state.coords[1]}
                        readOnly
                        hidden
                        required
                    />

                    <ManualLocationInput
                        show={manualLoc}
                        onChange={(e) => {
                            state.setCoords([e.latlng.lat, e.latlng.lng]);
                            if (state.address && state.borough)
                                state.setValid(true);
                        }}
                        marker={
                            state.coords ? (
                                <Marker
                                    key='selected-site'
                                    position={state.coords}
                                >
                                    <Popup>{`${state.coords[0]}, ${state.coords[1]}`}</Popup>
                                </Marker>
                            ) : (
                                []
                            )
                        }
                        hide={() => {
                            setManualLoc(false);
                            state.setCoords(['', '']);
                        }}
                    />

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '0 3%',
                        }}
                    >
                        <div>
                            <SiteTypeInput
                                onChange={(v) => state.setSiteType(v)}
                            />
                        </div>
                        <div>
                            <ProgramTypeInput
                                onChange={(v) => state.setProgramType(v)}
                            />
                        </div>
                    </div>
                    <Form.Label htmlFor='additional_notes'>
                        Additional Notes
                    </Form.Label>
                    <Form.Control
                        id='additional_notes'
                        as='textarea'
                        value={state.notes}
                        onChange={(e) => state.setNotes(e.target.value)}
                    />
                </div>
                <Button className='w-100' type='submit' id='subbtn'>
                    Submit
                </Button>
            </Form>
        </div>
    );
};

SiteProfileForm.propTypes = {
    afterSubmit: PropTypes.func,
};

const getSiteTypesSet = (site_types_relations) => {
    if (site_types_relations.length <= 0) return new Set();
    const s = new Set();
    site_types_relations.map(({ site_type: { name } }) => s.add(name));

    return s;
};

const getProgramTypesSet = (program_types_relations) => {
    if (program_types_relations.length <= 0) return new Set();
    const s = new Set();
    program_types_relations.map(({ program_type: { name } }) => s.add(name));

    return s;
};

export const SiteProfileEditForm = ({ site, afterSubmit }) => {
    const state = useContext(NewSiteFormContext);
    const { setQuery } = useContext(GlobalContext);
    // Initialize the State with All of the Values from the Existing Item
    useEffect(() => {
        state.setPending(site.meta.pending);
        state.setProjectName(site.project_name);
        state.setFirstName(site.meta.primary_contact.firstname);
        state.setLastName(site.meta.primary_contact.lastname);
        state.setPhone(site.meta.primary_contact.phone);
        state.setSiteType(getSiteTypesSet(site.site_types_relations));
        state.setProgramType(getProgramTypesSet(site.program_types_relations));
    }, []);

    return (
        <Form
            id='site_profile_edit_form'
            onSubmit={async (e) => {
                e.preventDefault();
                // debonce setting
                let btn = document.getElementById("subbtn")
                btn.disabled=true
                btn.innerText="Sending....."
                try {
                    const form = {
                        data: {
                            project_name: state.project_name,
                            contact: {
                                id: site.meta.primary_contact_id,
                                firstname: state.contact.firstname,
                                lastname: state.contact.lastname,
                                phone: state.contact.phone,
                            },
                            pending: state.pending,
                            csb_eligible: state.csb_eligible,
                            stockpile_eligible: state.stockpile_eligible,
                            notes: state.notes,
                            site_types: Array.from(state.site_types),
                            program_types: Array.from(state.program_types),
                        },
                    };

                    let url = `${API_URL}/sites/${site.id}`;

                    const res = await fetch(url, {
                        method: 'PATCH',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(form),
                    });

                    const json = await res.json();

                    const data = await runQuery();
                    await setQuery(data);

                    if (afterSubmit) afterSubmit();
                } catch (err) {
                    alert('Error Submitting Form');
                    console.log(err);
                }
            }}
        >
            <Form.Switch
                id='pending'
                label='Pending'
                onChange={() => state.setPending(!state.pending)}
                defaultChecked={site.meta.pending}
            />
            <Form.Label htmlFor='pc_fname'>
                Primary Contact First Name
            </Form.Label>
            <Form.Control
                id='pc_fname'
                type='text'
                onChange={(e) => state.setFirstName(e.target.value)}
                defaultValue={site.meta.primary_contact.firstname}
                required
            ></Form.Control>

            <Form.Label htmlFor='pc_lname'>
                Primary Contact Last Name
            </Form.Label>
            <Form.Control
                id='pc_lname'
                type='text'
                onChange={(e) => state.setLastName(e.target.value)}
                defaultValue={site.meta.primary_contact.lastname}
                required
            ></Form.Control>

            <Form.Label htmlFor='pc_phone'>
                Primary Contact Phone Number
            </Form.Label>
            <Form.Control
                id='pc_phone'
                type='text'
                onChange={(e) => state.setPhone(e.target.value)}
                defaultValue={site.meta.primary_contact.phone}
                required
            ></Form.Control>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '0 3%',
                }}
            >
                <div>
                    <SiteTypeInput
                        defaultValues={getSiteTypesSet(
                            site.site_types_relations
                        )}
                        onChange={(v) => state.setSiteType(v)}
                    />
                </div>
                <div>
                    <ProgramTypeInput
                        defaultValues={getProgramTypesSet(
                            site.program_types_relations
                        )}
                        onChange={(v) => state.setProgramType(v)}
                    />
                </div>
            </div>
            <Form.Label htmlFor='additional_notes'>Additional Notes</Form.Label>
            <Form.Control
                id='additional_notes'
                as='textarea'
                onChange={(e) => state.setNotes(e.target.value)}
                defaultValue={site.meta.notes}
            />
            <Button className='mt-2 w-100' type='submit' id='subbtn'>
                Save Changes
            </Button>
        </Form>
    );
};

SiteProfileEditForm.propTypes = {
    site: PropTypes.object.isRequired,
    afterSubmit: PropTypes.func,
};
