import * as actions from '../../Shared/constants';

export default (state, action) => {
    switch (action.type) {
        case actions.SET_GENERATING_ID:
            return {
                ...state,
                generating_id: action.payload,
            };
        case actions.SET_RECEIVING_ID:
            return {
                ...state,
                receiving_id: action.payload,
            };
        case actions.SET_SOIL_TYPE:
            return {
                ...state,
                soil_types: action.payload,
            };
        case actions.SET_STATUS:
            return {
                ...state,
                status: action.payload,
            };
        case actions.SET_ANT_START:
            return {
                ...state,
                ant_start: action.payload,
            };
        case actions.SET_ANT_END:
            return {
                ...state,
                ant_end: action.payload,
            };
        case actions.SET_ACT_START:
            return {
                ...state,
                act_start: action.payload,
            };
        case actions.SET_ACT_END:
            return {
                ...state,
                act_end: action.payload,
            };
        case actions.SET_NOTES:
            return {
                ...state,
                notes: action.payload,
            };
        case actions.SET_SOURCE_ALT_ID:
            return {
                ...state,
                source_alt_id: action.payload,
            };
        case actions.SET_DESTINATION_ALT_ID:
            return {
                ...state,
                destination_alt_id: action.payload,
            };
        default:
            return state;
    }
};
