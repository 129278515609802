import React, { useState,useContext,useRef,useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { GlobalContext } from '../../context/GlobalState';

export const AltLocationSelector = ({
    id,
    placeholder,
    onChange,
    disabled,
    ...rest
})=>{
    const [selectedLocation, setSelectedLocation] = useState([]);
    // const typeahead = useRef(null);
    const {
        query: { alternateLocations },
    } = useContext(GlobalContext); 

    const handleSelectionChange = (e)=>{
        if(e[0] !== undefined){
            // console.log(e[0].id);
            setSelectedLocation(e);
            onChange(e[0].id)
        }
        // typeahead.current.blur();
    }


    return (
        <Form.Group>
            <Typeahead
                id={id}
                //ref={typeahead}
                labelKey="alt_address"
                onChange = {handleSelectionChange}
                options={alternateLocations.filter(ele=>ele.active)} //only show active alternate location for selector
                placeholder={placeholder}
                disabled={disabled}
                renderMenuItemChildren={(option)=>{
                            return <div>
                                {option.alt_address}
                                <div>
                                    <small>Facility Name: {option.facility_name?option.facility_name:"None"}</small>
                                </div>
                            </div>
                        }}
                {...rest}
            />
        </Form.Group>
    );
}