import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {
    selectFilter,
    dateFilter,
} from 'react-bootstrap-table2-filter';
import { Container, Row, Col } from 'react-bootstrap';
import ToolkitProvider, {
    Search,
    CSVExport,
} from 'react-bootstrap-table2-toolkit';
import {
    statusOptions,
    boroughOptions,
    deepCopy,
    CY_TO_TN_FACTOR,
} from '../../utils/';
import { Link } from 'react-router-dom';

const ProjectNumberFormatter = (cell, row) => {
    return (
        <Link to={`/sites/${cell}`} target='_blank'>
            {cell}{' '}
        </Link>
    );
};

export const FilterTable = () => {
    const { ExportCSVButton } = CSVExport;
    const { SearchBar } = Search;
    const {
        query: { transfers, sites }, // in here the query be run 
    } = useContext(GlobalContext);

    let addressOptions = {};
    let siteNameOptions = {};
    
    sites.map(({ name, address }) => {
        addressOptions[address] = address;
        siteNameOptions[name] = name;
    });

    //site type for all records
    let siteType = new Map();
    // console.log(sites);
    sites.map((ele)=>{
        if(ele.site_types_relations[0]){
            siteType.set(ele.id,ele.site_types_relations[0].site_type.name);
        }else{
            siteType.set(ele.id,"null");
        }
    });
    // console.log(siteType);
    
    let cpTransfers = deepCopy(
        transfers.map((l) => {
            //add the sity type for source and destination
           l['siteType_source']=siteType.get(l.source_id);
           l['siteType_destination']=siteType.get(l.destination_id);
            l.soil_transferred_tn = (
                l.soil_transferred * CY_TO_TN_FACTOR
            ).toFixed(2);
            return l;
        })
    );
    //console.log(cpTransfers[0]);
    
    let columns = [
        {
            // TODO: Change
            dataField: 'csb_number',
            text: 'CSB Number',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: (cell) => statusOptions[cell],
            filter: selectFilter({
                options: statusOptions,
                placeholder: 'Status',
            }),
        },
        {
            dataField: 'siteType_source',
            text: 'Site Type Source',
            sort: false,
        },
        {
            dataField: 'source.name',
            text: 'Generating Site',
            sort: false,
            filter: selectFilter({
                options: siteNameOptions,
                placeholder: 'Generating Site',
            }),
        },
        {
            dataField: 'source.address',
            text: 'Generating Address',
            sort: true,
            filter: selectFilter({
                options: addressOptions,
                placeholder: 'Generating Address',
            }),
        },
        {
            dataField: 'source.borough',
            text: 'Generating Borough',
            sort: true,
            filter: selectFilter({
                options: boroughOptions,
                placeholder: 'Gen Borough',
            }),
        },
        {
            dataField: 'source.meta.oer_proj_num',
            formatter: (cell, row) => ProjectNumberFormatter(cell, row),
            text: 'OER # - Gen.',
            sort: true,
        },
        {
            dataField: 'siteType_destination',
            text: 'Site Type Destination',
            sort: false,
        },
        {
            dataField: 'destination.name',
            text: 'Receiving Site',
            sort: true,
            filter: selectFilter({
                options: siteNameOptions,
                placeholder: 'Receiving Site',
            }),
        },
        {
            dataField: 'destination.address',
            text: 'Receiving Address',
            sort: true,
            filter: selectFilter({
                options: addressOptions,
                placeholder: 'Receiving Address',
            }),
        },
        {
            dataField: 'destination.borough',
            text: 'Receiving Borough',
            sort: true,
            filter: selectFilter({
                options: boroughOptions,
                placeholder: 'Receiving Borough',
            }),
        },
        {
            dataField: 'destination.meta.oer_proj_num',
            formatter: (cell, row) => ProjectNumberFormatter(cell, row),
            text: 'OER # - Rec.',
            sort: true,
        },
        {
            dataField: 'ant_start',
            text: 'Ant. Start',
            sort: true,
            filter: dateFilter(),
        },
        {
            dataField: 'ant_end',
            text: 'Ant. End',
            sort: true,
            filter: dateFilter(),
        },
        {
            dataField: 'act_start',
            text: 'Act. Start',
            sort: true,
            filter: dateFilter(),
        },
        {
            dataField: 'act_end',
            text: 'Act. End',
            sort: true,
            filter: dateFilter(),
        },
        {
            dataField: 'soil_transferred',
            text: 'Amount (CY)',
            sort: true,
        },
        {
            dataField: 'track_one_soil_transferred',
            text: 'UUSCO (CY)',
            sort: true,
        },
        {
            dataField: 'track_two_soil_transferred',
            text: 'GF (CY)',
            sort: true,
        },
        {
            dataField: 'track_three_soil_transferred',
            text: 'RRSCO (CY)',
            sort: true,
        },
        {
            dataField: 'notes',
            text: 'Notes',
            sort: false,
            formatter: (cell) => <p className={'notes-cell'}>{cell}</p>,
        },
        {
            dataField: 'source_alt.alt_address',
            text: 'Source Alternate Address',
            sort: false,
            formatter: (cell) => <p className={'notes-cell'}>{cell}</p>,
        },
        // {
        //     dataField: 'source_alt.alt_lat',
        //     text: 'Source Alternate Latitude',
        //     sort: false,
        // },
        // {
        //     dataField: 'source_alt.alt_lng',
        //     text: 'Source Alternate Longitude',
        //     sort: false,
        // },
        {
            dataField: 'destination_alt.alt_address',
            text: 'Destination Alternate Address',
            sort: false,
            formatter: (cell) => <p className={'notes-cell'}>{cell}</p>,
        },
        // {
        //     dataField: 'destination_alt.alt_lat',
        //     text: 'Destination Alternate Latitude',
        //     sort: false,
        // },
        // {
        //     dataField: 'destination_alt.alt_lng',
        //     text: 'Destination Alternate Longitude',
        //     sort: false,
        // },
        // {
        //     dataField: 'csb_number',
        //     text: 'Files',
        //     sort: false,
        //     formatter: (cell) => (
        //         <a
        //             className={'files-cell'}
        //             href={`${process.env.REACT_APP_UPLOADS_API_URL}/${cell}`}
        //             target='_blank'
        //         >
        //             Click to View Files
        //         </a>
        //     ),
        // },
    ];

    columns = columns.map((col) => {
        if (Object.keys(col).includes('headerStyle')) {
            return col;
        }
        col.headerStyle = () => {
            return {
                whiteSpace: 'nowrap',
                verticalAlign: 'baseline',
                minWidth: '100px',
            };
        };
        return col;
    });

    return (
        <Container fluid>
            <ToolkitProvider
                bootstrap4
                // TODO: Change
                keyField='id'
                data={cpTransfers}
                columns={columns}
                search
                exportCSV={{
                    fileName: 'transfers.csv',
                    onlyExportFiltered: true,
                    exportAll: false,
                }}
            >
                {(props) => (
                    <>
                        <Row>
                            <Col className='text-right'>
                                <SearchBar {...props.searchProps} />
                            </Col>
                            <div className='text-right mb-1'>
                                <ExportCSVButton
                                    {...props.csvProps}
                                    className='btn-primary'
                                >
                                    Export to CSV
                                </ExportCSVButton>
                            </div>
                        </Row>
                        <BootstrapTable
                            striped
                            hover
                            condensed
                            responsive
                            {...props.baseProps}
                            pagination={paginationFactory()}
                            filter={filterFactory()}
                            noDataIndication='Table is Empty'
                        />
                    </>
                )}
            </ToolkitProvider>
        </Container>
    );
};
