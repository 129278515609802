import React, {useState,useContext, useRef, useEffect} from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import {createAlternateLocation} from "../../api/index"
import { Typeahead } from 'react-bootstrap-typeahead';
import { GlobalContext } from '../../context/GlobalState';

export const CreateAltLocation = ({afterSubmit})=>{
    const {
        query: { alternateLocations },
    } = useContext(GlobalContext); 

    const typeahead = useRef(null);
    //state that save user input
    const [inputAltLocation, setInputAltLocation] = useState({
        // "alt_address":"",
        "alt_lat":"",
        "alt_lng":"",
        "facility_name":"",
        "alt_street_name":"",
        "alt_city":"",
        "alt_state":"",
        "alt_zipcode":"",
    });
    const [invaildFacility, setInvaildFacility] = useState(false)
    //handle user input data change
    const handleUserInput = (e)=>{
        let {name,value} = e.target;
        if(name==="facility_name"){
            if(inputAltLocation.facility_name !== value){setInvaildFacility(false)}
        }
        setInputAltLocation((oldData)=>{
            return {
                ...oldData,
                [name]:value,
            };
        });
    };
    const handleSelectionInput = (input,e)=>{
        //console.log(input)
        setInputAltLocation((oldData)=>{
            return {
                ...oldData,
                ['alt_address']:input,
            };
        });
    };
    const handleUserSelectionInput = (input,e)=>{
        // console.log(input[0]['alt_street_name'])
        // fill up fileds with selected alternate location
        document.getElementsByName("alt_street_name")[0].setAttribute('value',input[0]['alt_street_name']);
        document.getElementsByName("alt_city")[0].setAttribute('value',input[0]['alt_city']);
        document.getElementsByName("alt_state")[0].setAttribute('value',input[0]['alt_state']);
        document.getElementsByName("alt_zipcode")[0].setAttribute('value',input[0]['alt_zipcode']);
        document.getElementsByName("alt_lat")[0].setAttribute('value',input[0]['alt_lat']);
        document.getElementsByName("alt_lng")[0].setAttribute('value',input[0]['alt_lng']);
        document.getElementsByName("facility_name")[0].setAttribute('value',input[0]['facility_name']);
        //also update the inputAltLocation
        setInputAltLocation((oldData)=>{
            return {
                ...oldData,
                ["alt_lat"]:input[0]['alt_lat'],
                ["alt_lng"]:input[0]['alt_lng'],
                ["facility_name"]:input[0]['facility_name'],
                ["alt_street_name"]:input[0]['alt_street_name'],
                ["alt_city"]:input[0]['alt_city'],
                ["alt_state"]:input[0]['alt_state'],
                ["alt_zipcode"]:input[0]['alt_zipcode'],
            }
        })
        if(input[0]===undefined){return}
        alert("the alternate address already exits, please make sure you enter a differnt facility name")
        setInputAltLocation((oldData)=>{
            return {
                ...oldData,
                ['alt_address']:input[0].alt_address,
                ['facility_name']:input[0].facility_name
            };
        });
        setInvaildFacility(true)
    };
    
    //handle create new user to the server
    const handleSubmit = async(e)=>{
        e.preventDefault();

        let btn = document.getElementById("subbtn")
        btn.disabled=true
        btn.innerText="Sending....."
        // no need alt_address, now alt_address= alt_street_name+", "+alt_city+", "+alt_state+", "+alt_zipcode,
        // if(inputAltLocation.alt_address === '' || inputAltLocation.alt_address === null){
        //     alert("please enter an alternate address")
        //     return
        // }
        //need to check the correcness for input vlaue lat&lng
        if(!inputAltLocation.alt_lat.match(/^[-]?[0-9]*\.?[0-9]+$/g) || !inputAltLocation.alt_lng.match(/^[-]?[0-9]*\.?[0-9]+$/g)){
            alert("invaild lat or lng input, try again....")
            return
        }
        const result = await createAlternateLocation(inputAltLocation);
        //console.log(result.success);
        
        if(result.success){
            alert("Alternate Location create successfully!");
            if(afterSubmit){afterSubmit()}
            window.location.reload();
        }else{
            alert("Alternate Location create failed!");
        }
    };
    
    const handleReset = ()=>{
        typeahead.current.clear();
        document.getElementsByName("alt_street_name")[0].setAttribute('value','');
        document.getElementsByName("alt_city")[0].setAttribute('value','');
        document.getElementsByName("alt_state")[0].setAttribute('value','');
        document.getElementsByName("alt_zipcode")[0].setAttribute('value','');
        document.getElementsByName("alt_lat")[0].setAttribute('value','');
        document.getElementsByName("alt_lng")[0].setAttribute('value','');
        document.getElementsByName("facility_name")[0].setAttribute('value','');
    }

    //throttle function here
    return (
        <Container fluid='md'>
            <Form id='altlocationForm' onSubmit={(e)=>handleSubmit(e)}>  
            <Form.Label>Alternate Address</Form.Label>
                <Form.Group className="mb-3" controlId="formBasicFName">
                    <Typeahead
                        //filterBy={filterByFields}
                        id="alt_address"
                        labelKey="alt_address" 
                        ref={typeahead}
                        onInputChange={handleSelectionInput}
                        onChange={handleUserSelectionInput}
                        options={alternateLocations.filter(ele=>ele.active)} //only show active alternate location for selector
                        placeholder="Pick an address If you need"
                        renderMenuItemChildren={(option)=>{
                            return <div>
                                {option.alt_address}
                                <div>
                                    <small>Facility Name: {option.facility_name?option.facility_name:"None"}</small>
                                </div>
                            </div>
                        }}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Street Name</Form.Label>
                    <Form.Control type="text" placeholder="street name" name="alt_street_name" onChange={(e)=>handleUserInput(e)} required/>
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text" placeholder="city" name="alt_city" onChange={(e)=>handleUserInput(e)} required/>
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>State</Form.Label>
                    <Form.Control type="text" placeholder="State" name="alt_state" onChange={(e)=>handleUserInput(e)} required/>
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>ZipCode</Form.Label>
                    <Form.Control type="text" placeholder="zipcode" name="alt_zipcode" onChange={(e)=>handleUserInput(e)} required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicLName">
                    <Form.Label>Alternate Latitude</Form.Label>
                    <Form.Control type="text" placeholder="Enter Latitude" name="alt_lat" onChange={(e)=>handleUserInput(e)} required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicLName">
                    <Form.Label>Alternate Longitude</Form.Label>
                    <Form.Control type="text" placeholder="Enter Longitude" name="alt_lng" onChange={(e)=>handleUserInput(e)} required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicLName">
                    <Form.Label>Facility Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter Facility Name" name="facility_name"  isInvalid={invaildFacility} onChange={(e)=>handleUserInput(e)} required/>
                    <Form.Control.Feedback type='invalid'>
                            please enter a different facility name.
                    </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" id="subbtn">
                    Submit
                </Button>
                {" "}
                <Button variant="secondary" type="reset" onClick={handleReset}>
                    Reset
                </Button>
            </Form>
        </Container>    
    );
};
