import React from 'react';
import { Map, TileLayer } from 'react-leaflet';
import { DEFAULT_CENTER } from '../../../../utils/smartmatching';

export const ManualLocationInput = ({ show, onChange, hide, marker }) => {
    if (show)
        return (
            <>
                <Map
                    id='map'
                    onclick={(e) => onChange(e)}
                    // maxBounds={[]}
                    center={DEFAULT_CENTER}
                    zoom={12}
                    className='h-50vh'
                    style={{ width: '100%' }}
                >
                    {marker}
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    />
                </Map>
                <p className='btn text-info' onClick={hide}>
                    Cancel
                </p>
            </>
        );
    else {
        return <></>;
    }
};
