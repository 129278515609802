import React, { useContext, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { GlobalContext } from '../../context/GlobalState';
import { Container, Modal} from 'react-bootstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import {Button, Form} from 'react-bootstrap';
import {updateUser,changePassword} from '../../api/index';
//import PwdModal from './PasswordModal'; //not user this pwdModal currently
export const UserManagement = () => {

    //loading up all user information from GlobalContext
    const {query:{users}} = useContext(GlobalContext);
    const [showVal, setShowVal] = useState(false);

    //save current selected user inforamtion
    const handleShow = (sel_user)=>{
        setShowVal(true);
        setSelectedUser(sel_user);
    };
    const handleClose = ()=> setShowVal(false);
    const [selectedUser,setSelectedUser] = useState({});
    const [newPwdSet, setNewPwd] = useState({newPwd:"",reNewPwd:""});

    //show password change dialog  
    const passwordFormatter=(cell, row, rowIndex, formatExtraData)=>{
        return <Button onClick={()=>handleShow(row)}>change password</Button>;
    }
    //handle user input data change
    const handleChangePwd = (e)=>{
        const {name,value} = e.target;
        setNewPwd((oldData)=>{
            return {
                ...oldData,
                [name]:value,
            }
        })
    };
    //this function will do request for change password;
    const handleSubmitPwd = async()=>{   
        // debonce setting
        let btn = document.getElementById("pwdsubbtn")
        btn.disabled=true
        btn.innerText="Sending....."
        if(newPwdSet.newPwd =='' || newPwdSet.reNewPwd== ''){
            alert('Please enter a password');
            btn.disabled=false
            btn.innerText="Save"
            return;
        }
        if(newPwdSet.newPwd !== newPwdSet.reNewPwd){
            alert('enterd new password not match, try again!');
            btn.disabled=false
            btn.innerText="Save"
            return;
        }
        //call the api-->index.js -->changepassword()
        const result = await changePassword(selectedUser.email,newPwdSet.newPwd);
        // console.log(result.success);
        if(result.success){
            alert("password changed successfully!");
        }else{
            alert("password changed Failed! Try again.");
        }
        handleClose();
    };
    const activeFormatter=(cell, row)=>{
        return <Form.Check type='checkbox' name="active" onChange={(e)=>handleActiveChange(e,row,cell)} defaultChecked={cell} />;
    }
    const adminFormatter=(cell, row)=>{
        return <Form.Check type='checkbox' name="admin" onChange={(e)=>handleAdminChange(e,row,cell)} defaultChecked={cell} />;
    }
    //copy another one for admin change
    const handleActiveChange = async(e,data,cell)=>{
        e.persist()
        e.target.disabled = true
        // console.log(e.target.name,e.target.checked)
        // console.log(data)
        data['active'] = e.target.checked
        const row = data
        const result = await updateUser(row);
            // console.log(result.success);
            if(result){
                alert(row.email+" user active has been changed");
        }

        e.target.disabled = false
    }
    const handleAdminChange = async(e,data,cell)=>{
        e.persist()
        e.target.disabled = true
        // console.log(e.target.name,e.target.checked)
        
        data['user_type'] = e.target.checked
        const row = data
        const result = await updateUser(row);
            // console.log(result.success);
            if(result){
                alert(row.email+" user type has been changed");
        }

        e.target.disabled = false
    }
    //list of columns for table
    const columns = [
        {
            dataField:'id',
            text:'ID',
            editable:()=>{
                return false;
            },
        },
        {
            dataField:'first_name',
            text:'First',
            editable:()=>{
                return false;
            },
        },
        {
            dataField:'last_name',
            text:'Last',
            editable:()=>{
                return false;
            },
        },
        {
            dataField:'user_type',
            text:'Admin User',
            type:'bool',
            formatter:adminFormatter,
           
        },
        {
            dataField:'active',
            text:'Active',
            type:'bool',
            formatter:activeFormatter,
            
        },
        {
            dataField:'changePassword',
            text:'ChangePassword',
            isDummyField:true,
            formatter:passwordFormatter,
        },
    ];
    //high line selected row
    const selectRow = {
        mode:'radio',
        style:{ border:'2px solid red'},
        clickToSelect:true,
        clickToEdit:true,
    };


    return (
        <Container fluid>
        <Modal show={showVal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Change password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formBChangePassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" controlid='newPwd' name='newPwd' onChange={handleChangePwd} required={true}/>
                        <Form.Label>Re-Password</Form.Label>
                        <Form.Control type="password" placeholder="Re-Password" controlid='reNewPwd' name='reNewPwd' onChange={handleChangePwd} required={true}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={handleClose} >Close</Button>
                <Button variant='Primary' onClick={handleSubmitPwd} id='pwdsubbtn'>Save</Button>
            </Modal.Footer>
        </Modal>
            <ToolkitProvider
                bootstrap4
                keyField='id'
                data={users}
                columns={columns}
            >
                {(props) => (
                    <>
                        <BootstrapTable
                            selectRow={selectRow}
                        // cellEdit={cellEditFactory(cellEdit)}
                            striped
                            hover
                            condensed
                            responsive
                            {...props.baseProps}
                            noDataIndication='Table is Empty'
                        ></BootstrapTable>
                    </>
                )}
            </ToolkitProvider>
        </Container>
    );
};