import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { LogoutButton } from '../Buttons/LogoutButton';
import { LinkContainer } from 'react-router-bootstrap';
import './style.css';
import { API_URL } from '../../api/queries';
import { isUserAdmin } from '../../api/auth';

export const Header = () => {
    //in here need to check if user is right type to user mangaemnet
    let [admin,setAdmin]= useState(false);
    useEffect(()=>{
        let chekcUserType = async ()=>{
            let result =  await isUserAdmin();
            setAdmin(result);
        };
        chekcUserType();
    },[])
    return (
        <header>
            <Navbar bg='dark' variant='dark' expand='lg' collapseOnSelect>
                <LinkContainer to='/'>
                    <Navbar.Brand>Clean Soil Bank</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav>
                        <LinkContainer to='/'>
                            <Nav.Link href='/'>Home</Nav.Link>
                        </LinkContainer>
                       
                        <NavDropdown
                            title='Site Management'
                            id='site-management-dropdown'
                        >
                            <LinkContainer to='/create/site'>
                                <NavDropdown.Item>
                                    Create New Site
                                </NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                            <LinkContainer to='/sites/'>
                                <NavDropdown.Item>
                                    Manage Existing Site
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>

                        <NavDropdown
                            title='Soil Management'
                            id='soil-management-dropdown'
                        >
                            <LinkContainer to='/create/availability_request'>
                                <NavDropdown.Item>
                                    New Availability/Request
                                </NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                            <LinkContainer to='/create/transfer'>
                                <NavDropdown.Item>
                                    Create New Transfer
                                </NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                            <LinkContainer to='/transfers/all/'>
                                <NavDropdown.Item>
                                    View All Transfers
                                </NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                            <LinkContainer to='/alternateLocations/'>
                                <NavDropdown.Item>
                                    View All Alternate Locations
                                </NavDropdown.Item>
                            </LinkContainer>
                            {/* TODO: Get Feedback on Whether Implementation is Necessary */}
                            {/* <LinkContainer to='/manage/availability_request'>
                                <NavDropdown.Divider />
                                <NavDropdown.Item>
                                    Manage Availabilities & Requests
                                </NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                            <LinkContainer to='/manage/transfers'>
                                <NavDropdown.Item>
                                    Manage Existing Transfers
                                </NavDropdown.Item>
                            </LinkContainer> */}
                        </NavDropdown>
                        <NavDropdown
                            title='Matching Tool'
                            id='matching-tool-dropdown'
                        >
                            <LinkContainer to='/matching/'>
                                <NavDropdown.Item>
                                    Matching Tool
                                </NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                href={`${API_URL}/match/download`}
                                target='_blank'
                            >
                                Export Matching Data
                            </NavDropdown.Item>
                        </NavDropdown>
                        {
                            admin?
                            <NavDropdown
                                title='Management'
                                id='user-management-dropdown'>
                                <LinkContainer to='/usermanagement/createUser'>
                                    <NavDropdown.Item>Create User</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/usermanagement/'>
                                    <NavDropdown.Item>Manage User Status</NavDropdown.Item>
                                </LinkContainer>
                            <NavDropdown.Divider />
                                
                                <NavDropdown.Item
                                href={`${API_URL}/sites/opendata`}
                                target='_blank'
                                >
                                Export Speed Open Data
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                    href={`${API_URL}/ghg`}
                                    target='_blank'
                                >
                                    Export GHG Emissions Data
                                </NavDropdown.Item>
                            </NavDropdown>
                            :
                            <></>
                        }
                        <div className='nav-fixed-right'>
                            <LogoutButton />
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
};
