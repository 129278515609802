import * as actions from '../../Shared/constants';

export default (state, action) => {
    switch (action.type) {
        case actions.SET_CSB_ELIGIBLE:
            return {
                ...state,
                csb_eligible: action.payload,
            };
        case actions.SET_STOCKPILE_ELIGIBLE:
            return {
                ...state,
                stockpile_eligible: action.payload,
            };
        case actions.SET_SOIL_TYPE:
            return {
                ...state,
                soil_types: action.payload,
            };
        case actions.SET_STOCKPILE_TYPE:
            return {
                ...state,
                stockpile_types: action.payload,
            };
        case actions.TOGGLE_GEOTECHNICAL_REQUIREMENTS:
            return {
                ...state,
                geotechnical_requirements: action.payload,
            };
        case actions.SET_GEOTECHNICAL_DETAILS:
            return {
                ...state,
                geotechnical_details: action.payload,
            };
        case actions.SET_EXPIRATION_DATE:
            return {
                ...state,
                expiration_date: action.payload,
            };
        case actions.SET_LOADS_PER_DAY:
            return {
                ...state,
                loads_per_day: action.payload,
            };
        case actions.SET_PROPOSED_USAGE:
            return {
                ...state,
                proposed_usage: action.payload,
            };
        case actions.SET_ADDITIONAL_NOTES:
            return {
                ...state,
                notes: action.payload,
            };
        default:
            return state;
    }
};
