import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Modal, Button, Container, Form } from 'react-bootstrap';
import { GlobalContext } from '../../context/GlobalState';
import { CY_TO_TN_FACTOR, deepCopy, statusOptions } from '../../utils/';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { numberCellFormatter, transferAmountFormatter } from './helpers';
import {
    TransferFormContainer,
    TransferFormEditContainer,
} from '../Forms/Transfer/Container';
import { runQuery } from '../../api';
import { API_URL } from '../../api/queries';
/*
    This Component Will Render all of the Transfers
    Where the Site is a Generating or Receiving Site
*/
// TODO: Add Generating or Receiving Filter on Table
export const SiteTransfers = ({ site }) => {
    const { setQuery } = useContext(GlobalContext);
    const modes = {
        EDITING: 'EDITING',
        DELETING: 'DELETING',
        CREATING: 'CREATING',
    };
    const [modal, setModal] = useState(false);
    const [mode, setMode] = useState(null);
    const [selected, setSelected] = useState(null);

    const {
        query: { transfers },
    } = useContext(GlobalContext);

    let currTransfers = deepCopy(
        transfers.filter(({ source, destination }) => {
            // Non-Inclusive Because a Site Cannot Transfer to Itself
            return (site.id === source.id) ^ (site.id === destination.id);
        })
    );

    // Determine if Transfer is Incoming or Outgoing Relative to the Current Site
    const evalIncomingOutgoing = (t) => {
        return t.source.id === site.id ? 'Outgoing' : 'Incoming';
    };
    currTransfers = currTransfers.map((t) => {
        t.soil_transferred_tn = (t.soil_transferred * CY_TO_TN_FACTOR).toFixed(
            2
        );
        t.type = evalIncomingOutgoing(t);
        return t;
    });

    const editButtonFormatter = (cell, row) => {
        return (
            <Button
                onClick={() => {
                    setModal(true);
                    setMode(modes.EDITING);
                    setSelected(row);
                }}
                variant='info'
                id={`edit-button-${cell}`}
            >
                Edit
            </Button>
        );
    };

    const deleteButtonFormatter = (cell, row) => {
        return (
            <Button
                onClick={() => {
                    setModal(true);
                    setMode(modes.DELETING);
                    setSelected(row);
                }}
                variant='danger'
                id={`delete-button-${cell}`}
            >
                Delete
            </Button>
        );
    };

    const dateFormatter = (cell) => new Date(cell).toISOString().split('T')[0];

    /* 
    Table Will Have a Column for Dates and Total Soil
    and Will Also Include a Dropdown for the
    Specific Types of Soils and Quantities
    as Well as Where it Transferred To
    */
    let transferColumns = [
        {
            dataField: 'csb_number',
            text: 'CSB Number',
            sort: true,
        },
        {
            dataField: 'type',
            text: 'Type',
        },
        {
            dataField: 'soil_transferred',
            text: 'Amount (CY)',
            formatter: transferAmountFormatter,
        },
        {
            dataField: 'soil_transferred_tn',
            text: 'Amount (TN)',
            formatter: numberCellFormatter,
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cell) => statusOptions[cell],
        },
        {
            dataField: 'created',
            text: 'Created At',
            sort: true,
            formatter: dateFormatter,
        },
        {
            dataField: 'modified',
            text: 'Last Updated',
            sort: true,
            formatter: dateFormatter,
        },
        {
            dataField: 'id',
            key: 'first',
            text: 'Edit',
            formatter: editButtonFormatter,
        },
        {
            dataField: 'dupe_id',
            text: 'Delete',
            formatter: deleteButtonFormatter,
        },
    ];

    const modalRenderSwitch = () => {
        if (!modal) return;
        switch (mode) {
            case modes.CREATING:
                return (
                    <Modal
                        show={modal}
                        onHide={() => {
                            setModal(false);
                            setSelected(null);
                        }}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Create New Transfer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <TransferFormContainer
                                afterSubmit={() => setModal(false)}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant='danger'
                                onClick={() => {
                                    setModal(false);
                                    setSelected(null);
                                }}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                );
            case modes.EDITING:
                return (
                    <Modal
                        show={modal}
                        onHide={() => {
                            setModal(false);
                            setSelected(null);
                        }}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Transfer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <TransferFormEditContainer
                                transfer={selected}
                                afterSubmit={() => setModal(false)}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant='danger'
                                onClick={() => {
                                    setModal(false);
                                    setSelected(null);
                                }}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                );
            case modes.DELETING:
                return (
                    <Modal
                        show={modal}
                        onHide={() => {
                            setModal(false);
                            setSelected(null);
                        }}
                    >
                        <Form
                            onSubmit={async (e) => {
                                console.log('hi');
                                e.preventDefault();

                                try {
                                    let url = `${API_URL}/transfers/${selected.id}`;

                                    const res = await fetch(url, {
                                        method: 'DELETE',
                                        credentials: 'include',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                    });

                                    const json = await res.json();

                                    const data = await runQuery();
                                    await setQuery(data);

                                    setModal(false);
                                    console.log(json);
                                } catch (err) {
                                    console.log(err);
                                }
                            }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Transfer</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are You Sure You Want to Delete this Transfer?
                                This Action Cannot Be Undone.
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='danger' type='submit'>
                                    Delete
                                </Button>
                                <Button
                                    variant='info'
                                    onClick={() => {
                                        setModal(false);
                                        setSelected(null);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                );
        }
    };

    return (
        <Container fluid className='p-0'>
            {modalRenderSwitch()}
            <div className='d-flex justify-content-between'>
                <h2>Transfers</h2>
                <Button
                    onClick={() => {
                        setModal(true);
                        setMode(modes.CREATING);
                    }}
                    className='align-self-end mb-2'
                >
                    Create Transfer
                </Button>
            </div>
            <ToolkitProvider
                bootstrap4
                keyField='csb_number'
                data={currTransfers}
                columns={transferColumns}
            >
                {(props) => (
                    <BootstrapTable
                        striped
                        hover
                        condensed
                        responsive
                        pagination={paginationFactory()}
                        noDataIndication='No Transfers Found.'
                        {...props.baseProps}
                    />
                )}
            </ToolkitProvider>
        </Container>
    );
};

SiteTransfers.propTypes = {
    site: PropTypes.object.isRequired,
};
