import React, { useContext, useEffect, useState } from 'react';
import { Map, Marker, TileLayer, Popup } from 'react-leaflet';
import { redIcon } from '../../MapIcons';
import { SmartMatchingContext } from '../../../context/SmartMatchingContext';
import { categoricalIconPicker } from '../helpers';
import { Legend } from './Legend';
import { DEFAULT_CENTER } from '../../../utils/smartmatching';
import { GlobalContext } from '../../../context/GlobalState';
import { deepCopy } from '../../../utils';

export const MatchingMap = React.forwardRef(({}, ref) => {
    const MAP = useContext(SmartMatchingContext);
    const {
        query: { sites },
    } = useContext(GlobalContext);
    const [position, setPosition] = useState(DEFAULT_CENTER);
    const [zoom, setZoom] = useState(12);

    useEffect(() => {
        MAP.setSecondarySite(null);

        if (!MAP.selected_site)
            MAP.setFilteredSites(
                deepCopy(sites).filter(
                    (site) =>
                        // TODO: Add Stockpile Math to This
                        site.total_soil_generated -
                            site.total_soil_transferred >
                            0 ||
                        site.total_soil_requested - site.total_soil_received > 0
                )
            );
    }, [MAP.selected_site]);

    return (
        <Map
            id='map'
            center={
                MAP.selected_site
                    ? [MAP.selected_site.lat, MAP.selected_site.lng]
                    : position
            }
            zoom={zoom}
            className='h-50 h-md-100 matching-map'
            ref={ref}
        >
            {MAP.selected_site ? (
                <Marker
                    key='selected-site'
                    icon={redIcon}
                    onclick={(e) => {
                        // Set Zoom First to Avoid Zooming Off-Center
                        setZoom(17);
                        // console.log(e.latlng);  [MAP.selected_site.lat, MAP.selected_site.lng]
                        setPosition(e.latlng);
                    }}
                    position={
                            [MAP.selected_site.lat, MAP.selected_site.lng]
                        }
                >
                    {/* FIXME: Change this to Something More Useful */}
                    <Popup>{MAP.selected_site.address}</Popup>
                </Marker>
            ) : (
                []
            )}

            {/* Map All the Sites */}
            {!MAP.secondary_site ? (
                MAP.filtered_sites?.map((site, it) => {
                    if (site.lat && site.lng) {
                        return (
                            <Marker
                                key={it}
                                icon={categoricalIconPicker(site)}
                                onclick={(e) => {
                                    // Get Clicked Marker
                                    const selected_marker = MAP.filtered_sites.filter(
                                        ({ lat, lng }) =>
                                            e.latlng.equals({
                                                lat,
                                                lng,
                                            })
                                    )[0];

                                    setZoom(17);
                                    setPosition([
                                        selected_marker.lat,
                                        selected_marker.lng,
                                    ]);
                                }}
                                position={[site.lat, site.lng]}
                            >
                                <Popup>{site.address}</Popup>
                            </Marker>
                        );
                    }
                })
            ) : (
                <Marker
                    key={'secondary-site'}
                    icon={categoricalIconPicker(MAP.secondary_site)}
                    onclick={(e) => {
                        // Get Clicked Marker
                        setZoom(17);
                        setPosition([
                            MAP.secondary_site.lat,
                            MAP.secondary_site.lng,
                        ]);
                    }}
                    position={[MAP.secondary_site.lat, MAP.secondary_site.lng]}
                >
                    <Popup>{MAP.secondary_site.address}</Popup>
                </Marker>
            )}

            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <Legend />
        </Map>
    );
});
