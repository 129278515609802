import React, { useContext, useState, useEffect} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { GlobalContext } from '../../context/GlobalState';
import { Container, Form} from 'react-bootstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import {Button, Modal} from 'react-bootstrap';
import {updateAlternatelocation} from '../../api/index';
import {CreateAltLocation} from "./createAltLocation" 
import { isUserAdmin } from '../../api/auth';

export const AlternateLocations = () => {
    //loading up all alternative locations from GlobalContext
    const {query:{alternateLocations}} = useContext(GlobalContext);
    const [modal, setModal] = useState(false);

    //in here need to check if user is right type to user mangaemnet
    let [admin,setAdmin]= useState(false);
    useEffect(()=>{
        let chekcUserType = async ()=>{
            let result =  await isUserAdmin();
            setAdmin(result);
        };
        chekcUserType();
    },[])
   
    const activeFormatter=(cell, row, rowIndex, formatExtraData)=>{
        return <Form.Check type='checkbox' onChange={(e)=>handleActiveChange(e,row)} defaultChecked={cell} />;
    }
    const handleActiveChange = async(e,data)=>{
        e.persist()
        
        e.target.disabled = true
        data['active']= e.target.checked
        data['inactiveChange'] = true
        //call the api to save change, and reset the distance
        const result = await updateAlternatelocation(data);
        if(result.success){
            alert("the location has been updated");
        }else{
            alert('Fail to update the location, try again...')
        }
        e.target.disabled = false
    }
    //list of columns for table
    const columns = [
        {
            dataField:'id',
            text:'ID',
            editable:()=>{
                return false;
            },
        },
        {
            dataField:'alt_street_name',
            text:'alt_street_name',
            sort:true,
            editable:()=>{
                return admin?true:false;
            },
        },
        {
            dataField:'alt_city',
            text:'alt_city',
            sort:true,
            editable:()=>{
                return admin?true:false;
            },
        },
        {
            dataField:'alt_state',
            text:'alt_state',
            sort:true,
            editable:()=>{
                return admin?true:false;
            },
        },
        {
            dataField:'alt_zipcode',
            text:'alt_zipcode',
            editable:()=>{
                return admin?true:false;
            },
        },
        {
            dataField:'alt_lat',
            text:'alt_lat',
            editable:()=>{
                return admin?true:false;
            },
        },
        {
            dataField:'alt_lng',
            text:'alt_lng',
            editable:()=>{
                return admin?true:false;
            },
        },
        {
            dataField:'facility_name',
            text:'Facility Name',
            sort:true,
            editable:()=>{
                return admin?true:false;
            },
        },
        {
            dataField:'active',
            text:'Active',
            type:'bool',
            sort:true, 
            formatter:activeFormatter,
            // editable:()=>{
            //     return false;
            // },
        },
    ];

    //high line selected row
    const selectRow = {
        mode:'radio',
        style:{ border:'2px solid red'},
        clickToSelect:true,
        clickToEdit:true,
    };

    //hanlde edit cell, save new value to backend
    const cellEdit ={
        mode:'dbclick',
        blurToSave: true,
        afterSaveCell: async(oldValue,newValue,row,column)=>{
            console.log(this)
            if(oldValue === newValue){return;} //no change Values just return.
            if(newValue === ''){
                alert("Alt Address can not be empty!")
                window.location.reload()
                return
            }
            if(column.dataField==='alt_lat' || column.dataField==='alt_lng'){
                if(!newValue.match(/^[-]?[0-9]*\.?[0-9]+$/g)){
                    alert("please enter a correct value for lat or lng")
                    return
                }
                //console.log("lat & lng changed")
                row['lat_lng_reset'] = true;
            }
            //call the update funciton
            
            const result = await updateAlternatelocation(row);
            //console.log(result.success);
            //show the result.
            if(result.success){
                alert("the location has been updated");
            }else{
                alert('Fail to update the location, try again...')
            }
        },
    };
    
    return (
        <Container fluid>
        <Modal
            show={modal}
            onHide={() => { setModal(false); }}
        >
                <Modal.Header closeButton>
                    <Modal.Title>Create New Alernate Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateAltLocation
                        afterSubmit={()=>setModal(false)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='danger'
                        onClick={() => {
                            setModal(false);
                        }}
                    >
                    Cancel
                    </Button>
                </Modal.Footer>
        </Modal>
            <ToolkitProvider
                bootstrap4
                keyField='id'
                data={alternateLocations}
                columns={columns}
            >
                {(props) => (
                    <>
                        <BootstrapTable
                        selectRow={selectRow}
                        cellEdit={cellEditFactory(cellEdit)}
                            striped
                            hover
                            condensed
                            responsive
                            {...props.baseProps}
                            noDataIndication='Table is Empty'
                        ></BootstrapTable>
                    </>
                )}
            </ToolkitProvider>
            <div>
                <Button onClick={()=>setModal(true)}>Create New Alternate Location</Button>
            </div>
        </Container>
    );
};