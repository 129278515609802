import React from 'react';
import PropTypes from 'prop-types';
import { Col, OverlayTrigger } from 'react-bootstrap';
import { GrCircleInformation } from 'react-icons/gr';
import { CY_TO_TN_FACTOR } from '../../utils';

export const SiteMetaCol = ({ label, num, overlayContent }) => {
    return (
        <Col className='d-flex justify-content-between justify-content-sm-evenly'>
            <div>
                <p className='d-inline'>{label} </p>
                <OverlayTrigger
                    trigger='click'
                    placement='bottom'
                    overlay={overlayContent}
                >
                    <GrCircleInformation onClick={(e) => e.stopPropagation()} />
                </OverlayTrigger>
            </div>
            <div
                className='d-flex justify-content-between'
                style={{ minWidth: '20%' }}
            >
                <p className='d-inline'>{Number(num).toLocaleString()}</p>

                <p className='d-inline ml-4'>
                    {Number(
                        (num * CY_TO_TN_FACTOR).toFixed(2)
                    ).toLocaleString()}
                </p>
            </div>
        </Col>
    );
};

SiteMetaCol.propTypes = {
    label: PropTypes.string.isRequired,
    num: PropTypes.number.isRequired,
    overlayContent: PropTypes.node.isRequired,
};
