import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container, Collapse, Modal, Button } from 'react-bootstrap';
import { metaFieldCalc, trackBreakdownPopOver } from './helpers';
import { SiteProfileEditForm } from '../Forms/SiteProfile';
import { SiteMetaCol } from './SiteMetaCol';
export const SiteMeta = ({ site }) => {
    const [open, setOpen] = useState(false);
    const [editing, setEditing] = useState(false);
    const [primaryContact, setPrimaryContact] = useState(false);
    const {
        name,
        meta: { oer_proj_num, notes, primary_contact },
        address,
        borough,
        site_types_relations,
        program_types_relations,
    } = site;
    
    const extra_fields = metaFieldCalc(site, site.stockpile);

    return (
        <Container fluid className='my-3 p-3 text-align-right border'>
            <Modal show={editing} onHide={() => setEditing(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Site Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SiteProfileEditForm
                        site={site}
                        afterSubmit={() => setEditing(false)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='danger'
                        onClick={() => {
                            setOpen(false);
                            setEditing(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Col className='d-flex flex-row w-100'>
                {/* Heading and Edit Button*/}
                <h3 className='text-center w-100'>{name} - Site Profile</h3>
                <p className='btn p-0 m-0 text-info'>
                    <u onClick={() => setEditing(true)}>Edit</u>
                </p>
            </Col>

            <Row className='col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'>
                {/* OER Project Number */}
                <Col className='d-flex justify-content-between justify-content-sm-evenly'>
                    <p>TMP Number:</p>
                    <p>{oer_proj_num}</p>
                </Col>

                <Col className='d-flex justify-content-between justify-content-sm-evenly'>
                    {/* Address */}
                    <p>Address:</p>
                    <p>
                        {address}, {borough}
                    </p>
                </Col>
            </Row>
            <Row className='col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'>
                {/* Soil Units */}
                <Col className='d-flex justify-content-end justify-content-sm-evenly'>
                    <div
                        className='d-flex justify-content-between'
                        style={{ minWidth: '20%' }}
                    >
                        <p className='d-inline'>
                            <strong>CY</strong>
                        </p>
                        <p className='d-inline ml-4'>
                            <strong>TN</strong>
                        </p>
                    </div>
                </Col>
            </Row>
            <Row
                onClick={() => setOpen((curr) => !curr)}
                title='Click for Details'
                className='cursor-pointer col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'
            >
                <SiteMetaCol
                    label='Current Soil Available:'
                    num={extra_fields.current_soil_available}
                    overlayContent={trackBreakdownPopOver(
                        extra_fields.current_track_one_soil_available,
                        extra_fields.current_track_two_soil_available,
                        extra_fields.current_track_three_soil_available
                    )}
                />
            </Row>
            <Row
                onClick={() => setOpen((curr) => !curr)}
                title='Click for Details'
                className='cursor-pointer col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'
            >
                <SiteMetaCol
                    label='Current Soil Capacity:'
                    num={extra_fields.current_soil_capacity}
                    overlayContent={trackBreakdownPopOver(
                        extra_fields.current_track_one_capacity,
                        extra_fields.current_track_two_capacity,
                        extra_fields.current_track_three_capacity
                    )}
                />
            </Row>
            <Collapse in={open}>
                <div>
                    <Row className='col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'>
                        <SiteMetaCol
                            label='Total Soil Generated:'
                            num={site.total_soil_generated}
                            overlayContent={trackBreakdownPopOver(
                                site.total_track_one_soil_generated,
                                site.total_track_two_soil_generated,
                                site.total_track_three_soil_generated
                            )}
                        />
                    </Row>
                    <Row className='col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'>
                        <SiteMetaCol
                            label='Total Soil Requested'
                            num={site.total_soil_requested}
                            overlayContent={trackBreakdownPopOver(
                                site.total_track_one_soil_requested,
                                site.total_track_two_soil_requested,
                                site.total_track_three_soil_requested
                            )}
                        />
                    </Row>
                    <Row className='col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'>
                        <SiteMetaCol
                            label='Total Pending Soil Generated:'
                            num={site.total_pending_soil_generated}
                            overlayContent={trackBreakdownPopOver(
                                site.total_track_one_pending_soil_generated,
                                site.total_track_two_pending_soil_generated,
                                site.total_track_three_pending_soil_generated
                            )}
                        />
                    </Row>
                    <Row className='col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'>
                        <SiteMetaCol
                            label='Total Pending Soil Requested:'
                            num={site.total_pending_soil_requested}
                            overlayContent={trackBreakdownPopOver(
                                site.total_track_one_pending_soil_requested,
                                site.total_track_two_pending_soil_requested,
                                site.total_track_three_pending_soil_requested
                            )}
                        />
                    </Row>
                    <Row className='col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'>
                        <SiteMetaCol
                            label='Total Soil Transferred:'
                            num={site.total_soil_transferred}
                            overlayContent={trackBreakdownPopOver(
                                site.total_track_one_soil_transferred,
                                site.total_track_two_soil_transferred,
                                site.total_track_three_soil_transferred
                            )}
                        />
                    </Row>
                    <Row className='col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'>
                        <SiteMetaCol
                            label='Total Soil Received'
                            num={site.total_soil_received}
                            overlayContent={trackBreakdownPopOver(
                                site.total_track_one_soil_received,
                                site.total_track_two_soil_received,
                                site.total_track_three_soil_received
                            )}
                        />
                    </Row>
                </div>
            </Collapse>
            <Row className='col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'>
                <Col className='d-flex justify-content-between justify-content-sm-evenly'>
                    {/* Site Type */}
                    <p>Site Type:</p>
                    <div>
                        <p className='d-inline text-capitalize'>
                            {site_types_relations
                                .map(({ site_type: { name } }) => name)
                                .join(', ')}
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className='col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'>
                <Col className='d-flex justify-content-between justify-content-sm-evenly'>
                    {/* Program Type */}
                    <p>Program Type:</p>
                    <p className='d-inline text-capitalize'>
                        {program_types_relations
                            .map(({ program_type: { name } }) => name)
                            .join(', ')}
                    </p>
                </Col>
            </Row>

            <Row className='col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'>
                <Col className='d-flex justify-content-between justify-content-sm-evenly'>
                    {/* Other */}
                    <p>Notes:</p>
                    <p>{notes}</p>
                </Col>
            </Row>
            <Row
                onClick={() => setPrimaryContact((curr) => !curr)}
                className='cursor-pointer col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'
                title='Click for Details'
            >
                <Col className='d-flex justify-content-between justify-content-sm-evenly'>
                    {/* Other */}
                    <p>Primary Contact:</p>
                    <p>Click to Reveal</p>
                </Col>
            </Row>
            <Collapse in={primaryContact}>
                <div>
                    <Row className='col-12 p-0 col-lg-10 mx-auto flex-column flex-md-row'>
                        <Col className='d-flex justify-content-between justify-content-sm-evenly'>
                            {/* Other */}
                            <p>Primary Contact Name:</p>
                            <p>
                                {primary_contact.firstname}{' '}
                                {primary_contact.lastname}
                            </p>
                        </Col>
                        <Col className='d-flex justify-content-between justify-content-sm-evenly'>
                            {/* Other */}
                            <p>Primary Contact Phone:</p>
                            <p>
                                {primary_contact.phone
                                    .match(/(\d{3})(\d{3})(\d{4})/)
                                    .slice(1)
                                    .join('-')}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Collapse>
        </Container>
    );
};

SiteMeta.propTypes = {
    site: PropTypes.object.isRequired,
};
