import React, { useRef } from 'react';
import { Container, Row } from 'react-bootstrap';
import { SmartMatchingProvider } from '../../context/SmartMatchingContext';
import { MatchingMap } from './Map/MatchingMap';
import { SelectionPane } from './SelectionPane/';
import './SmartMatching.css';
import { WindowSlider } from './WindowSlider';
// Container to Pass Context
export const SmartMatchingContainer = () => {
    return (
        <SmartMatchingProvider>
            <SmartMatching />
        </SmartMatchingProvider>
    );
};

export const SmartMatching = () => {
    // Ref for Changing Window Size of Map with Slider
    const map_ref = useRef(null);

    return (
        <>
            <Container fluid className='h-100 position-absolute'>
                <Row className='h-100'>
                    <MatchingMap ref={map_ref} />
                    <WindowSlider map_ref={map_ref} />
                    <SelectionPane map_ref={map_ref} />
                </Row>
            </Container>
        </>
    );
};
