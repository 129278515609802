export const CY = 'CY';
export const TN = 'TN';
export const ANT_START = 'ANT_START';
export const ANT_END = 'ANT_END';
export const ACT_START = 'ACT_START';
export const ACT_END = 'ACT_END';
export const SUBMITTING = 'SUBMITTING';
export const EDITING = 'EDITING';
export const PREVIEW = 'PREVIEW';
export const DELETING = 'DELETING';
export const CONFIRMING_DELETE = 'COMFIRMING_DELETE';
export const CONFIRMED_DELETE = 'CONFIRMED_DELETE';
export const TOGGLE_RAWP_RAP = 'TOGGLE_RAWP_RAP';
export const SET_TOP_DEPTH = 'SET_TOP_DEPTH';
export const SET_BOTTOM_DEPTH = 'SET_BOTTOM_DEPTH';
export const SET_GROUNDWATER_DEPTH = 'SET_GROUNDWATER_DEPTH';
export const TOGGLE_BORING_LOGS = 'TOGGLE_BORING_LOGS';
export const TOGGLE_GEOTECHNICAL_SAMPLES = 'TOGGLE_GEOTECHNICAL_SAMPLES';
export const TOGGLE_GEOTECHNICAL_RESULTS = 'TOGGLE GEOTECHNICAL_RESULTS';
export const TOGGLE_CHEMICAL_TESTS = 'TOGGLE_CHEMICAL_TESTS';
export const SET_PH_VALUES = 'SET_PH_VALUES';
export const TOGGLE_DIRECT_LOADING = 'TOGGLE_DIRECT_LOADING';
export const TOGGLE_SEGREGATED_SOIL_STOCKPILE =
    'TOGGLE_SEGREGATED_SOIL_STOCKPILE';
export const SET_EXCAVATION_DATE = 'SET_EXCAVATION_DATE';
export const SET_RATE_OF_EXPORT = 'SET_RATE_OF_EXPORT';
export const SET_ADDITIONAL_NOTES = 'SET_ADDITIONAL_NOTES';
export const TOGGLE_FORM_MODE = 'TOGGLE_FORM_MODE';
export const SET_SOIL_TYPE = 'SET_SOIL_TYPE';
export const SET_STOCKPILE_TYPE = 'SET_STOCKPILE_TYPE';
export const SET_UNRESTRICTED_AMOUNT = 'SET_UNRESTRICTED_AMOUNT';
export const SET_GENERAL_FILL_AMOUNT = 'SET_GENERAL_FILL_AMOUNT';
export const SET_RESTRICTED_RESIDENTIAL_AMOUNT =
    'SET_RESTRICTED_RESIDENTIAL_AMOUNT';
export const SET_OTHER_DETAILS = 'SET_OTHER_DETAILS';
export const SET_EXPIRATION_DATE = 'SET_EXPIRATION_DATE';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_BOROUGH = 'SET_BOROUGH';
export const SET_COORDS = 'SET_COORDS';
export const SET_BBL = 'SET_BBL';
export const SET_PROJECT = 'SET_PROJECT';
export const SET_INPUT_TYPE = 'SET_INPUT_TYPE';
export const SET_AMOUNT = 'SET_AMOUNT';
export const SET_UNIT = 'SET_UNIT';
export const SET_AUTOFILL = 'SET_AUTOFILL';
export const SET_VALID = 'SET_VALID';
export const SET_SITE_TYPE = 'SET_SITE_TYPE';
export const SET_SITE_TYPE_OTHER = 'SET_SITE_TYPE_OTHER';
export const SET_PROGRAM_TYPE = 'SET_PROGRAM_TYPE';
export const SET_PROGRAM_TYPE_OTHER = 'SET_PROGRAM_TYPE_OTHER';
export const SET_SOIL_REQUIREMENTS = 'SET_SOIL_REQUIREMENTS';
export const SET_ANT_AMOUNT_CY = 'SET_ANT_AMOUNT_CY';
export const SET_ANT_AMOUNT_TN = 'SET_ANT_AMOUNT_TN';
export const SET_PENDING = 'SET_PENDING';
export const SET_STOCKPILE = 'SET_STOCKPILE';
export const SET_CSB_ELIGIBLE = 'SET_CSB_ELIGIBLE';
export const SET_STOCKPILE_ELIGIBLE = 'SET_STOCKPILE_ELIGIBLE';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_TOTAL_GENERATING_CAPACITY_TN =
    'SET_TOTAL_GENERATING_CAPACITY_TN';
export const SET_TOTAL_RECEIVING_CAPACITY_TN =
    'SET_TOTAL_RECEIVING_CAPACITY_TN';
export const SET_TOTAL_GENERATING_CAPACITY_CY =
    'SET_TOTAL_GENERATING_CAPACITY_CY';
export const SET_TOTAL_RECEIVING_CAPACITY_CY =
    'SET_TOTAL_RECEIVING_CAPACITY_CY';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_PHONE = 'SET_PHONE';
export const SET_PROJECT_NAME = 'SET_PROJECT_NAME';
export const SET_OER_PROJECT = 'SET_OER_PROJECT';
export const TOGGLE_MANUAL_LOCATION = 'TOGGLE_MANUAL_LOCATION';
export const SET_SITE_PROGRAM = 'SET_SITE_PROGRAM';
export const SET_SITE_PROGRAM_OTHER = 'SET_SITE_PROGRAM_OTHER';
export const INIT_FORM = 'INIT_FORM';
export const SET_RECEIVING_ID = 'SET_RECEIVING_ID';
export const SET_GENERATING_ID = 'SET_GENERATING_ID';
export const SET_RECEIVING_SITE = 'SET_RECEIVING_SITE';
export const SET_AMOUNT_CY = 'SET_AMOUNT_CY';
export const SET_AMOUNT_TN = 'SET_AMOUNT_TN';
export const SET_STATUS = 'SET_STATUS';
export const SET_ANT_START = 'SET_ANT_START';
export const SET_ANT_END = 'SET_ANT_END';
export const SET_ACT_START = 'SET_ACT_START';
export const SET_ACT_END = 'SET_ACT_END';
export const SET_NOTES = 'SET_NOTES';
export const SET_EDITING = 'SET_EDITING';
export const SET_SUBMITTING = 'SET_SUBMITTING';
export const SET_ERROR = 'SET_ERROR';
export const SET_DELETING = 'SET_DELETING';
export const SET_MODAL = 'SET_MODAL';
export const SET_MATCH = 'SET_MATCH';
export const SET_ID = 'SET_ID';
export const SET_CSB_NUMBER = 'SET_CSB_NUMBER';
export const SET_UPLOADS = 'SET_UPLOADS';
export const SET_MODIFIED = 'SET_MODIFIED';
export const SET_PHOTO_VIDEO = 'SET_PHOTO_VIDEO';
export const SET_CAROUSEL_INDEX = 'SET_CAROUSEL_INDEX';
export const SET_DELETING_INDEX = 'SET_DELETING_INDEX';
export const SET_EXISTING_SITE = 'SET_EXISTING_SITE';
export const TOGGLE_GEOTECHNICAL_REQUIREMENTS =
    'TOGGLE_GEOTECHNICAL_REQUIREMENTS';
export const SET_GEOTECHNICAL_DETAILS = 'SET_GEOTECHNICAL_DETAILS';
export const SET_LOADS_PER_DAY = 'SET_LOADS_PER_DAY';
export const SET_PROPOSED_USAGE = 'SET_PROPOSED_USAGE';

export const SOIL_TYPES = {
    CLAY: 'clay',
    GRAVEL: 'gravel',
    COARSE_FINE_SAND: 'coarse and fine sand',
    COARSE_SAND: 'coarse sand',
    COARSE_SAND_GRAVEL: 'coarse sand and gravel',
    SILT: 'silt',
    SILT_CLAY: 'silt and clay',
    SILTY_SAND: 'silty sand',
    FINE_SAND: 'fine sand',
    TILL: 'till',
    NOT_SPECIFIED: 'not specified',
};

export const CHEM_CHARS = {
    GENERAL_FILL: 'residential soil/general fill',
    RESTRICTED_USE: 'restricted residential soil',
    UNRESTRICTED_USE: 'unrestricted use soil',
};
export const SET_SOURCE_ALT_ID = 'SET_SOURCE_ALT_ID';
export const SET_DESTINATION_ALT_ID = 'SET_DESTINATION_ALT_ID';