import React, {useState} from 'react';
import { Button,Container, Form } from 'react-bootstrap';
import { newUser } from '../../api/index';
import { Redirect } from 'react-router-dom';

export const CreateUser = ()=>{
    //state that save after created a new user, will lead to redirect to next page
    const [afterCreate, setAfterCreate] = useState(false);
    //state that save user input
    const [inputUser, setNewUser] = useState({
        "first_name":"",
        "last_name":"",
        "password":"",
        "email":"",
    });
    //handle user input data change
    const handleUserInput = (e)=>{
        const {name,value} = e.target;
        setNewUser((oldData)=>{
            return {
                ...oldData,
                [name]:value,
            };
        });
    };
    //handle create new user to the server
    const handleSubmit= async(e)=>{
        e.preventDefault();
        // debonce setting
        let btn = document.getElementById("subbtn")
        btn.disabled=true
        btn.innerText="Sending....."
        //get all user input
        //console.log(inputUser);
        //call the api->index.js-> newUser();
        const result = await newUser(inputUser);
        //console.log(result.success);
        if(result.success){
            alert("User create successfully!");
            setAfterCreate(true);
        }else{
            alert("User create failed!");
            setAfterCreate(false);
        }
    };
    
    return (
        <Container fluid='md'>
        {
            afterCreate?<Redirect forceRefresh={true} to="/usermanagement/" />:<></>
        }
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicFName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" placeholder="First Name" name="first_name" onChange={handleUserInput} required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicLName">
                    <Form.Label>last Name</Form.Label>
                    <Form.Control type="text" placeholder="Last Name" name="last_name" onChange={handleUserInput} required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" name="email" onChange={handleUserInput} required/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" name="password" onChange={handleUserInput} required/>
                </Form.Group>
                <Button variant="primary" type="submit" id='subbtn'>
                    Submit
                </Button>
                {" "}
                <Button variant="secondary" type="reset">
                    Reset
                </Button>
            </Form>
        </Container>    
    );
};
