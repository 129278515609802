import { GENERATING } from '../components/SmartMatching/helpers';

// Filtering Constants
export const AMOUNT_LTH = 'AMOUNT_LTH';
export const AMOUNT_HTL = 'AMOUNT_HTL';

export const DISTANCE_LTH = 'DISTANCE_LTH';
export const DISTANCE_HTL = 'DISTANCE_HTL';

export const CREATED_LTH = 'CREATED_LTH';
export const CREATED_HTL = 'CREATED_HTL';

export const UPDATED_LTH = 'UPDATED_LTH';
export const UPDATED_HTL = 'UPDATED_HTL';

export const EXPIRATION_LTH = 'EXPIRATION_LTH';
export const EXPIRATION_HTL = 'EXPIRATION_HTL';

// Default Center
export const DEFAULT_CENTER = [40.7128, -74.006];

// Filtering Function
export const filter_sites = (selected, sites, opts) => {
    // FIXME: This is Extremely InEfficient. Use HashMap for Sites
    if (!selected || !sites) return [];

    const { category, sort, distance, min, max } = opts;
    const amount = { min, max };
    console.log(amount);

    // FIXME: Change this to Be the Filter Out Based
    // on Capacities
    const soil_sum =
        category === GENERATING
            ? 'total_soil_requested'
            : 'total_soil_generated';
    const soil_moved =
        category === GENERATING
            ? 'total_soil_received'
            : 'total_soil_transferred';

    // let filtered_sites = sites.filter((s, { category }) => {
    //     return category !== selected.category;
    // });

    // Remove Selected Site and Others that Are Not of Opposite
    // Type (Receiving vs Generating)
    let filtered_sites = sites.filter((s) => s !== selected);

    if (!opts) return filtered_sites;
    console.log(`After Category:`, filtered_sites);

    // TODO: There Will Need to Be a Nested Switch Case
    switch (sort) {
        // Add Cases and Sort Functions Based on ENUM
        case AMOUNT_HTL:
            filtered_sites = filtered_sites.sort(
                (a, b) =>
                    b[soil_sum] - b[soil_moved] - (a[soil_sum] - a[soil_moved])
            );
            break;
        case AMOUNT_LTH:
            filtered_sites = filtered_sites.sort(
                (a, b) =>
                    a[soil_sum] - a[soil_moved] - (b[soil_sum] - b[soil_moved])
            );
            break;
        case DISTANCE_HTL:
            filtered_sites = filtered_sites.sort((a, b) => {
                let da = haversine(a.lat, a.lng, selected.lat, selected.lng);
                let db = haversine(b.lat, b.lng, selected.lat, selected.lng);
                return db - da;
            });
            break;
        case DISTANCE_LTH:
            filtered_sites = filtered_sites.sort((a, b) => {
                let da = haversine(a.lat, a.lng, selected.lat, selected.lng);
                let db = haversine(b.lat, b.lng, selected.lat, selected.lng);
                return da - db;
            });
            break;
        case CREATED_HTL:
            filtered_sites = filtered_sites.sort(
                (a, b) => new Date(a.created) - new Date(b.created)
            );
            break;
        case CREATED_LTH:
            filtered_sites = filtered_sites.sort(
                (a, b) => new Date(b.created) - new Date(a.created)
            );
            break;
        case UPDATED_HTL:
            filtered_sites = filtered_sites.sort(
                (a, b) => new Date(a.modified) - new Date(b.modified)
            );
            break;
        case UPDATED_LTH:
            filtered_sites = filtered_sites.sort(
                (a, b) => new Date(b.modified) - new Date(a.modified)
            );
            break;
        // case EXPIRATION_HTL:
        //     filtered_sites = filtered_sites.sort(
        //         (a, b) =>
        //             new Date(a.expiration_date) - new Date(b.expiration_date)
        //     );
        //     break;
        // case EXPIRATION_LTH:
        //     filtered_sites = filtered_sites.sort(
        //         (a, b) =>
        //             new Date(b.expiration_date) - new Date(a.expiration_date)
        //     );
        // break;
        default:
            // Do Nothing
            break;
    }

    console.log(`After Sort:`, filtered_sites);

    if (distance) {
        // Use Haversine to Get Distance
        filtered_sites = filtered_sites.filter(({ lat, lng }) => {
            /* if lat,lng and selected.lat,selected.lng are <= distance */
            const d = haversine(lat, lng, selected.lat, selected.lng);
            return d <= distance;
        });
    }

    console.log(`After Distance:`, filtered_sites);

    if (amount) {
        if (!amount.min) amount.min = 0;
        if (!amount.max) amount.max = Number.MAX_SAFE_INTEGER;
        // Get Where Amount is Within Amount
        filtered_sites = filtered_sites.filter(
            (s) =>
                // Checking tn and cy is redundant
                s[soil_sum] - s[soil_moved] >= amount.min &&
                s[soil_sum] - s[soil_moved] <= amount.max
        );
    }
    console.log(`After Amount:`, filtered_sites);

    return filtered_sites;
};

const deg2rad = (n) => (n * Math.PI) / 180;

export const haversine = (lat1, lng1, lat2, lng2) => {
    const earth_radius = 3961;

    const dLat = deg2rad(lat2 - lat1);
    const dLng = deg2rad(lng2 - lng1);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
            Math.cos(deg2rad(lat2)) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2);
    const c = 2 * Math.asin(Math.sqrt(a));
    const d = earth_radius * c;

    return d;
};
