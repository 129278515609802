import React, { useContext } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { haversine } from '../../utils/smartmatching';
import { SmartMatchingContext } from '../../context/SmartMatchingContext';
import { TransferFormContext } from '../Forms/Transfer/context/TransferFormContext';
import { GENERATING } from '../SmartMatching/helpers';

export const MatchCard = ({ site }) => {
    const MAP = useContext(SmartMatchingContext);
    //const transaction = useContext(TransferFormContext);
    if (!MAP.selected_site) return <></>;

    const amt =
        MAP.category === GENERATING
            ? site.total_soil_requested - site.total_soil_received
            : site.total_soil_generated - site.total_soil_transferred;
    return (
        <Card className='my-2' key={`secondary_site_selection-${site.id}`}>
            <Card.Body>
                <Row>
                    <Col>
                        <Card.Title>
                            <Link
                                to={`/sites/${site.meta.oer_proj_num}/`}
                                target='_blank'
                            >
                                {site.name}
                            </Link>
                        </Card.Title>
                    </Col>
                    <Col>
                        <Card.Text>
                            <span>
                                {`${haversine(
                                    MAP.selected_site.lat,
                                    MAP.selected_site.lng,
                                    site.lat,
                                    site.lng
                                ).toFixed(2)} mi.`}
                            </span>
                        </Card.Text>
                    </Col>
                </Row>
                <Card.Text>
                    {`Soil ${
                        MAP.category === GENERATING ? 'Capacity' : 'Available'
                    }: ${Number(amt).toLocaleString()} (CY)`}
                </Card.Text>
                <Card.Text>
                    {`Site Last Updated: ${new Date(
                        site.modified
                    ).toLocaleDateString()}`}
                </Card.Text>
            </Card.Body>
        </Card>
    );
};
