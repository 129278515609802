import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import NewSiteFormReducer from './NewSiteReducer';
import * as types from '../../Shared/constants';

export const initialState = {
    stockpile: false,
    pending: false,
    contact: {
        firstname: '',
        lastname: '',
        phone: '',
    },
    project_name: '',
    oer_proj: '',
    address: '',
    autofill: null,
    borough: '',
    coords: ['', ''],
    bbl: null,
    site_types: new Set(),
    program_types: new Set(),
    input_type: null,
    valid_input: false,
    notes: '',
};

export const NewSiteFormContext = createContext(initialState);

export const NewSiteFormProvider = ({ children }) => {
    const [state, dispatch] = useReducer(NewSiteFormReducer, initialState);

    const setStockpile = (payload) => {
        dispatch({ type: types.SET_STOCKPILE, payload });
    };

    const setPending = (payload) => {
        dispatch({ type: types.SET_PENDING, payload });
    };

    const setFirstName = (payload) => {
        dispatch({ type: types.SET_FIRST_NAME, payload });
    };

    const setLastName = (payload) => {
        dispatch({ type: types.SET_LAST_NAME, payload });
    };

    const setPhone = (payload) => {
        dispatch({ type: types.SET_PHONE, payload });
    };

    const setProjectName = (payload) => {
        dispatch({ type: types.SET_PROJECT_NAME, payload });
    };

    const setProject = (payload) => {
        dispatch({ type: types.SET_OER_PROJECT, payload });
    };

    const setAddress = (payload) => {
        dispatch({ type: types.SET_ADDRESS, payload });
    };

    const setBorough = (payload) => {
        dispatch({ type: types.SET_BOROUGH, payload });
    };

    const setAutoFill = (payload) => {
        dispatch({ type: types.SET_AUTOFILL, payload });
    };

    const setCoords = (payload) => {
        dispatch({ type: types.SET_COORDS, payload });
    };

    const setBBL = (payload) => {
        dispatch({ type: types.SET_BBL, payload });
    };

    const setSiteType = (payload) => {
        dispatch({ type: types.SET_SITE_TYPE, payload });
    };

    const setSiteTypeOther = (payload) => {
        dispatch({ type: types.SET_SITE_TYPE_OTHER, payload });
    };

    const setProgramType = (payload) => {
        dispatch({ type: types.SET_SITE_PROGRAM, payload });
    };

    const setProgramTypeOther = (payload) => {
        dispatch({ type: types.SET_SITE_PROGRAM_OTHER, payload });
    };

    const setInputType = (payload) => {
        dispatch({ type: types.SET_INPUT_TYPE, payload });
    };

    const setValid = (payload) => {
        dispatch({ type: types.SET_VALID, payload });
    };

    const setNotes = (payload) => {
        dispatch({ type: types.SET_ADDITIONAL_NOTES, payload });
    };

    return (
        <NewSiteFormContext.Provider
            value={{
                stockpile: state.stockpile,
                pending: state.pending,
                contact: state.contact,
                project_name: state.project_name,
                oer_proj: state.oer_proj,
                address: state.address,
                autofill: state.autofill,
                borough: state.borough,
                coords: state.coords,
                bbl: state.bbl,
                site_types: state.site_types,
                program_types: state.program_types,
                input_type: state.input_type,
                valid_input: state.valid_input,
                notes: state.notes,
                setStockpile,
                setPending,
                setFirstName,
                setLastName,
                setPhone,
                setProjectName,
                setAddress,
                setBorough,
                setBBL,
                setProject,
                setInputType,
                setAutoFill,
                setCoords,
                setValid,
                setSiteType,
                setSiteTypeOther,
                setProgramType,
                setProgramTypeOther,
                setNotes,
            }}
        >
            {children}
        </NewSiteFormContext.Provider>
    );
};

NewSiteFormProvider.propTypes = {
    children: PropTypes.node,
};
