import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Collapse, Button } from 'react-bootstrap';
import { RequestFormContext } from './context/RequestContext';
import { SoilInput } from '../Shared/Inputs/SoilInput';
import { getSoilInputs } from '../../SiteOverview/SiteAvailabilityRequest';
import { GlobalContext } from '../../../context/GlobalState';
import { runQuery } from '../../../api';
import { eligibilityToggleRenderer } from '.';
import { API_URL } from '../../../api/queries';

export const SoilRequestForm = ({ sid, afterSubmit }) => {
    const state = useContext(RequestFormContext);
    const { setQuery } = useContext(GlobalContext);
    return (
        <Form
            onSubmit={async (e) => {
                e.preventDefault();
                // debonce setting
                let btn = document.getElementById("subbtn")
                btn.disabled=true
                btn.innerText="Sending....."
                try {
                    const form = {
                        data: {
                            ...state,
                            site_id: sid,
                            geotechnical_requirement_details:
                                state.geotechnical_details,
                            other_details: state.notes,
                            soil_types: Array.from(
                                state.soil_types.entries()
                            ).map(([k, v]) => [k, Array.from(v)]),
                        },
                    };

                    let url = `${API_URL}/requests/`;

                    const res = await fetch(url, {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(form),
                    });

                    const json = await res.json();

                    const data = await runQuery();
                    await setQuery(data);

                    console.log(json);

                    if (afterSubmit) afterSubmit();
                } catch (err) {
                    console.log(err);
                }
            }}
        >
            <h1>Soil Request Form</h1>
            <Form.Group>
                <h3>Soil Type (CY)</h3>
                <div className='d-flex flex-row flex-wrap'>
                    <SoilInput onChange={(v) => state.setSoilTypes(v)} />
                </div>

                <Form.Switch
                    id='geotech-req'
                    onChange={() =>
                        state.toggleGeotechnicalRequirements(
                            !state.geotechnical_requirements
                        )
                    }
                    label='Specific Geotechnical Requirements'
                />
                <Collapse in={state.geotechnical_requirements}>
                    <Form.Group>
                        <Form.Label htmlFor='geotech-details'>
                            Provide Details
                        </Form.Label>
                        <Form.Control
                            id='geotech-details'
                            type='text'
                            onChange={(e) =>
                                state.setGeotechnicalDetails(e.target.value)
                            }
                        />
                    </Form.Group>
                </Collapse>
            </Form.Group>

            <Form.Group>
                <h3>Time Period and Logistics</h3>

                <Form.Label htmlFor='expiration-date'>
                    When is the Soil Needed By?
                </Form.Label>
                <Form.Control
                    id='expiration-date'
                    type='date'
                    value={state.expiration_date}
                    onChange={(e) => state.setExpirationDate(e.target.value)}
                    required
                />

                <Form.Label className='mt-2' htmlFor='loads-per-day'>
                    Loads Per Day (CY)
                </Form.Label>
                <Form.Control
                    id='loads-per-day'
                    type='number'
                    value={state.loads_per_day}
                    onChange={(e) => state.setLoadsPerDay(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group>
                <h3>Miscellaneous Information</h3>

                <Form.Label htmlFor='proposed-usage'>Proposed Usage</Form.Label>
                <Form.Control
                    id='proposed-usage'
                    as='textarea'
                    value={state.proposed_usage}
                    onChange={(e) => state.setProposedUsage(e.target.value)}
                    required
                />
                <Form.Label className='mt-2' htmlFor='additional-details'>
                    Other Details
                </Form.Label>
                <Form.Control
                    id='additional-details'
                    as='textarea'
                    value={state.notes}
                    onChange={(e) => state.setNotes(e.target.value)}
                />
            </Form.Group>
            <Button className='w-100' type='submit' id='subbtn'>
                Submit
            </Button>
        </Form>
    );
};

SoilRequestForm.propTypes = {
    sid: PropTypes.number.isRequired,
    afterSubmit: PropTypes.func,
};

export const SoilRequestEditForm = ({ request, afterSubmit }) => {
    const state = useContext(RequestFormContext);
    const { setQuery } = useContext(GlobalContext);
    const [showSoilAmounts, setShowSoilAmounts] = useState(false);
    const [showStockpileAmounts, setShowStockpileAmounts] = useState(false);
    useEffect(() => {
        state.setCsbEligible(request.csb_eligible);
        state.setStockpileEligible(request.stockpile_eligible);
        state.setExpirationDate(request.expiration_date);
        state.setProposedUsage(request.proposed_usage);
        state.toggleGeotechnicalRequirements(request.geotechnical_requirements);
        state.setGeotechnicalDetails(request.geotechnical_requirement_details);
        state.setLoadsPerDay(request.loads_per_day);
        state.setNotes(request.other_details);
    }, []);

    return (
        <Form
            onSubmit={async (e) => {
                e.preventDefault();
                // debonce setting
                let btn = document.getElementById("editsubbtn")
                btn.disabled=true
                btn.innerText="Sending....."
                try {
                    const form = {
                        data: {
                            csb_eligible: state.csb_eligible,
                            stockpile_eligible: state.stockpile_eligible,
                            expiration_date: state.expiration_date,
                            proposed_usage: state.proposed_usage,
                            geotechnical_requirements:
                                state.geotechnical_requirements,
                            geotechnical_requirement_details:
                                state.geotechnical_details,
                            loads_per_day: state.loads_per_day,
                            other_details: state.notes,
                            soil_types: Array.from(
                                state.soil_types.entries()
                            ).map(([k, v]) => [k, Array.from(v)]),
                        },
                    };

                    if (state.stockpile_eligible) {
                        form.data.stockpile_types = Array.from(
                            state.stockpile_types.entries()
                        ).map(([k, v]) => [k, Array.from(v)]);
                    }

                    let url = `${API_URL}/requests/${request.id}`;

                    const res = await fetch(url, {
                        method: 'PATCH',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(form),
                    });

                    const json = await res.json();

                    const data = await runQuery();
                    await setQuery(data);

                    if (afterSubmit) afterSubmit();

                    console.log(json);
                } catch (err) {
                    console.log(err);
                }
            }}
        >
            {eligibilityToggleRenderer(request, state)}
            <Form.Group>
                <div
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={() => setShowSoilAmounts((curr) => !curr)}
                >
                    <div
                        className={showSoilAmounts ? 'arrow down' : 'arrow'}
                    ></div>
                    <h3>Overall Soil Type (CY)</h3>
                </div>
                <div
                    id='soil-amount-collapse'
                    className={`d-flex flex-row flex-wrap ${
                        !showSoilAmounts ? 'hide' : ''
                    }`}
                >
                    <SoilInput
                        onChange={(v) => state.setSoilTypes(v)}
                        defaultValues={getSoilInputs(request, false)}
                    />
                </div>
                {request.stockpile_eligible ? (
                    <>
                        <div
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            onClick={() =>
                                setShowStockpileAmounts((curr) => !curr)
                            }
                        >
                            <div
                                className={
                                    showStockpileAmounts
                                        ? 'arrow down'
                                        : 'arrow'
                                }
                            ></div>
                            <h3>Stockpile Eligible Soil Type (CY)</h3>
                        </div>
                        <div
                            id='stockpile-amount-collapse'
                            className={`d-flex flex-row flex-wrap ${
                                !showStockpileAmounts ? 'hide' : ''
                            }`}
                        >
                            <SoilInput
                                key='stockpile-soil-amounts'
                                onChange={(v) => state.setStockpileTypes(v)}
                                defaultValues={getSoilInputs(
                                    request,
                                    false,
                                    true
                                )}
                            />
                        </div>
                    </>
                ) : (
                    []
                )}

                <Form.Switch
                    id='geotech-req'
                    onChange={() =>
                        state.toggleGeotechnicalRequirements(
                            !state.geotechnical_requirements
                        )
                    }
                    label='Specific Geotechnical Requirements'
                    defaultChecked={request.geotechnical_requirements}
                />
                <Collapse in={state.geotechnical_requirements}>
                    <Form.Group>
                        <Form.Label htmlFor='geotech-details'>
                            Provide Details
                        </Form.Label>
                        <Form.Control
                            id='geotech-details'
                            type='text'
                            onChange={(e) =>
                                state.setGeotechnicalDetails(e.target.value)
                            }
                            defaultValue={
                                request.geotechnical_requirement_details
                            }
                        />
                    </Form.Group>
                </Collapse>
            </Form.Group>

            <Form.Group>
                <h3>Time Period and Logistics</h3>

                <Form.Label htmlFor='expiration-date'>
                    When is the Soil Needed By?
                </Form.Label>
                <Form.Control
                    id='expiration-date'
                    type='date'
                    onChange={(e) => state.setExpirationDate(e.target.value)}
                    defaultValue={request.expiration_date}
                    required
                />

                <Form.Label className='mt-2' htmlFor='loads-per-day'>
                    Loads Per Day (CY)
                </Form.Label>
                <Form.Control
                    id='loads-per-day'
                    type='number'
                    onChange={(e) => state.setLoadsPerDay(e.target.value)}
                    defaultValue={request.loads_per_day}
                    required
                />
            </Form.Group>

            <Form.Group>
                <h3>Miscellaneous Information</h3>

                <Form.Label htmlFor='proposed-usage'>Proposed Usage</Form.Label>
                <Form.Control
                    id='proposed-usage'
                    as='textarea'
                    onChange={(e) => state.setProposedUsage(e.target.value)}
                    defaultValue={request.proposed_usage}
                    required
                />
                <Form.Label className='mt-2' htmlFor='additional-details'>
                    Other Details
                </Form.Label>
                <Form.Control
                    id='additional-details'
                    as='textarea'
                    onChange={(e) => state.setNotes(e.target.value)}
                    defaultValue={request.other_details}
                />
            </Form.Group>
            <Button className='w-100' type='submit' id='editsubbtn'>
                Submit
            </Button>
        </Form>
    );
};

SoilRequestEditForm.propTypes = {
    request: PropTypes.object,
    afterSubmit: PropTypes.func,
};
