import React from 'react';
import { PropTypes } from 'prop-types';
import { Container } from 'react-bootstrap';
import { TransferForm, TransferEditForm } from '.';
import { TransferFormProvider } from './context/TransferFormContext';

export const TransferFormContainer = ({
    afterSubmit,
    generating,
    receiving,
}) => {
    return (
        <TransferFormProvider>
            <Container>
                <TransferForm
                    afterSubmit={afterSubmit}
                    generating={generating}
                    receiving={receiving}
                />
            </Container>
        </TransferFormProvider>
    );
};

TransferFormContainer.propTypes = {
    afterSubmit: PropTypes.func,
};

export const TransferFormEditContainer = ({ transfer, afterSubmit }) => {
    return (
        <TransferFormProvider>
            <Container>
                <TransferEditForm
                    transfer={transfer}
                    afterSubmit={afterSubmit}
                />
            </Container>
        </TransferFormProvider>
    );
};

TransferFormEditContainer.propTypes = {
    transfer: PropTypes.object.isRequired,
    afterSubmit: PropTypes.func,
};
