import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export const NotFound = () => {
    const history = useHistory();
    console.log(history.location);
    if (history.location.pathname !== '/404') history.push('/404');

    return (
        <>
            <Link to='/'>
                <Button>Home</Button>
            </Link>
            404 Not Found
        </>
    );
};
