import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute/';
import { Footer } from './components/Footer/';
import { Login } from './components/Login/';
import { Landing } from './components/Landing/';
import { NotFound } from './components/NotFound/';
import * as serviceWorker from './serviceWorker';
import './custom.scss';
import { FilterTable } from './components/Tables/FilterTable';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { NewSiteForm } from './components/Forms/NewSite';
import { AvailabilityRequestForm } from './components/Forms/AvailabilityRequest';
import { SiteOverview } from './components/SiteOverview';
import { TransferFormContainer } from './components/Forms/Transfer/Container';
import { Alert } from 'react-bootstrap';
import { SmartMatchingContainer } from './components/SmartMatching';
import { UserManagement} from "./components/UserManagment/UserManagement";
import {AlternateLocations} from "./components/alternateLocation/alternateLoction"
import { CreateUser } from "./components/UserManagment/CreateUser.js";

ReactDOM.render(
    <Router>
        {process.env.NODE_ENV !== 'production' ?
        <Alert variant='danger' style={{ margin: '0', textAlign: 'center' }}>
            Warning: This Site is for
            <strong> Development and Testing Only</strong>! Any Data Entered on
            this Site Will Not Be Saved
        </Alert>
        : []}
        <Switch>
            <ProtectedRoute exact path='/' component={Landing} />
            <Route exact path='/login' component={Login} />
            <ProtectedRoute exact path='/create/site' component={NewSiteForm} />
            <ProtectedRoute
                path='/sites/:TMP_NUMBER?'
                component={SiteOverview}
            />
            <ProtectedRoute
                exact
                path={[
                    '/create/availability_request',
                    '/create/availability',
                    '/create/request',
                ]}
                component={AvailabilityRequestForm}
            />

            <ProtectedRoute
                exact
                path='/transfers/all/'
                component={FilterTable}
            />
            <ProtectedRoute
                exact
                path='/create/transfer/'
                component={TransferFormContainer}
            />
            <ProtectedRoute
                exact
                path='/matching/'
                component={SmartMatchingContainer}
            />
            <ProtectedRoute
                exact
                path='/usermanagement/'
                component={UserManagement}
            />
            <ProtectedRoute
                exact
                path='/usermanagement/createUser'
                component={CreateUser}
            />
            <ProtectedRoute
                exact
                path='/alternateLocations/'
                component={AlternateLocations}
            />
            <Route component={NotFound} />
        </Switch>
        <Footer />
    </Router>,
    document.getElementById('root')
);

serviceWorker.unregister();
