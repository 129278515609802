import React from 'react';
import { Container, Popover, Row, Col, OverlayTrigger } from 'react-bootstrap';
import { CY_TO_TN_FACTOR } from '../../utils/';
import { GrCircleInformation } from 'react-icons/gr';

export const castToFormattedNumber = (n, tt) =>
    tt
        ? Number(Number(n * CY_TO_TN_FACTOR).toFixed(2)).toLocaleString()
        : Number(Number(n).toFixed(2)).toLocaleString();

export const metaFieldCalc = (site, stockpile) => {
    const fields = {
        current_soil_available:
            site.total_soil_generated - site.total_soil_transferred,
        current_track_one_soil_available:
            site.total_track_one_soil_generated -
            site.total_track_one_soil_transferred,
        current_track_two_soil_available:
            site.total_track_two_soil_generated -
            site.total_track_two_soil_transferred,
        current_track_three_soil_available:
            site.total_track_three_soil_generated -
            site.total_track_three_soil_transferred,
        current_soil_capacity:
            site.total_soil_requested - site.total_soil_received,
        current_track_one_capacity:
            site.total_track_one_soil_requested -
            site.total_track_one_soil_received,
        current_track_two_capacity:
            site.total_track_two_soil_requested -
            site.total_track_two_soil_received,
        current_track_three_capacity:
            site.total_track_three_soil_requested -
            site.total_track_three_soil_received,
    };

    /* NOTE: When the site is a stockpile, we need to start with 
       the total capacity of the stockpile (the sum of approved 
       requests) and subtract the difference in the amount of soil
       we have received (including pending) and the amount 
       of soil we have transferred (ignoring pending).

       This allows us to ensure that we would be able to handle all
       soil we commit to receiving even if the incomplete
       Incoming and Outgoing transfers do not end up completing.

       Example:
        Site A is a Stockpile and Has:
        Request A, 15000 CY

        Transfer A, Incoming, Complete, 4000 CY
        Transfer B, Outgoing, Complete, 2000 CY
        Transfer C, Incoming, Active, 500 CY
        Transfer D, Outgoing, Active, 500 CY
        Transfer E, Incoming, Cancelled, 2000 CY

        Capacity = 15000 - (4000 + 500 - 2000) = 12000 CY

        Regular Site
        ------------
        Current Soil Available: The sum of all approved availabilities - The sum of all outgoing transfers (that aren't cancelled)
        Current Soil Capacity: The sum of all approved requests - The sum of all incoming transfers (that aren't cancelled)

        Current Soil Available: total_soil_generated - total_potential_soil_transferred
        Current Soil Capacity: total_soil_requested- total_potential_soil_received

        Stockpile
        ---------
        Current Soil Available: The sum of all incoming transfers (that are completed) - The sum of all outgoing transfers (that aren't cancelled)
        Current Soil Capacity: The sum of all approved requests - The difference in the sum of incoming transfers (that aren't cancelled) and the sum of outgoing transfers (that have been completed)

        Current Soil Available: total_completed_soil_received - total_potential_soil_transferred
        Current Soil Capacity: total_soil_requested - [ total_potential_soil_received - total_completed_soil_transferred ]
    */
    if (stockpile) {
        return {
            ...fields,
            current_soil_capacity:
                site.total_soil_requested -
                site.total_soil_received +
                site.total_soil_transferred_complete,
        };
    }
    return fields;
};

export const trackBreakdownPopOver = (t1, t2, t3) => (
    <Popover id='popover-basic'>
        <Popover.Title as='h3'>Soil Breakdown</Popover.Title>
        <Popover.Content>
            <Container fluid>
                <Row>
                    <Col>
                        <p>
                            Unrestricted Use Soil (CY):{' '}
                            {castToFormattedNumber(t1)}
                        </p>
                    </Col>
                    <Col>
                        <p>
                            Unrestricted Use Soil (TN):{' '}
                            {castToFormattedNumber(t1, true)}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            Residential Soil/General Fill Soil (CY):{' '}
                            {castToFormattedNumber(t2)}
                        </p>
                    </Col>
                    <Col>
                        <p>
                            Residential Soil/General Fill Soil (TN):{' '}
                            {castToFormattedNumber(t2, true)}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            Restricted Residential Soil (CY):{' '}
                            {castToFormattedNumber(t3)}
                        </p>
                    </Col>
                    <Col>
                        <p>
                            Restricted Residential Soil (TN):{' '}
                            {castToFormattedNumber(t3, true)}
                        </p>
                    </Col>
                </Row>
            </Container>
        </Popover.Content>
    </Popover>
);

export const numberCellFormatter = (cell, row) => castToFormattedNumber(cell);

export const availabilityAmountFormatter = (cell, row) => (
    <OverlayTrigger
        trigger='click'
        placement='bottom'
        overlay={trackBreakdownPopOver(
            row.track_one_soil_available,
            row.track_two_soil_available,
            row.track_three_soil_available
        )}
    >
        <p>
            {`${numberCellFormatter(cell, row)} `}
            <GrCircleInformation />
        </p>
    </OverlayTrigger>
);

export const requestAmountFormatter = (cell, row) => (
    <OverlayTrigger
        trigger='click'
        placement='bottom'
        overlay={trackBreakdownPopOver(
            row.track_one_soil_requested,
            row.track_two_soil_requested,
            row.track_three_soil_requested
        )}
    >
        <p>
            {`${numberCellFormatter(cell, row)} `}
            <GrCircleInformation />
        </p>
    </OverlayTrigger>
);

export const transferAmountFormatter = (cell, row) => (
    <OverlayTrigger
        trigger='click'
        placement='bottom'
        overlay={trackBreakdownPopOver(
            row.track_one_soil_transferred,
            row.track_two_soil_transferred,
            row.track_three_soil_transferred
        )}
    >
        <p>
            {`${numberCellFormatter(cell, row)} `}
            <GrCircleInformation />
        </p>
    </OverlayTrigger>
);
