import React, { useContext, useState, useRef, useEffect } from 'react';
import { SmartMatchingContext } from '../../../context/SmartMatchingContext';
import { Button, Form, Modal } from 'react-bootstrap';
import { SiteSelector } from '../../Forms/Shared/Inputs/SiteSelector';
import { Filters } from './Filters';
import { SmartMatchingPaginator } from '../Pagination';
// import { GlobalContext } from '../../../context/GlobalState';
import { MatchCard } from '../../Cards/MatchCard';
import { GENERATING, RECEIVING } from '../helpers';
import { TransferFormContainer } from '../../Forms/Transfer/Container';

export const SelectionPane = () => {
    const MAP = useContext(SmartMatchingContext);
    const selection_ref = useRef(null);
    const [items, setItems] = useState([]);
    const [modal, setModal] = useState(false);

    const sites = MAP.filtered_sites || [];

    useEffect(() => {
        setItems(
            sites.map((site, it) => {
                return (
                    <Form.Check
                        key={site.id}
                        type='radio'
                        name='secondary-site'
                        value={site.id}
                        id={site.id}
                        checked={MAP.secondary_site == site}
                        onChange={(e) => {
                            // FIXME: Make this O(1) with a Map/Set
                            const s = sites.filter(
                                ({ id }) => id === parseInt(e.target.value)
                            )[0];

                            MAP.setSecondarySite(s);
                        }}
                        label={<MatchCard site={site} />}
                    />
                );
            })
        );
    }, [sites, MAP.secondary_site]);

    if (!sites) return null;
    return (
        <div
            ref={selection_ref}
            className='h-50 h-md-100 p-0 selection-pane mx-md-2'
            style={{ overflow: 'hidden' }}
        >
            <div
                style={{
                    height: 'calc(100% - 50px)',
                    overflowY: 'auto',
                }}
            >
                <h4>Site Selector</h4>

                <SiteSelector
                    id='generating-match'
                    placeholder='Generating Site'
                    mode='generating'
                    onChange={(site_id) => {
                        MAP.setSelectedSite(
                            sites.filter(({ id }) => id === site_id)[0]
                        );
                        MAP.setCategory(GENERATING);
                    }}
                    disabled={MAP.selected_site && MAP.category === RECEIVING}
                />

                <SiteSelector
                    id='receiving-match'
                    placeholder='Receiving Site'
                    mode='receiving'
                    onChange={(site_id) => {
                        MAP.setSelectedSite(
                            sites.filter(({ id }) => id === site_id)[0]
                        );
                        MAP.setCategory(RECEIVING);
                    }}
                    disabled={MAP.selected_site && MAP.category === GENERATING}
                />

                <Filters />

                {MAP.selected_site ? (
                    <>
                        <h3>Potential Matches ({MAP.filtered_sites.length})</h3>
                        <SmartMatchingPaginator
                            items={items}
                            width={selection_ref.current.clientWidth}
                            sel_ref={selection_ref}
                        />
                    </>
                ) : (
                    []
                )}
            </div>
            <hr className='m-0' />

            <div
                className='d-flex justify-content-between'
                style={{
                    height: '45px',
                }}
            >
                {/* <SmartMatchingModal
                show={transaction.modal && transaction.match}
            /> */}
                <Modal
                    show={modal}
                    onHide={() => {
                        setModal(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Create New Transfer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TransferFormContainer
                            afterSubmit={() => setModal(false)}
                            generating={
                                MAP.category === GENERATING
                                    ? MAP.selected_site
                                    : MAP.secondary_site
                            }
                            receiving={
                                MAP.category === GENERATING
                                    ? MAP.secondary_site
                                    : MAP.selected_site
                            }
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='danger'
                            onClick={() => {
                                setModal(false);
                                // setSelected(null);
                            }}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Button
                    onClick={() => {
                        MAP.setSecondarySite(null);
                    }}
                    variant='danger'
                    disabled={!MAP.secondary_site}
                >
                    Clear Selection
                </Button>
                <Button
                    onClick={() => {
                        setModal(true);
                    }}
                    disabled={!MAP.selected_site || !MAP.secondary_site}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};
