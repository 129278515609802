import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { attemptLogin } from '../../api/';
import { Container, Alert, Fade } from 'react-bootstrap';
import { userAuthenticated } from '../../api/auth';
import { GlobalContext } from '../../context/GlobalState';
import { Form, Button, Image } from 'react-bootstrap';

export const Login = () => {
    // const { loading } = useContext(GlobalContext);
    const history = useHistory();
    const [userName, setUserName] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        const auth = async () => {
            if ((await userAuthenticated()) === true) {
                history.push('/');
            }
        };
        auth();
    }, []);

    // if (loading) return <></>;

    return (
        <Container
            fluid
            className='h-100 d-flex flex-column justify-content-center'
            style={{ minHeight: 'calc(100vh - 56px)' }}
        >
            <Form
                className='w-100 m-auto text-center'
                style={{
                    maxWidth: '330px',
                    padding: '15px',
                }}
                onSubmit={async (e) => {
                    e.preventDefault();
                    const { success } = await attemptLogin({
                        userName,
                        password,
                    });
                    if (success === true) {
                        history.push('/');
                    } else {
                        setError(true);
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                    }
                }}
            >
                <Image
                    className='mb-3'
                    src='https://picsum.photos/150'
                    rounded
                />
                <h3 className='text-center mb-3 font-weight-normal'>
                    Please Sign In
                </h3>
                <Fade in={error} timeout={200}>
                    <Alert show={error} key='login-error' variant='danger'>
                        Invalid Username or Password
                    </Alert>
                </Fade>
                <Form.Group>
                    <Form.Label htmlFor='username' className='sr-only'>
                        Username
                    </Form.Label>
                    <Form.Control
                        id='username'
                        type='text'
                        placeholder='Username'
                        onChange={(e) => setUserName(e.target.value)}
                        style={{
                            marginBottom: '-1px',
                            borderBottomRightRadius: '0px',
                            borderBottomLeftRadius: '0px',
                        }}
                        required
                    />
                    <Form.Label htmlFor='password' className='sr-only'>
                        Password
                    </Form.Label>
                    <Form.Control
                        id='password'
                        type='password'
                        placeholder='Password'
                        onChange={(e) => setPassword(e.target.value)}
                        style={{
                            marginBottom: '-1px',
                            borderTopRightRadius: '0px',
                            borderTopLeftRadius: '0px',
                        }}
                        required
                    />
                    <Button size='lg' className='btn-block mt-3' type='submit'>
                        Login
                    </Button>
                </Form.Group>
            </Form>
        </Container>
    );
};
