import { API_URL } from '../queries';

export const userAuthenticated = async (controller) => {
    try {
        const url = `${API_URL}/token`;
        const res = await fetch(url, {
            method: 'POST',
            signal: controller,
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        const { success } = await res.json();
        return success;
    } catch (err) {
        console.log(err);
        return false;
    }
};
//call backend & return check user_type
export const isUserAdmin = async (controller)=>{
    try{
        
        const url = `${API_URL}/userManagement/`;
        const res = await fetch(url,{
            method:'GET',
            signal:controller,
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        const {result} = await res.json();
        //console.log("is UserAdmin result: "+result);
        return result;
    }catch(err){
        console.log(err);
        return false;
    }
};
