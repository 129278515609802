import React, { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import { GlobalContext } from '../../../../context/GlobalState';

/**
 * This Component is a TypeAhead for Selecting a Receiving Site.
 * When in a Filtered Mode (Generating or Receiving),
 * Sites Are Identified Using Their ID Versus TMP Number While Unfiltered.
 *
 * @param  omit   We Expect a Site ID When There is a Form or Other Input
 * that Should Not Accept Two of the Same Sites.
 * i.e. A Transfer Form Cannot Have the Same Generating and Receiving Site
 * @param   mode  We Accept a String ('generating' | 'receiving' | 'all') for the Mode of the Selector
 * @param  onChange    We Require an onChange Event Listener to Pass Information About
 * a Selected Element to Other Components.
 */
export const SiteSelector = ({
    id,
    placeholder,
    omit,
    mode,
    onChange,
    defaultSelected,
    ...rest
}) => {
    const MODES = {
        generating: 'generating',
        receiving: 'receiving',
        all: 'all',
    };
    const {
        query: { sites },
    } = useContext(GlobalContext);

    const typeahead = useRef(null);
    const [selected, setSelected] = useState(null);
    let opts = [];

    // Return the ID and TMP Number of a Selected Site
    const globalOnChange = (selected) => {
        if (selected.length <= 0) {
            setSelected(null);
            return;
        }

        let el = opts.filter(
            ({ meta: { oer_proj_num } }) => oer_proj_num === selected[0].id
        );
        if (selected[0].id !== omit && el.length > 0) {
            setSelected({ tmp: selected[0].id, id: el[0].id });
            typeahead.current.blur();
        }
    };

    // Returns the ID of a Selected Site
    const filteredOnChange = (selected) => {
        if (selected.length <= 0) {
            setSelected(null);
            return;
        }

        if (
            selected[0].id !== omit &&
            opts.filter(({ id }) => id === selected[0].id).length > 0
        ) {
            setSelected(selected[0].id);
            typeahead.current.blur();
        }
    };

    const filteredOptionsHandler = () =>
        opts
            .filter(({ id }) => id !== omit)
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(({ id, name }) => ({
                id: id,
                label: name,
            }));

    const globalOptionsHandler = () =>
        opts
            .filter(({ meta: { oer_proj_num } }) => oer_proj_num !== omit)
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(({ meta: { oer_proj_num }, name }) => ({
                id: oer_proj_num,
                label: name,
            }));

    const globalDefaultHandler = () =>
        opts
            .filter(({ meta: { oer_proj_num } }) => oer_proj_num === selected)
            .map(({ meta: { oer_proj_num }, name }) => ({
                id: oer_proj_num,
                label: name,
            }));
    const filteredDefaultHandler = () =>
        opts
            .filter(({ id }) => id === selected)
            .map(({ id, name }) => ({
                id: id,
                label: name,
            }));

    let changeHandler, optionsHandler, defaultSelectedHandler;

    switch (mode) {
        case MODES.generating:
            changeHandler = filteredOnChange;
            optionsHandler = filteredOptionsHandler;
            defaultSelectedHandler = filteredDefaultHandler;
            // Avoid Ternary In Case of Future Iterations Adding More Logic
            if (sites) {
                opts = sites.filter(
                    ({ total_soil_generated, total_soil_transferred }) =>
                        total_soil_generated - total_soil_transferred > 0
                );
            } else {
                opts = [];
            }
            break;
        case MODES.receiving:
            changeHandler = filteredOnChange;
            optionsHandler = filteredOptionsHandler;
            defaultSelectedHandler = filteredDefaultHandler;
            if (sites) {
                opts = sites.filter(
                    ({
                        stockpile,
                        total_soil_requested,
                        total_soil_received,
                        total_soil_transferred_complete,
                    }) => {
                        if (stockpile) {
                            return (
                                total_soil_requested -
                                    total_soil_received +
                                    total_soil_transferred_complete >
                                0
                            );
                        }
                        return total_soil_requested - total_soil_received > 0;
                    }
                );
            } else {
                opts = [];
            }
            break;
        case MODES.all:
            changeHandler = globalOnChange;
            optionsHandler = globalOptionsHandler;
            defaultSelectedHandler = globalDefaultHandler;
            opts = sites || [];
            break;
        default:
            throw new Error('Mode Not Recognized. Please Check Your Props');
    }

    useEffect(() => {
        onChange(selected);
    }, [selected]);

    return (
        <Typeahead
            id={id}
            ref={typeahead}
            onFocus={(e) => {
                e.target.select();
            }}
            onChange={(selected) => changeHandler(selected)}
            options={optionsHandler()}
            defaultSelected={defaultSelected || defaultSelectedHandler()}
            placeholder={placeholder}
            {...rest}
        />
    );
};

SiteSelector.propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    omit: PropTypes.number,
    mode: PropTypes.oneOf(['generating', 'receiving', 'all']).isRequired,
    onChange: PropTypes.func.isRequired,
};
