import React, { useEffect } from 'react';
import L from 'leaflet';
import { useLeaflet } from 'react-leaflet';

export const Legend = () => {
    const BLUE = '#0B93E2';
    const GREEN = '#0AFF16';
    const ORANGE = '#FF9D0A';
    const PURPLE = '#FF0ACA';

    const { map } = useLeaflet();
    useEffect(() => {
        const legend = L.control({ position: 'bottomright' });

        legend.onAdd = () => {
            const div = L.DomUtil.create('div', 'legend');
            div.innerHTML = `
                        <ul class="pt-3 pb-2 px-4" style="list-style-type: none; background: white;">
                            <li style="display: flex; align-items: center;">
                                <div style="width: 3px; height: 3px; padding: 2px; line-height: 10px; margin-right: 5px; border: 1px solid rgba(0,0,0,.2); border-radius: 50%; background: ${ORANGE};"></div>
                                <span>Generating Site</span>
                            </li>
                            <li style="display: flex; align-items: center;">
                                <div style="width: 3px; height: 3px; padding: 2px; line-height: 10px; margin-right: 5px; border: 1px solid rgba(0,0,0,.2); border-radius: 50%; background: ${GREEN};"></div>
                                <span>Receiving Site</span>
                            </li>
                            <li style="display: flex; align-items: center;">
                                <div style="width: 3px; height: 3px; padding: 2px; line-height: 10px; margin-right: 5px; border: 1px solid rgba(0,0,0,.2); border-radius: 50%; background: ${PURPLE};"></div>
                                <span>Generating & Receiving Site</span>
                            </li>
                        </ul>`;
            return div;
        };

        legend.addTo(map);

        return () => legend.remove();
    }, [map]);
    return null;
};
