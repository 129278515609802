import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { SoilInput } from '../Shared/Inputs/SoilInput';
import { SiteSelector } from '../Shared/Inputs/SiteSelector';
import { TransferFormContext } from './context/TransferFormContext';
import { GlobalContext } from '../../../context/GlobalState';
import { runQuery } from '../../../api';
import { nullDate } from '../../../utils/';
import { API_URL } from '../../../api/queries';
import {AltLocationSelector} from '../../alternateLocation/altLocationSelector';

export const TransferForm = ({ afterSubmit, generating, receiving }) => {
    const state = useContext(TransferFormContext);
    const { setQuery } = useContext(GlobalContext);

    useEffect(() => {
        if (generating) state.setGeneratingId(generating.id);
        if (receiving) state.setReceivingId(receiving.id);
    }, []);

    return (
        <Form
            onSubmit={async (e) => {
                e.preventDefault();
                //console.log(state)
                // debonce setting
                let btn = document.getElementById("subbtn")
                btn.disabled=true
                btn.innerText="Sending....."
                try {
                    const form = {
                        data: {
                            ...state,
                            ant_start: nullDate(state.ant_start)
                                ? null
                                : state.ant_start,
                            ant_end: nullDate(state.ant_end)
                                ? null
                                : state.ant_end,
                            act_start: nullDate(state.act_start)
                                ? null
                                : state.act_start,
                            act_end: nullDate(state.act_end)
                                ? null
                                : state.act_end,
                            soil_types: Array.from(
                                state.soil_types.entries()
                            ).map(([k, v]) => [k, Array.from(v)]),
                        },
                    };
                    let url = `${API_URL}/transfers/`;

                    const res = await fetch(url, {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(form),
                    });

                    const json = await res.json();

                    const data = await runQuery();//no need to refresh the page!, just call runQUery to update the data
                    await setQuery(data);

                    if (afterSubmit) {
                        afterSubmit();
                    }
                    if (json.success === true) {
                        alert('Transfer Successfully Created');
                        // TODO: Eventually Create a State Flush Method
                        // to reset the state.
                        // Pre-Requisites:
                        //     - Allow Site Selector to Take a Value Prop
                        //     - Allow Soil Input to Take a Value Prop
                        window.location.reload();
                    }
                } catch (err) {
                    alert('Error Creating Transfer');
                    console.log(err);
                }
            }}
        >
            <h1>Soil Transfer Form</h1>
            <Form.Group>
                <h3>Generating Site</h3>
                <SiteSelector
                    key='generating-selector'
                    id='generating-selector'
                    placeholder='Generating Site'
                    mode='generating'
                    omit={state.receiving_id}
                    defaultSelected={
                        generating
                            ? [
                                  {
                                      id: generating.id,
                                      label: generating.name,
                                  },
                              ]
                            : undefined
                    }
                    // This Gives the ID of the Source that is Associated with the Site Selected
                    onChange={(v) => state.setGeneratingId(v)}
                />
                <h3>Recieving Site</h3>
                <SiteSelector
                    key='receiving-selector'
                    id='receiving-selector'
                    placeholder='Receiving Site'
                    mode='receiving'
                    omit={state.generating_id}
                    defaultSelected={
                        receiving
                            ? [
                                  {
                                      id: receiving.id,
                                      label: receiving.name,
                                  },
                              ]
                            : undefined
                    }
                    // This Gives the ID of the Destination that is Associated with the Site Selected
                    onChange={(v) => state.setReceivingId(v)}
                />
            </Form.Group>

            <Form.Group>
                <h3>Soil Information (CY)</h3>
                <div className='d-flex flex-row flex-wrap'>
                    <SoilInput onChange={(v) => state.setSoilTypes(v)} />
                </div>
            </Form.Group>

            <h3>Transfer Metadata</h3>
            <Form.Group>
                {/* Status */}
                <Form.Label htmlFor='status'>Status</Form.Label>
                <Form.Control
                    id='status'
                    as='select'
                    value={state.status}
                    onChange={(e) => state.setStatus(e.target.value)}
                    required
                >
                    <option value=''>Status</option>
                    <option value='A'>Active</option>
                    <option value='H'>On Hold</option>
                    <option value='X'>Cancelled</option>
                    <option value='C'>Complete</option>
                </Form.Control>
            </Form.Group>

            <Form.Group>
                {/* Ant Start */}
                <Form.Label htmlFor='ant_start'>Anticipated Start</Form.Label>
                <Form.Control
                    id='ant_start'
                    type='date'
                    value={state.ant_start}
                    onChange={(e) => state.setAntStart(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group>
                {/* Ant End */}
                <Form.Label htmlFor='ant_end'>Anticipated End</Form.Label>
                <Form.Control
                    id='ant_end'
                    type='date'
                    value={state.ant_end}
                    onChange={(e) => state.setAntEnd(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group>
                {/* Act Start */}
                <Form.Label htmlFor='act_start'>Actual Start</Form.Label>
                <Form.Control
                    id='act_start'
                    type='date'
                    value={state.act_start}
                    onChange={(e) => state.setActStart(e.target.value)}
                />
            </Form.Group>

            <Form.Group>
                {/* Act End */}
                <Form.Label htmlFor='act_end'>Actual End</Form.Label>
                <Form.Control
                    id='act_end'
                    type='date'
                    value={state.act_end}
                    onChange={(e) => state.setActEnd(e.target.value)}
                />
            </Form.Group>

            <Form.Group>
                {/* Act End */}
                <Form.Label htmlFor='notes'>Notes</Form.Label>
                <Form.Control
                    id='notes'
                    as='textarea'
                    value={state.notes}
                    onChange={(e) => state.setNotes(e.target.value)}
                />
            </Form.Group>

            <Form.Group>
                <Button className='w-100' type='submit' id='subbtn'>
                    Submit
                </Button>
            </Form.Group>
        </Form>
    );
};

TransferForm.propTypes = {
    afterSubmit: PropTypes.func,
};

const getSoilInputs = (s) => {
    const v = new Map();

    s.transfer_soil_type_relations.map(
        ({ chem_char, soil_type, soil_amount }) => {
            const n = new Map(v.get(soil_type.name));
            n.has(chem_char.name) && !soil_amount
                ? n.delete(chem_char.name)
                : n.set(
                      chem_char.name,
                      (n.get(chem_char.name) || 0) + soil_amount
                  );

            v.set(soil_type.name, n);
        }
    );

    return v;
};

export const TransferEditForm = ({ transfer, afterSubmit }) => {
    const [disableAlt,setDisableAlt] = useState({
        source_alt_disable:true,
        destination_alt_disable:true
    })
    const state = useContext(TransferFormContext);
    const { setQuery } = useContext(GlobalContext);
    // console.log(transfer)
    useEffect(() => {
        state.setGeneratingId(transfer.source_id);
        state.setReceivingId(transfer.destination_id);
        state.setAntStart(transfer.ant_start);
        state.setAntEnd(transfer.ant_end);
        state.setActStart(transfer.act_start);
        state.setActEnd(transfer.act_end);
        state.setSoilTypes(getSoilInputs(transfer));
        state.setStatus(transfer.status);
        state.setNotes(transfer.notes);
        state.setSource_Alt_Id(transfer.source_alt_id);
        state.setDestination_Alt_Id(transfer.destination_alt_id);
        setDisableAlt({
            source_alt_disable:transfer.source_alt_id?false:true,
            destination_alt_disable:transfer.destination_alt_id?false:true,
        })
    }, []);

    const handleAltDisable = (e)=>{
        //console.log(e.target.checked)
        const {id,checked} = e.target;
        setDisableAlt((oldValue)=>{
            return {
                ...oldValue,
                [id]:!checked
            }
        })
    }
    return (
        <Form
            onSubmit={async (e) => {
                e.preventDefault();
                // debonce setting
                let btn = document.getElementById("editsubbtn")
                btn.disabled=true
                btn.innerText="Sending....."
                try {
                    // check if any address changed, if so need to reset the shortest distance.
                    let needResetValue = () =>{
                        if(transfer.source_id !== state.generating_id){
                             return true
                        }
                        if(transfer.destination_id !== state.receiving_id){return true}
                        if(disableAlt.source_alt_disable || (transfer.source_alt_id !== state.source_alt_id)){return true}
                        if(disableAlt.destination_alt_disable || (transfer.destination_alt_id !== state.destination_alt_id)){
                            return true
                        }
                        return false;
                    }
                    const form = {
                        data: {
                            source_id: state.generating_id,
                            destination_id: state.receiving_id,
                            status: state.status,
                            ant_start: nullDate(state.ant_start)
                                ? null
                                : state.ant_start,
                            ant_end: nullDate(state.ant_end)
                                ? null
                                : state.ant_end,
                            act_start: nullDate(state.act_start)
                                ? null
                                : state.act_start,
                            act_end: nullDate(state.act_end)
                                ? null
                                : state.act_end,
                            notes: state.notes,
                            source_alt_id : disableAlt.source_alt_disable? null:state.source_alt_id,
                            destination_alt_id: disableAlt.destination_alt_disable? null:state.destination_alt_id,
                            needReset:needResetValue(),
                            soil_types: Array.from(
                                state.soil_types.entries()
                            ).map(([k, v]) => [k, Array.from(v)]),
                        },
                    };

                    let url = `${API_URL}/transfers/${transfer.id}`;

                    const res = await fetch(url, {
                        method: 'PATCH',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(form),
                    });

                    const json = await res.json();

                    const data = await runQuery();
                    await setQuery(data);

                    if (afterSubmit) {
                        afterSubmit();
                    }

                    if (json.success === true) {
                        alert('Transfer Successfully Created');
                        //window.location.reload();
                    }

                    console.log(json);
                } catch (err) {
                    alert('Error Creating Transfer');
                    console.log(err);
                }
            }}
        >
            <h1>Soil Transfer Form</h1>
            <Form.Group>
                <h3>Generating Site</h3>
                <SiteSelector
                    key='generating-selector'
                    id='generating-selector'
                    placeholder='Generating Site'
                    mode='generating'
                    omit={state.receiving_id}
                    // This Gives the ID of the Source that is Associated with the Site Selected
                    onChange={(v) => state.setGeneratingId(v)}
                    defaultSelected={[
                        {
                            id: transfer.source.id,
                            label: transfer.source.name,
                        },
                    ]}
                />
                
                <h5 style={{display:"inline"}}>Generating Alternate Location</h5>
                <Form.Check
                    style={{display:"inline", marginLeft:"10px"}}
                    type="switch"
                    id="source_alt_disable"
                    onChange={(e)=>handleAltDisable(e)} //useState with T/F to control input fields
                    defaultChecked={transfer.source_alt_id?true:false}
                />
                <AltLocationSelector
                    id="generating-alt-selector"
                    key='generating-alt-selector'
                    placeholder={transfer.source_alt?transfer.source_alt.alt_address:"Pick an Alternate Location"}
                    onChange={(v) => state.setSource_Alt_Id(v)}
                    disabled={disableAlt.source_alt_disable}
                />
            
                <h3>Recieving Site</h3>
                <SiteSelector
                    key='receiving-selector'
                    id='receiving-selector'
                    placeholder='Receiving Site'
                    mode='receiving'
                    omit={state.generating_id}
                    // This Gives the ID of the Destination that is Associated with the Site Selected
                    onChange={(v) => state.setReceivingId(v)}
                    defaultSelected={[
                        {
                            id: transfer.destination.id,
                            label: transfer.destination.name,
                        },
                    ]}
                />
                
                <h5 style={{display:"inline"}}>Recieving Alternate Location</h5>
                <Form.Check
                    style={{display:"inline", marginLeft:"10px"}}
                    type="switch"
                    id="destination_alt_disable"
                    onChange={(e)=>handleAltDisable(e)} //useState with T/F to control input fields
                    defaultChecked={transfer.destination_alt_id?true:false}
                />
                <AltLocationSelector
                    id="receiving-alt-selector"
                    key='receiving-alt-selector'
                    placeholder={transfer.destination_alt_id?transfer.destination_alt.alt_address:"Pick an Alternate Location"}
                    onChange={(v) => state.setDestination_Alt_Id(v)}
                    disabled={disableAlt.destination_alt_disable}
                />
            </Form.Group>

            <Form.Group>
                <h3>Soil Information (CY)</h3>
                <div className='d-flex flex-row flex-wrap'>
                    <SoilInput
                        onChange={(v) => state.setSoilTypes(v)}
                        defaultValues={getSoilInputs(transfer)}
                    />
                </div>
            </Form.Group>

            <h3>Transfer Metadata</h3>
            <Form.Group>
                {/* Status */}
                <Form.Label htmlFor='status'>Status</Form.Label>
                <Form.Control
                    id='status'
                    as='select'
                    value={state.status}
                    onChange={(e) => state.setStatus(e.target.value)}
                    required
                >
                    <option value=''>Status</option>
                    <option value='A'>Active</option>
                    <option value='H'>On Hold</option>
                    <option value='X'>Cancelled</option>
                    <option value='C'>Complete</option>
                </Form.Control>
            </Form.Group>

            <Form.Group>
                {/* Ant Start */}
                <Form.Label htmlFor='ant_start'>Anticipated Start</Form.Label>
                <Form.Control
                    id='ant_start'
                    type='date'
                    onChange={(e) => state.setAntStart(e.target.value)}
                    defaultValue={state.ant_start}
                    required
                />
            </Form.Group>

            <Form.Group>
                {/* Ant End */}
                <Form.Label htmlFor='ant_end'>Anticipated End</Form.Label>
                <Form.Control
                    id='ant_end'
                    type='date'
                    onChange={(e) => state.setAntEnd(e.target.value)}
                    defaultValue={state.ant_end}
                    required
                />
            </Form.Group>

            <Form.Group>
                {/* Act Start */}
                <Form.Label htmlFor='act_start'>Actual Start</Form.Label>
                <Form.Control
                    id='act_start'
                    type='date'
                    onChange={(e) => state.setActStart(e.target.value)}
                    defaultValue={state.act_start}
                />
            </Form.Group>

            <Form.Group>
                {/* Act End */}
                <Form.Label htmlFor='act_end'>Actual End</Form.Label>
                <Form.Control
                    id='act_end'
                    type='date'
                    onChange={(e) => state.setActEnd(e.target.value)}
                    defaultValue={state.act_end}
                />
            </Form.Group>

            <Form.Group>
                {/* Act End */}
                <Form.Label htmlFor='notes'>Notes</Form.Label>
                <Form.Control
                    id='notes'
                    as='textarea'
                    onChange={(e) => state.setNotes(e.target.value)}
                    defaultValue={state.notes}
                />
            </Form.Group>

            <Form.Group>
                <Button className='w-100' type='submit' id='editsubbtn'>
                    Submit
                </Button>
            </Form.Group>
        </Form>
    );
};

TransferEditForm.propTypes = {
    transfer: PropTypes.object,
    afterSubmit: PropTypes.func,
};
