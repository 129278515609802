import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import RequestFormReducer from './RequestReducer';
import * as types from '../../Shared/constants';

const initialState = {
    csb_eligible: false,
    stockpile_eligible: false,
    geotechnical_requirements: false,
    geotechnical_details: '',
    expiration_date: '',
    loads_per_day: '',
    proposed_usage: '',
    notes: '',
    soil_types: new Map(),
    stockpile_types: new Map(),
};

export const RequestFormContext = createContext(initialState);

export const RequestFormProvider = ({ children }) => {
    const [state, dispatch] = useReducer(RequestFormReducer, initialState);

    const setCsbEligible = (payload) => {
        dispatch({ type: types.SET_CSB_ELIGIBLE, payload });
    };

    const setStockpileEligible = (payload) => {
        dispatch({ type: types.SET_STOCKPILE_ELIGIBLE, payload });
    };

    const setSoilTypes = (payload) => {
        dispatch({
            type: types.SET_SOIL_TYPE,
            payload,
        });
    };

    const setStockpileTypes = (payload) => {
        dispatch({
            type: types.SET_STOCKPILE_TYPE,
            payload,
        });
    };

    const toggleGeotechnicalRequirements = (payload) => {
        dispatch({
            type: types.TOGGLE_GEOTECHNICAL_REQUIREMENTS,
            payload,
        });
    };

    const setGeotechnicalDetails = (payload) => {
        dispatch({
            type: types.SET_GEOTECHNICAL_DETAILS,
            payload,
        });
    };

    const setExpirationDate = (payload) => {
        dispatch({
            type: types.SET_EXPIRATION_DATE,
            payload,
        });
    };

    const setLoadsPerDay = (payload) => {
        dispatch({
            type: types.SET_LOADS_PER_DAY,
            payload,
        });
    };

    const setProposedUsage = (payload) => {
        dispatch({
            type: types.SET_PROPOSED_USAGE,
            payload,
        });
    };

    const setNotes = (payload) => {
        dispatch({
            type: types.SET_ADDITIONAL_NOTES,
            payload,
        });
    };

    return (
        <RequestFormContext.Provider
            value={{
                csb_eligible: state.csb_eligible,
                stockpile_eligible: state.stockpile_eligible,
                soil_types: state.soil_types,
                stockpile_types: state.stockpile_types,
                geotechnical_requirements: state.geotechnical_requirements,
                geotechnical_details: state.geotechnical_details,
                expiration_date: state.expiration_date,
                loads_per_day: state.loads_per_day,
                proposed_usage: state.proposed_usage,
                notes: state.notes,
                setCsbEligible,
                setStockpileEligible,
                setSoilTypes,
                setStockpileTypes,
                toggleGeotechnicalRequirements,
                setGeotechnicalDetails,
                setExpirationDate,
                setLoadsPerDay,
                setProposedUsage,
                setNotes,
            }}
        >
            {children}
        </RequestFormContext.Provider>
    );
};

RequestFormProvider.propTypes = {
    children: PropTypes.node,
};
