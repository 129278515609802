import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import SmartMatchingReducer from './SmartMatchingReducer';
import * as actions from './actions';

const initialState = {
    selected_site: null,
    secondary_site: null,
    filtered_sites: null,
    category: null,
};

export const SmartMatchingContext = createContext(initialState);

export const SmartMatchingProvider = ({ children }) => {
    const [state, dispatch] = useReducer(SmartMatchingReducer, initialState);

    const setSelectedSite = (payload) => {
        dispatch({ type: actions.SET_SELECTED_SITE, payload });
    };
    const setSecondarySite = (payload) => {
        dispatch({ type: actions.SET_SECONDARY_SITE, payload });
    };

    const setFilteredSites = (payload) => {
        dispatch({ type: actions.SET_FILTERED_SITES, payload });
    };

    const setCategory = (payload) => {
        dispatch({ type: actions.SET_CATEGORY, payload });
    };

    return (
        <SmartMatchingContext.Provider
            value={{
                selected_site: state.selected_site,
                secondary_site: state.secondary_site,
                filtered_sites: state.filtered_sites,
                category: state.category,
                setSelectedSite,
                setSecondarySite,
                setFilteredSites,
                setCategory,
            }}
        >
            {children}
        </SmartMatchingContext.Provider>
    );
};

SmartMatchingProvider.propTypes = {
    children: PropTypes.node,
};
