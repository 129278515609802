export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const GET_ALL_SITES = 'GET_ALL_SITES';
export const API_URL =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_URL
        : window.__global.REACT_APP_API_URL;
export const EPIC_URL =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_EPIC_URL
        : window.__global.REACT_APP_EPIC_URL;
export const EPIC_URL_BACKUP =
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_EPIC_URL_BACKUP
            : window.__global.REACT_APP_EPIC_URL_BACKUP;
    
