import React from 'react';

export const WindowSlider = ({ map_ref }) => {
    if (!map_ref) return null;

    return (
        <div
            className='slider-bar'
            style={{
                width: '20px',
                backgroundColor: 'black',
                cursor: 'col-resize',
                userSelect: 'none',
            }}
            onMouseDown={(e) => {
                // Get the Mouse X Position and Update
                // the Width Based on leftX
                let drag = e.clientX;
                document.onmousemove = (e) => {
                    if (!map_ref) return;
                    map_ref.current.container.style.width =
                        map_ref.current.container.offsetWidth +
                        e.clientX -
                        drag +
                        'px';
                    map_ref.current.leafletElement.invalidateSize();
                    drag = e.clientX;
                };

                document.onmouseup = () =>
                    (document.onmousemove = document.onmouseup = null);
            }}
        />
    );
};
