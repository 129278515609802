import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import RootReducer from './RootReducer';

const initialState = {
    loading: true,
    cache: {},
    query: {
        sites: [],
        transfers: [],
        users:[],
        alternateLocations:[]
    },
    form: {
        generating_id: null,
        generating_site: null,
        transactions: [],
    },
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(RootReducer, initialState);
    const setLoading = (val) => {
        dispatch({
            type: 'SET_LOADING',
            payload: val,
        });
    };

    const setCachedSet = (data) => {
        dispatch({
            type: 'SET_CACHED_SET',
            payload: data,
        });
    };

    const setQuery = (data) => {
        dispatch({
            type: 'SET_DB_QUERY',
            payload: data,
        });
    };

    return (
        <GlobalContext.Provider
            value={{
                ...state,
                setLoading,
                setCachedSet,
                setQuery,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

GlobalProvider.propTypes = {
    children: PropTypes.node,
};
