import * as actions from '../../Shared/constants';

export default (state, action) => {
    switch (action.type) {
        case actions.SET_STOCKPILE:
            return {
                ...state,
                stockpile: action.payload,
            };
        case actions.SET_PENDING:
            return {
                ...state,
                pending: action.payload,
            };
        case actions.SET_FIRST_NAME:
            return {
                ...state,
                contact: { ...state.contact, firstname: action.payload },
            };
        case actions.SET_LAST_NAME:
            return {
                ...state,
                contact: { ...state.contact, lastname: action.payload },
            };
        case actions.SET_PHONE:
            return {
                ...state,
                contact: { ...state.contact, phone: action.payload },
            };
        case actions.SET_PROJECT_NAME:
            return {
                ...state,
                project_name: action.payload,
            };
        case actions.SET_OER_PROJECT:
            return {
                ...state,
                oer_proj: action.payload.toUpperCase(),
            };
        case actions.SET_ADDRESS:
            return {
                ...state,
                address: action.payload,
            };
        case actions.SET_AUTOFILL:
            return {
                ...state,
                autofill: action.payload,
            };
        case actions.SET_BOROUGH:
            return {
                ...state,
                borough: action.payload,
            };
        case actions.SET_COORDS:
            return {
                ...state,
                coords: action.payload,
            };
        case actions.SET_BBL:
            return {
                ...state,
                bbl: action.payload,
            };
        case actions.SET_SITE_TYPE:
            return {
                ...state,
                site_types: action.payload,
            };
        case actions.SET_SITE_PROGRAM:
            return {
                ...state,
                program_types: action.payload,
            };
        case actions.SET_INPUT_TYPE:
            return {
                ...state,
                input_type: action.payload,
            };
        case actions.SET_VALID:
            return {
                ...state,
                valid_input: action.payload,
            };
        case actions.SET_ADDITIONAL_NOTES:
            return {
                ...state,
                notes: action.payload,
            };

        default:
            throw new Error();
    }
};
