import redsvg from '../../assets/img/red_icon.svg';
import bluesvg from '../../assets/img/blue_icon.svg';
import blacksvg from '../../assets/img/black_icon.svg';
import orangesvg from '../../assets/img/orange_icon.svg';
import purplesvg from '../../assets/img/purple_icon.svg';
import greensvg from '../../assets/img/green_icon.svg';
import L from 'leaflet';

export const blueIcon = new L.Icon({
    iconUrl: bluesvg,
    iconRetinaUrl: bluesvg,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [25, 55],
});
export const redIcon = new L.Icon({
    iconUrl: redsvg,
    iconRetinaUrl: redsvg,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [25, 55],
});

export const blackIcon = new L.Icon({
    iconUrl: blacksvg,
    iconRetinaUrl: blacksvg,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [25, 55],
});

export const orangeIcon = new L.Icon({
    iconUrl: orangesvg,
    iconRetinaUrl: orangesvg,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [25, 55],
});

export const purpleIcon = new L.Icon({
    iconUrl: purplesvg,
    iconRetinaUrl: purplesvg,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [25, 55],
});

export const greenIcon = new L.Icon({
    iconUrl: greensvg,
    iconRetinaUrl: greensvg,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [25, 55],
});
