export const getBorough = (n) => {
    switch (n) {
        case '1':
            return 'MN';
        case '2':
            return 'BX';
        case '3':
            return 'BK';
        case '4':
            return 'QN';
        case '5':
            return 'SI';
        default:
            return '-1';
    }
};

export const castBorough = (b) => {
    switch (b) {
        case 'Manhattan':
            return '1';
        case 'Bronx':
            return '2';
        case 'Brooklyn':
            return '3';
        case 'Queens':
            return '4';
        case 'Staten Island':
            return '5';
        default:
            throw new Error();
    }
};

export const constructBBL = ({ BlockNumber, Borough, LotNumber }) => {
    let bbl = '';
    bbl += castBorough(Borough);

    bbl += BlockNumber;
    bbl += LotNumber;

    return bbl;
};
