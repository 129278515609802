import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export const ProgramTypeInput = ({ onChange, defaultValues }) => {
    const allowed = new Set(['vcp', 'bcp', 'e-designation/rd', 'hpd']);
    let o = false,
        dov = '';
    if (defaultValues)
        new Set(defaultValues).forEach((v) => {
            if (!allowed.has(v)) {
                o = true;
                dov = v;
            }
        });
    const [state, setState] = useState(defaultValues || new Set());
    const [other, setOther] = useState(o);
    const [otherVal, setOtherVal] = useState(dov);

    const handleCheck = (val) => {
        const checked = new Set(state);
        const v = val.toLowerCase();

        if (checked.has(v)) {
            checked.delete(v);
        } else {
            checked.add(v);
        }
        setState(checked);
    };

    const handleOther = (val) => {
        const checked = new Set(state);

        checked.delete(otherVal);
        if (!val || val == '') {
            setOtherVal('');
        } else {
            setOtherVal(val);
            checked.add(val);
        }
        setState(checked);
    };

    useEffect(() => {
        onChange(state);
    }, [state]);

    useEffect(() => {
        if (!other) handleOther('');
    }, [other]);
    return (
        <>
            <Form.Label htmlFor={`program_type`}>Program Type</Form.Label>
            <Form.Group>
                <Form.Check
                    onChange={(e) => {
                        handleCheck(e.target.value);
                    }}
                    type='checkbox'
                    id='vcp'
                    label='VCP'
                    value='VCP'
                    checked={state.has('vcp')}
                />
                <Form.Check
                    onChange={(e) => {
                        handleCheck(e.target.value);
                    }}
                    type='checkbox'
                    id='bcp'
                    label='NYS BCP'
                    value='BCP'
                    checked={state.has('bcp')}
                />
                <Form.Check
                    onChange={(e) => {
                        handleCheck(e.target.value);
                    }}
                    type='checkbox'
                    id='erd'
                    label='E-Designation/RD'
                    value='E-Designation/RD'
                    checked={state.has('e-designation/rd')}
                />
                <Form.Check
                    onChange={(e) => {
                        handleCheck(e.target.value);
                    }}
                    type='checkbox'
                    id='hpd'
                    label='HPD'
                    value='HPD'
                    checked={state.has('hpd')}
                />
                <div className='d-flex'>
                    <Form.Check
                        onChange={() => {
                            setOther((currOther) => !currOther);
                        }}
                        type='checkbox'
                        id='other_program_type'
                        label='Other'
                        value='Other'
                        checked={other}
                    />
                    <Form.Control
                        type='text'
                        className='ml-2'
                        style={{ width: '20%', height: '100%', padding: 0 }}
                        onChange={(e) => handleOther(e.target.value)}
                        value={otherVal}
                        disabled={!other}
                    ></Form.Control>
                </div>
            </Form.Group>
        </>
    );
};

ProgramTypeInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultValues: PropTypes.instanceOf(Set),
};
