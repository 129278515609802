import {
    redIcon,
    purpleIcon,
    orangeIcon,
    greenIcon,
    blackIcon,
} from '../MapIcons';

// Change Icon Color Based on the Site's Receiving/Generating Capacities
export const categoricalIconPicker = (s) => {
    if (s.stockpile) {
        // TODO: Add Cases for Stockpile
        return purpleIcon;
    } else {
        if (
            s.total_soil_generated - s.total_soil_transferred > 0 &&
            s.total_soil_requested - s.total_soil_received > 0
        ) {
            return purpleIcon;
        } else if (s.total_soil_generated - s.total_soil_transferred > 0) {
            return orangeIcon;
        } else if (s.total_soil_requested - s.total_soil_received > 0) {
            return greenIcon;
        } else {
            return blackIcon;
        }
    }
};

export const GENERATING = 'GENERATING';
export const RECEIVING = 'RECEIVING';
