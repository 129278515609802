import { API_URL, EPIC_URL } from './queries';

/* eslint-disable no-undef */
export const attemptLogin = async (data) => {
    try {
        if (!data || !data.userName || !data.password) return false;
        const url = `${API_URL}/login`;
        const d = { email: data.userName, password: data.password };
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(d),
        });
        return res.json();
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const attemptLogout = async () => {
    try {
        const url = `${API_URL}/logout`;
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        return res.json();
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const getApiData = async () => {
    try {
        const url = EPIC_URL;

        const res = await fetch(
            url
            // FIXME: Potentially Find CORS Workaround After EPIC Patch (When Pods Have Outbound Traffic Access)
            // , { headers: { 'X-API-KEY': process.env.REACT_APP_EPIC_API_KEY } }
        );
        const data = await res.json();
        return data;
    } catch (err) {
        console.log('Error fetching API Data');
        return {};
    }
};

export const runQuery = async () => {
    const transfer_url = `${API_URL}/transfers`;
    const site_url = `${API_URL}/sites`;
    const user_url = `${API_URL}/user`;
    const alternateLocations_url = `${API_URL}/alternateLocations`;
    // const source_url = `${API_URL}/sources`;
    // const destination_url = `${API_URL}/destinations`;
    const data = {};

    try {
        const transfer_res = await fetch(transfer_url,{credentials:'include'});
        const p = await transfer_res.json();
        data.transfers = await p.transfers;

        const site_res = await fetch(site_url,{credentials:'include'});
        const s = await site_res.json();
        data.sites = await s.sites;

        const getAllUser_res = await fetch(user_url,{credentials:'include'});
        const user_json = await getAllUser_res.json();
        data.users = await user_json.userList;
        
        const getAlternateLocations_res = await fetch(alternateLocations_url);
        const alternateLocations_json = await getAlternateLocations_res.json();
        data.alternateLocations = await alternateLocations_json.altList;
        
        // const source_res = await fetch(source_url);
        // const ss = await source_res.json();
        // data.sources = await ss.sources;

        // const destination_res = await fetch(destination_url);
        // const d = await destination_res.json();
        // data.destinations = await d.destinations;

        return data;
    } catch (err) {
        console.log('Error Running Query');
        return {};
    }
};

export const getSites = async () => {
    const site_url = `${API_URL}/sites`;

    try {
        const site_res = await fetch(site_url);
        const s = await site_res.json();

        return s.sites;
    } catch (err) {
        console.log('Error Fetching Sites');
        return [];
    }
};

export const getTransfers = async () => {
    // Returns Array
    const transfer_url = `${API_URL}/transfers`;

    try {
        const transfer_res = await fetch(transfer_url);
        const p = await transfer_res.json();

        return p.transfers;
    } catch (err) {
        console.log('Error Fetching Transfers');
        return [];
    }
};

export const submitForm = async (d) => {
    const form = {
        data: {
            ...d,
        },
    };

    let url = `${API_URL}/transfers/`;

    const res = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
    });

    const json = await res.json();
    return json;
};

export const submitBulk = async (transactions) => {
    let failed = [];
    let succeeded = [];

    try {
        for (const transaction of transactions) {
            const form = {
                data: {
                    ...transaction.data,
                },
            };

            let url = `${API_URL}/transfers`;

            const res = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(form),
            });

            const { success } = await res.json();

            if (!success) {
                failed.push(transaction);
            } else {
                succeeded.push(transaction);
            }
        }

        return { succeeded, failed };
    } catch (err) {
        console.log(err);
        return { succeeded, failed };
    }
};

export const submitSite = async (site) => {
    console.log(site);
    const data = {
        data: {
            ...site,
        },
    };

    try {
        let url = `${API_URL}/sites/`;

        const res = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const ret = await res.json();
        return ret;
    } catch (err) {
        console.log(err);
        return { success: false, msg: err };
    }
};

export const deleteAPITransaction = async (id) => {
    try {
        const url = `${API_URL}/transfers/${id}`;

        const res = await fetch(url, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const ret = await res.json();
        return ret;
    } catch (err) {
        console.log(err);
        return { success: false, msg: err };
    }
};

export const editAPITransaction = async (id, data) => {
    try {
        let url = `${API_URL}/transfers/${id} `;

        const res = await fetch(url, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data }),
        });

        const { success } = await res.json();
        return success;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const editAPISite = async (id, data) => {
    try {
        let url = `${API_URL}/sites/${id}`;

        const res = await fetch(url, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data }),
        });

        const ret = await res.json();
        return ret;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const deleteAPISite = async (id, data) => {
    try {
        let url = `${API_URL}/sites/${id} `;

        const res = await fetch(url, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data }),
        });

        const ret = await res.json();
        return ret;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const submitMatch = async (data) => {
    // When Matching:
    // - Get the two id's of the pending sites
    // - Create New Sites from the Both Pending Site Records
    // - Remove the Pending Site Records from Pending Table

    try {
        let url = `${API_URL}/match/ `;

        const res = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data }),
        });

        const ret = await res.json();
        return ret;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const submitPhotoVideo = async (data) => {
    try {
        let url = `${API_URL}/uploads/ `;
        const formData = new FormData();

        for (const name in data) {
            formData.append(name, data[name]);
        }

        const res = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            body: formData,
        });

        const ret = await res.json();
        return ret;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const deletePhotoVideo = async (id) => {
    try {
        let url = `${API_URL}/uploads/${id} `;

        const res = await fetch(url, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const ret = await res.json();
        return ret;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const getAllUser = async()=>{
    const url = `${API_URL}/user`;
    let userList = [];
    try{
        const getAllUser_res = await fetch(url); // fetch GET
        const user_json = await getAllUser_res.json();
        userList = await user_json.userList;

        return userList;
    }catch(err){
        console.log("error happened on fetch all user inforamtion!");
        console.log(err);
        return [];
    }
};

export const newUser = async(userData)=>{
    const url = `${API_URL}/user`;
    try{
        const addUser_res = await fetch(url,{
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userData }),
        });
        const result = await addUser_res.json();
        return result;
    }catch(err){
        console.log(err);
        return false;
    }
};

export const changePassword = async(email,newPwd)=>{
    const url = `${API_URL}/user`;
    try{
        const changePassword_res = await fetch(url,{
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email,newPwd }),
        });
        const result = await changePassword_res.json();
        return result;
    }catch(err){
        console.log(err);
        return false;
    }
};

export const updateUser = async(updateData)=>{
    const url = `${API_URL}/userManagement`;
    try{
        const updateUser_res = await fetch(url,{
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ updateData }),
        });
        const result = await updateUser_res.json();
        return result;
    }catch(err){
        console.log(err);
        return false;
    }
};

export const createAlternateLocation = async(data)=>{
    const url = `${API_URL}/alternateLocations`;
    try{
        const create_res = await fetch(url,{
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        const result = await create_res.json();
        return result;
    }catch(err){
        console.log(err);
        return false;
    }
};
export const updateAlternatelocation = async (data)=>{
    try {
        const url = `${API_URL}/alternateLocations/`;
        const res = await fetch(url,{
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({data}),
        })
        const result = await res.json();
        return result;
    } catch (err) {
        console.log(err);
        return false;
    }
}

export const deleteAlternatelocation = async (id)=>{
    try {
        const url = `${API_URL}/alternateLocations/${id}`;
        const res = await fetch(url,{
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        const result = await res.json();
        return result;
    } catch (err) {
        console.log(err);
        return false;
    }
}