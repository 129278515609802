import React, { useState, useEffect, useContext } from 'react';
import { Container, Form } from 'react-bootstrap';
import * as SORT from '../../../utils/smartmatching';
import { MatchCard } from '../../Cards/MatchCard';
import { SmartMatchingContext } from '../../../context/SmartMatchingContext';
import { deepCopy } from '../../../utils';
import { GlobalContext } from '../../../context/GlobalState';
import { GENERATING } from '../helpers';

export const Filters = () => {
    const MAP = useContext(SmartMatchingContext);
    const {
        query: { sites },
    } = useContext(GlobalContext);
    const [min_amount_cy, setMinAmountCY] = useState(0);
    const [max_amount_cy, setMaxAmountCY] = useState(null);
    const [distance, setDistance] = useState(10);
    const [max_distance, setMaxDistance] = useState(100);
    const [sort, setSort] = useState(SORT.DISTANCE_LTH);

    useEffect(() => {
        if (!MAP.selected_site) return;

        const soil_sum =
            MAP.category === GENERATING
                ? 'total_soil_requested'
                : 'total_soil_generated';
        const soil_moved =
            MAP.category === GENERATING
                ? 'total_soil_received'
                : 'total_soil_transferred';

        const base_sites = deepCopy(sites).filter((site) => {
            return (
                site.id !== MAP.selected_site.id &&
                // TODO: Add Stockpile Math to This
                site[soil_sum] - site[soil_moved] > 0
            );
        });

        const s = SORT.filter_sites(MAP.selected_site, base_sites, {
            category: MAP.category,
            sort,
            distance,
            min: min_amount_cy,
            max: max_amount_cy,
        });

        MAP.setFilteredSites(s);
    }, [MAP.selected_site, min_amount_cy, max_amount_cy, distance, sort]);

    useEffect(() => {
        if (!MAP.selected_site) return;

        // Get the Farthest Site to Upper-Bound the Max Distance Slider
        if (MAP.filtered_sites.length > 0) {
            const dist_lth = deepCopy(MAP.filtered_sites).sort((a, b) => {
                let da = SORT.haversine(
                    a.lat,
                    a.lng,
                    MAP.selected_site.lat,
                    MAP.selected_site.lng
                );
                let db = SORT.haversine(
                    b.lat,
                    b.lng,
                    MAP.selected_site.lat,
                    MAP.selected_site.lng
                );
                return da - db;
            });

            const md =
                Math.ceil(
                    SORT.haversine(
                        dist_lth[dist_lth.length - 1].lat,
                        dist_lth[dist_lth.length - 1].lng,
                        MAP.selected_site.lat,
                        MAP.selected_site.lng
                    )
                ) + 1;

            setDistance(md);
            setMaxDistance(md);
        }
    }, [MAP.selected_site]);

    if (!MAP.selected_site) return null;

    return (
        <Container
            fluid
            className='p-0'
            style={{
                marginBottom: '50px',
            }}
        >
            <Form.Group className='d-flex justify-content-between my-2'>
                <Form.Check
                    type='checkbox'
                    id='csb-eligible'
                    label='CSB Eligible'
                    // TODO: Remove this After Availability/Request Satisfaction Implemented
                    disabled
                />
                <Form.Check
                    type='checkbox'
                    id='stockpile-eligible'
                    label='Stockpile Eligible'
                    // TODO: Remove this After Availability/Request Satisfaction Implemented
                    disabled
                />
            </Form.Group>
            <Form.Label>Sort</Form.Label>
            <Form.Control
                as='select'
                value={sort}
                onChange={(e) => {
                    setSort(e.target.value);
                }}
            >
                <option value={SORT.DISTANCE_LTH}>
                    Distance (Close to Far)
                </option>
                <option value={SORT.DISTANCE_HTL}>
                    Distance (Far to Close)
                </option>

                <option value={SORT.AMOUNT_LTH}>Amount (Low to High)</option>
                <option value={SORT.AMOUNT_HTL}>Amount (High to Low)</option>
                {/* <option value={SORT.CREATED_LTH}>Created (New to Old)</option>
                <option value={SORT.CREATED_HTL}>Created (Old to New)</option>
                <option value={SORT.EXPIRATION_HTL}>
                    Expiration (New to Old)
                </option>
                <option value={SORT.EXPIRATION_LTH}>
                    Expiration (Old to New)
                </option> */}
                <option value={SORT.UPDATED_LTH}>Updated (New to Old)</option>
                <option value={SORT.UPDATED_HTL}>Updated (Old to New)</option>
            </Form.Control>
            <Form.Group className='d-flex justify-content-between'>
                <div>
                    <Form.Label>Min. Amount (CY)</Form.Label>
                    <Form.Control
                        value={min_amount_cy || ''}
                        type='number'
                        min='0'
                        onChange={(e) => {
                            setMinAmountCY(
                                e.target.value !== '' ? e.target.value : 0
                            );
                        }}
                    />
                </div>
                <div>
                    <Form.Label>Max. Amount (CY)</Form.Label>
                    <Form.Control
                        value={max_amount_cy || ''}
                        type='number'
                        min='0'
                        onChange={(e) => {
                            setMaxAmountCY(
                                e.target.value !== '' ? e.target.value : null
                            );
                        }}
                    />
                </div>
            </Form.Group>

            <Form.Label>Max Distance ({distance} Mi.)</Form.Label>
            <Form.Control
                value={distance}
                type='range'
                onChange={(e) => setDistance(e.target.value)}
                min={0}
                max={max_distance}
            />
            <hr />
            {MAP.secondary_site ? (
                <>
                    <h3>Selected Match</h3>{' '}
                    <MatchCard site={MAP.secondary_site} />
                </>
            ) : (
                []
            )}
        </Container>
    );
};
