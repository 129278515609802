import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Collapse } from 'react-bootstrap';
import { SOIL_TYPES, CHEM_CHARS } from '../constants';

export const SoilInput = ({ onChange, defaultValues }) => {
    const [state, setState] = useState(defaultValues || new Map());

    const getVal = (t) => {
        if (!state.has(t.type)) return '';

        const { type, chem_char } = t;

        return state.get(type).get(chem_char) || '';
    };

    const toggleType = (t) => {
        const tr = new Map(state);

        if (tr.has(t)) {
            tr.delete(t);
        } else {
            tr.set(t, new Map());
        }

        setState(tr);
    };

    const addSoil = (t) => {
        if (!state.has(t.type)) return state;

        const { type, chem_char, val } = t;
        const tr = new Map(state);
        const n = new Map(tr.get(type));
        n.has(chem_char) && !val ? n.delete(chem_char) : n.set(chem_char, val);

        tr.set(type, n);

        setState(tr);
    };

    useEffect(() => {
        onChange(state);
    }, [state]);

    return Object.values(SOIL_TYPES).map((soilType, i) => (
        <Form.Group key={i} className='w-50'>
            <Form.Check
                id={soilType.replaceAll(/\s/g, '-')}
                label={soilType}
                onClick={() => toggleType(soilType)}
                style={{ textTransform: 'capitalize' }}
                defaultChecked={state.has(soilType)}
            />
            <Collapse in={state.has(soilType)}>
                <Form.Group>
                    <Form.Label
                        htmlFor={`${soilType.replaceAll(/\s/g, '-')}-unres`}
                    >
                        Unrestricted Amount
                    </Form.Label>
                    <Form.Control
                        id={`${soilType.replaceAll(/\s/g, '-')}-unres`}
                        type='number'
                        value={getVal({
                            type: soilType,
                            chem_char: CHEM_CHARS.UNRESTRICTED_USE,
                        })}
                        onChange={(e) => {
                            addSoil({
                                type: soilType,
                                chem_char: CHEM_CHARS.UNRESTRICTED_USE,
                                val: e.target.value,
                            });
                        }}
                    />
                    <Form.Label
                        htmlFor={`${soilType.replaceAll(/\s/g, '-')}-genfill`}
                    >
                        General Fill Amount
                    </Form.Label>
                    <Form.Control
                        id={`${soilType.replaceAll(/\s/g, '-')}-genfill`}
                        type='number'
                        value={getVal({
                            type: soilType,
                            chem_char: CHEM_CHARS.GENERAL_FILL,
                        })}
                        onChange={(e) =>
                            addSoil({
                                type: soilType,
                                chem_char: CHEM_CHARS.GENERAL_FILL,
                                val: e.target.value,
                            })
                        }
                    />

                    <Form.Label
                        htmlFor={`${soilType.replaceAll(/\s/g, '-')}-rest`}
                    >
                        Restricted Residential Amount
                    </Form.Label>
                    <Form.Control
                        id={`${soilType.replaceAll(/\s/g, '-')}-rest`}
                        type='number'
                        value={getVal({
                            type: soilType,
                            chem_char: CHEM_CHARS.RESTRICTED_USE,
                        })}
                        onChange={(e) =>
                            addSoil({
                                type: soilType,
                                chem_char: CHEM_CHARS.RESTRICTED_USE,
                                val: e.target.value,
                            })
                        }
                    />
                </Form.Group>
            </Collapse>
        </Form.Group>
    ));
};

SoilInput.propTypes = {
    onChange: PropTypes.func.isRequired,
};
