import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container, Pagination, Form } from 'react-bootstrap';

export const SmartMatchingPaginator = ({ items, width, sel_ref }) => {
    const [page, setPage] = useState(0);
    const [currWidth, setWidth] = useState(width);
    const [offset, setOffset] = useState(5);
    const base = page * offset;
    let min, max, pagelist;

    const obs = new ResizeObserver((entries) => {
        setWidth(entries[0].target.offsetWidth);
    });

    obs.observe(sel_ref.current);

    const mw = currWidth;

    let w = mw;
    const defaultWidth = 35;
    const digitFactor = 10;

    useEffect(() => {
        if (offset * page > items.length) {
            // If New Offset and Current Page Causes Items to Be Out of Bounds

            // Set to Last Page with New Offset
            setPage(Math.ceil(items.length / offset));
        }
    }, [offset]);

    const handlePageClick = (back) => {
        if (back && page <= 0) return;
        if (!back && base + offset > items.length) return;
        setPage(back ? page - 1 : page + 1);
    };

    if (items.length <= 0) {
        min = max = 0;
        pagelist = (
            <div className='text-center'>
                <p className='m-auto'>No Potential Matches Found.</p>
            </div>
        );
    } else if (items.length >= base + offset) {
        min = base + 1;
        max = base + offset;
        pagelist = items.slice(base, base + offset);
    } else if (items.length <= base + offset) {
        min = base + 1;
        max = items.length;
        pagelist = items.slice(base);
    } else {
        min = base + 1;
        max = items.length;
        pagelist = items;
    }

    const upperBound =
        items.length % offset > 0
            ? Math.ceil(items.length / offset)
            : items.length / offset;

    let paginationItems = [];
    let rev = [];
    w -= 5 * defaultWidth; // Make Room for Back/Forward Buttons and Ellipse

    // Go From the Back to Get the Widest Components Until Half of Width
    let n = upperBound;
    while (w >= mw / 2 && w > 0 && n <= upperBound && n > 0) {
        let x = n;
        rev.push(
            <Pagination.Item
                key={x - 1}
                active={page === x - 1}
                onClick={() => setPage(x - 1)}
            >
                {n}
            </Pagination.Item>
        );

        w -= defaultWidth + String(n).length * digitFactor;
        n--;
    }

    // Go From the Front to Get the Smallest Components Until Met in the Middle
    if (n > 0) {
        let limit = n + 1;

        n = 1;
        // Should Always Start from Page Unless it is Equal to the Limit
        if (page + 5 >= limit) {
            const estimator =
                limit - Math.floor(w / (defaultWidth + digitFactor));
            n = estimator > 0 ? estimator : page;
        } else {
            n = page + 1;
        }

        while (w > 0 && w > 0 && n > 0 && n < limit) {
            let x = n;
            paginationItems.push(
                <Pagination.Item
                    key={x - 1}
                    active={page === x - 1}
                    onClick={() => setPage(x - 1)}
                >
                    {n}
                </Pagination.Item>
            );

            w -= defaultWidth + String(n).length * digitFactor;
            n++;
        }

        // Add Ellipses
        paginationItems.push(<Pagination.Ellipsis key='ellipsis' />);
    }

    for (let i = rev.length; i >= 0; i--) {
        paginationItems.push(rev[i]);
    }

    return (
        <>
            <div>
                <p>Items Per Page: </p>
                <Form.Control
                    as='select'
                    onChange={(e) => {
                        setOffset(parseInt(e.target.value));
                    }}
                >
                    <option value={5} defaultValue>
                        5
                    </option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                </Form.Control>
            </div>
            {pagelist}

            <div>
                <Container className='d-flex flex-column align-items-center text-center'>
                    <Pagination>
                        <Pagination.First onClick={() => setPage(0)} />
                        <Pagination.Prev
                            onClick={() => handlePageClick(true)}
                        />
                        {paginationItems}
                        <Pagination.Next
                            onClick={() => handlePageClick(false)}
                        />
                        <Pagination.Last
                            onClick={() => setPage(upperBound - 1)}
                        />
                    </Pagination>

                    <p>
                        Showing {min}-{max} of {items.length}
                    </p>
                </Container>
            </div>
        </>
    );
};

SmartMatchingPaginator.propTypes = {
    items: PropTypes.array,
};
