import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import TransferFormReducer from './TransferFormReducer';
import * as actions from '../../Shared/constants';

const initialState = {
    generating_id: null,
    receiving_id: null,
    soil_types: new Map(),
    status: '',
    ant_start: '',
    ant_end: '',
    act_start: '',
    act_end: '',
    notes: '',
    source_alt_id:null,
    destination_alt_id:null,
};

export const TransferFormContext = createContext(initialState);

export const TransferFormProvider = ({ children }) => {
    const [state, dispatch] = useReducer(TransferFormReducer, initialState);

    const setGeneratingId = (payload) => {
        dispatch({ type: actions.SET_GENERATING_ID, payload });
    };

    const setReceivingId = (payload) => {
        dispatch({
            type: actions.SET_RECEIVING_ID,
            payload,
        });
    };

    const setSoilTypes = (payload) => {
        dispatch({
            type: actions.SET_SOIL_TYPE,
            payload,
        });
    };

    const setStatus = (payload) => {
        dispatch({
            type: actions.SET_STATUS,
            payload,
        });
    };

    const setAntStart = (payload) => {
        dispatch({
            type: actions.SET_ANT_START,
            payload,
        });
    };

    const setAntEnd = (payload) => {
        dispatch({
            type: actions.SET_ANT_END,
            payload,
        });
    };

    const setActStart = (payload) => {
        dispatch({
            type: actions.SET_ACT_START,
            payload,
        });
    };

    const setActEnd = (payload) => {
        dispatch({
            type: actions.SET_ACT_END,
            payload,
        });
    };

    const setNotes = (payload) => {
        dispatch({
            type: actions.SET_NOTES,
            payload,
        });
    };
    
    const setSource_Alt_Id = (payload) => {
        dispatch({
            type: actions.SET_SOURCE_ALT_ID,
            payload,
        });
    };
    const setDestination_Alt_Id = (payload) => {
        dispatch({
            type: actions.SET_DESTINATION_ALT_ID,
            payload,
        });
    };

    return (
        <TransferFormContext.Provider
            value={{
                ...state,
                setGeneratingId,
                setReceivingId,
                setSoilTypes,
                setStatus,
                setAntStart,
                setAntEnd,
                setActStart,
                setActEnd,
                setNotes,
                setSource_Alt_Id,
                setDestination_Alt_Id
            }}
        >
            {children}
        </TransferFormContext.Provider>
    );
};

TransferFormProvider.propTypes = {
    children: PropTypes.node,
};
