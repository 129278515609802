import * as actions from '../../Shared/constants';

export default (state, action) => {
    switch (action.type) {
        case actions.SET_CSB_ELIGIBLE:
            return {
                ...state,
                csb_eligible: action.payload,
            };
        case actions.SET_STOCKPILE_ELIGIBLE:
            return {
                ...state,
                stockpile_eligible: action.payload,
            };
        case actions.TOGGLE_RAWP_RAP:
            return {
                ...state,
                rawp_rap: action.payload,
            };
        case actions.SET_TOP_DEPTH:
            return {
                ...state,
                top_depth: action.payload,
            };
        case actions.SET_BOTTOM_DEPTH:
            return {
                ...state,
                bottom_depth: action.payload,
            };
        case actions.SET_GROUNDWATER_DEPTH:
            return {
                ...state,
                groundwater_depth: action.payload,
            };
        case actions.TOGGLE_BORING_LOGS:
            return {
                ...state,
                boring_logs: action.payload,
            };
        case actions.TOGGLE_GEOTECHNICAL_SAMPLES:
            return {
                ...state,
                geotechnical_samples: action.payload,
            };
        case actions.TOGGLE_GEOTECHNICAL_RESULTS:
            return {
                ...state,
                geotechnical_results: action.payload,
            };
        case actions.TOGGLE_CHEMICAL_TESTS:
            return {
                ...state,
                chemical_tests: action.payload,
            };
        case actions.SET_PH_VALUES:
            return {
                ...state,
                ph_values: action.payload,
            };
        case actions.SET_SOIL_TYPE:
            return {
                ...state,
                soil_types: action.payload,
            };
        case actions.SET_STOCKPILE_TYPE:
            return {
                ...state,
                stockpile_types: action.payload,
            };
        case actions.TOGGLE_DIRECT_LOADING:
            return {
                ...state,
                direct_loading: action.payload,
            };
        case actions.TOGGLE_SEGREGATED_SOIL_STOCKPILE:
            return {
                ...state,
                segregated_soil_stockpile: action.payload,
            };
        case actions.SET_EXCAVATION_DATE:
            return {
                ...state,
                excavation_date: action.payload,
            };
        case actions.SET_RATE_OF_EXPORT:
            return {
                ...state,
                rate_of_export: action.payload,
            };
        case actions.SET_ADDITIONAL_NOTES:
            return {
                ...state,
                notes: action.payload,
            };
        default:
            return state;
    }
};
