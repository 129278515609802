import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import { Container } from 'react-bootstrap';

export const Landing = () => {
    const { loading } = useContext(GlobalContext);

    if (loading) return <></>;

    return (
        <Container fluid className='d-flex' style={{ minHeight: 'inherit' }}>
            <p className='m-auto'>
                Welcome to the Clean Soil Bank Managment Tool
            </p>
        </Container>
    );
};
