import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { AvailabilityFormProvider } from './context/AvailabilityContext';
import { RequestFormProvider } from './context/RequestContext';
import { SoilAvailabilityForm } from './Availability';
import { SoilRequestForm } from './Request';
import { Button } from 'react-bootstrap';
import { SiteSelector } from '../Shared/Inputs/SiteSelector';

export const AvailabilityRequestForm = () => {
    const [v, setV] = useState(0);
    const [sid, setSid] = useState(null);
    const [tmp, setTMP] = useState(null);
    const handleChange = (vl) => setV(vl);
    const h = useHistory();
    const renderSwitch = () => {
        switch (v) {
            case 0:
                return (
                    <>
                        <h2>Select a Site</h2>

                        <SiteSelector
                            id='arq-site'
                            mode='all'
                            placeholder='Site'
                            onChange={(el) => {
                                if (el) {
                                    setSid(parseInt(el.id));
                                    setTMP(el.tmp);
                                } else {
                                    setSid(null);
                                    setTMP(null);
                                }
                            }}
                        />
                        <Button
                            className='w-100'
                            variant='warning'
                            onClick={() => {
                                setV((curr) => curr + 1);
                                h.replace('/create/availability');
                            }}
                            disabled={!sid || sid == ''}
                        >
                            Next
                        </Button>
                    </>
                );
            case 1:
                return (
                    <>
                        <AvailabilityFormProvider>
                            <SoilAvailabilityForm
                                sid={sid}
                                afterSubmit={() => h.push(`/sites/${tmp}`)}
                            />
                        </AvailabilityFormProvider>
                        <Button
                            className='w-100'
                            variant='warning'
                            onClick={() => {
                                h.replace('/create/availability_request');
                                setV((curr) => curr - 1);
                            }}
                        >
                            Back
                        </Button>
                    </>
                );
            case 2:
                return (
                    <>
                        <RequestFormProvider>
                            <SoilRequestForm
                                sid={sid}
                                afterSubmit={() => h.push(`/sites/${tmp}`)}
                            />
                        </RequestFormProvider>
                        <Button
                            className='w-100'
                            variant='warning'
                            onClick={() => setV((curr) => curr - 2)}
                        >
                            Back
                        </Button>
                    </>
                );

            default:
                return 'Error';
        }
    };

    return (
        <div className='container'>
            {v > 0 ? (
                <ToggleButtonGroup
                    className='w-100'
                    type='radio'
                    name='options'
                    value={v}
                    onChange={handleChange}
                >
                    <ToggleButton
                        onClick={() => {
                            h.replace('/create/availability');
                        }}
                        value={1}
                    >
                        Availability
                    </ToggleButton>
                    <ToggleButton
                        onClick={() => {
                            h.replace('/create/request');
                        }}
                        value={2}
                    >
                        Request
                    </ToggleButton>
                </ToggleButtonGroup>
            ) : (
                []
            )}
            {renderSwitch()}
        </div>
    );
};

export const eligibilityToggleRenderer = (o, state) => {
    if (o.vendor_approval) {
        return (
            <>
                <Form.Group>
                    <Form.Label>CSB Eligible</Form.Label>
                    <div>
                        <Form.Check
                            inline
                            id='csb-eligibility-true'
                            type='radio'
                            name='csb-eligible'
                            label='Yes'
                            onChange={() => state.setCsbEligible(true)}
                            defaultChecked={o.csb_eligible}
                        />
                        <Form.Check
                            inline
                            id='csb-eligibility-false'
                            type='radio'
                            name='csb-eligible'
                            label='No'
                            onChange={() => state.setCsbEligible(false)}
                            defaultChecked={!o.csb_eligible}
                        />
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Stockpile Eligible{' '}
                        {o.deferred ? (
                            <Badge variant='secondary'>&#9888; Deferred</Badge>
                        ) : (
                            []
                        )}
                    </Form.Label>
                    <div>
                        <Form.Check
                            inline
                            id='stockpile-eligibility-true'
                            name='stockpile-eligible'
                            type='radio'
                            label='Yes'
                            onChange={() => state.setStockpileEligible(true)}
                            defaultChecked={o.stockpile_eligible}
                        />
                        <Form.Check
                            inline
                            id='stockpile-eligibility-false'
                            name='stockpile-eligible'
                            type='radio'
                            label='No'
                            onChange={() => state.setStockpileEligible(false)}
                            defaultChecked={!o.stockpile_eligible}
                        />
                    </div>
                </Form.Group>
            </>
        );
    } else if (o.oer_approval) {
        return (
            <>
                <Form.Group>
                    <Form.Label>CSB Eligible</Form.Label>
                    <div>
                        <Form.Check
                            inline
                            id='csb-eligibility-true'
                            type='radio'
                            name='csb-eligible'
                            label='Yes'
                            onChange={() => state.setCsbEligible(true)}
                            defaultChecked={o.csb_eligible}
                        />
                        <Form.Check
                            inline
                            id='csb-eligibility-false'
                            type='radio'
                            name='csb-eligible'
                            label='No'
                            onChange={() => state.setCsbEligible(false)}
                            defaultChecked={!o.csb_eligible}
                        />
                    </div>
                </Form.Group>
            </>
        );
    } else {
        return;
    }
};
