import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import AvailabilityFormReducer from './AvailabilityReducer';
import * as types from '../../Shared/constants';

const initialState = {
    csb_eligible: false,
    stockpile_eligible: false,
    rawp_rap: false,
    top_depth: '',
    bottom_depth: '',
    groundwater_depth: '',
    boring_logs: false,
    geotechnical_samples: false,
    geotechnical_results: false,
    chemical_tests: false,
    ph_values: '',
    direct_loading: false,
    segregated_soil_stockpile: false,
    excavation_date: '',
    rate_of_export: '',
    soil_types: new Map(),
    stockpile_types: new Map(),
    notes: '',
};

export const AvailabilityFormContext = createContext(initialState);

export const AvailabilityFormProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AvailabilityFormReducer, initialState);

    const setCsbEligible = (payload) => {
        dispatch({ type: types.SET_CSB_ELIGIBLE, payload });
    };

    const setStockpileEligible = (payload) => {
        dispatch({ type: types.SET_STOCKPILE_ELIGIBLE, payload });
    };

    const toggleRawpRap = (payload) => {
        dispatch({
            type: types.TOGGLE_RAWP_RAP,
            payload,
        });
    };

    const setTopDepth = (payload) => {
        dispatch({
            type: types.SET_TOP_DEPTH,
            payload,
        });
    };

    const setBottomDepth = (payload) => {
        dispatch({
            type: types.SET_BOTTOM_DEPTH,
            payload,
        });
    };

    const setGroundwaterDepth = (payload) => {
        dispatch({
            type: types.SET_GROUNDWATER_DEPTH,
            payload,
        });
    };

    const toggleBoringLogs = (payload) => {
        dispatch({
            type: types.TOGGLE_BORING_LOGS,
            payload,
        });
    };

    const toggleGeotechnicalSamples = (payload) => {
        dispatch({
            type: types.TOGGLE_GEOTECHNICAL_SAMPLES,
            payload,
        });
    };

    const toggleGeotechnicalResults = (payload) => {
        dispatch({
            type: types.TOGGLE_GEOTECHNICAL_RESULTS,
            payload,
        });
    };

    const toggleChemicalTests = (payload) => {
        dispatch({
            type: types.TOGGLE_CHEMICAL_TESTS,
            payload,
        });
    };

    const setPhValues = (payload) => {
        dispatch({
            type: types.SET_PH_VALUES,
            payload,
        });
    };

    const setSoilTypes = (payload) => {
        dispatch({
            type: types.SET_SOIL_TYPE,
            payload,
        });
    };

    const setStockpileTypes = (payload) => {
        dispatch({
            type: types.SET_STOCKPILE_TYPE,
            payload,
        });
    };

    const toggleDirectLoading = (payload) => {
        dispatch({
            type: types.TOGGLE_DIRECT_LOADING,
            payload,
        });
    };

    const toggleSegregatedSoilStockpile = (payload) => {
        dispatch({
            type: types.TOGGLE_SEGREGATED_SOIL_STOCKPILE,
            payload,
        });
    };

    const setExcavationDate = (payload) => {
        dispatch({ type: types.SET_EXCAVATION_DATE, payload });
    };

    const setRateofExport = (payload) => {
        dispatch({
            type: types.SET_RATE_OF_EXPORT,
            payload,
        });
    };

    const setNotes = (payload) => {
        dispatch({
            type: types.SET_ADDITIONAL_NOTES,
            payload,
        });
    };

    return (
        <AvailabilityFormContext.Provider
            value={{
                csb_eligible: state.csb_eligible,
                stockpile_eligible: state.stockpile_eligible,
                rawp_rap: state.rawp_rap,
                top_depth: state.top_depth,
                bottom_depth: state.bottom_depth,
                groundwater_depth: state.groundwater_depth,
                boring_logs: state.boring_logs,
                geotechnical_samples: state.geotechnical_samples,
                geotechnical_results: state.geotechnical_results,
                chemical_tests: state.chemical_tests,
                ph_values: state.ph_values,
                soil_types: state.soil_types,
                stockpile_types: state.stockpile_types,
                direct_loading: state.direct_loading,
                segregated_soil_stockpile: state.segregated_soil_stockpile,
                excavation_date: state.excavation_date,
                rate_of_export: state.rate_of_export,
                notes: state.notes,
                setCsbEligible,
                setStockpileEligible,
                toggleRawpRap,
                setTopDepth,
                setBottomDepth,
                setGroundwaterDepth,
                toggleBoringLogs,
                toggleGeotechnicalSamples,
                toggleGeotechnicalResults,
                toggleChemicalTests,
                setPhValues,
                setSoilTypes,
                setStockpileTypes,
                toggleDirectLoading,
                toggleSegregatedSoilStockpile,
                setExcavationDate,
                setRateofExport,
                setNotes,
            }}
        >
            {children}
        </AvailabilityFormContext.Provider>
    );
};

AvailabilityFormProvider.propTypes = {
    children: PropTypes.node,
};
