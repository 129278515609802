import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import { AvailabilityFormContext } from './context/AvailabilityContext';
import { SoilInput } from '../Shared/Inputs/SoilInput';
import { getSoilInputs } from '../../SiteOverview/SiteAvailabilityRequest';
import { GlobalContext } from '../../../context/GlobalState';
import { runQuery } from '../../../api';
import { eligibilityToggleRenderer } from '.';
import { API_URL } from '../../../api/queries';

export const SoilAvailabilityForm = ({ sid, afterSubmit }) => {
    const state = useContext(AvailabilityFormContext);
    const { setQuery } = useContext(GlobalContext);
    // If there is an existing source or destination with this site
    // Then we should fill in the existing data from the form
    return (
        <Form
            onSubmit={async (e) => {
                e.preventDefault();
                // debonce setting
                let btn = document.getElementById("subbtn")
                btn.disabled=true
                btn.innerText="Sending....."
                try {
                    const form = {
                        data: {
                            ...state,
                            site_id: sid,
                            truck_to_stockpile: state.segregated_soil_stockpile,
                            additional_notes: state.notes,
                            soil_types: Array.from(
                                state.soil_types.entries()
                            ).map(([k, v]) => [k, Array.from(v)]),
                        },
                    };

                    let url = `${API_URL}/availabilities/`;

                    const res = await fetch(url, {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(form),
                    });

                    const data = await runQuery();
                    await setQuery(data);

                    const json = await res.json();

                    console.log(json);

                    if (afterSubmit) afterSubmit();
                } catch (err) {
                    console.log(err);
                }
            }}
        >
            <h1>Soil Availability Form</h1>
            <Form.Switch
                id='rawp-rap'
                label='Excavation to be performed under an OER and/or NYSDEC approved RAWP or RAP'
                value={state.rawp_rap}
                onChange={() => state.toggleRawpRap(!state.rawp_rap)}
            />
            <h3>Soil Physical Characteristics</h3>
            <Form.Group>
                <Form.Label htmlFor='top-depth'>
                    Estimated Top Depth of Clean Horizon (Below Adjacent Street
                    Grade)
                </Form.Label>
                <Form.Control
                    id='top-depth'
                    type='number'
                    value={state.top_depth}
                    onChange={(e) => state.setTopDepth(e.target.value)}
                    required
                />

                <Form.Label htmlFor='bottom-depth'>
                    Estimated Bottom Depth (Below Adjacent Street Grade)
                </Form.Label>
                <Form.Control
                    id='bottom-depth'
                    type='number'
                    value={state.bottom_depth}
                    onChange={(e) => state.setBottomDepth(e.target.value)}
                    required
                />

                <Form.Label htmlFor='groundwater-depth'>
                    Estimated Groundwater Depth
                </Form.Label>
                <Form.Control
                    id='groundwater-depth'
                    type='number'
                    value={state.groundwater_depth}
                    onChange={(e) => state.setGroundwaterDepth(e.target.value)}
                    required
                />

                <Form.Switch
                    id='boring-logs'
                    label='Boring Logs for Clean Soil on File with OER in a Final RIR or Phase 2'
                    value={state.boring_logs}
                    onChange={() => state.toggleBoringLogs(!state.boring_logs)}
                />
                <Form.Switch
                    id='geotechnical-samples'
                    label='Soil Samples Available for Geotechnical Testing'
                    value={state.geotechnical_samples}
                    onChange={() =>
                        state.toggleGeotechnicalSamples(
                            !state.geotechnical_samples
                        )
                    }
                />
                <Form.Switch
                    id='geotechnical-results'
                    label='Geotechnical Test Results Available'
                    value={state.geotechnical_results}
                    onChange={() =>
                        state.toggleGeotechnicalResults(
                            !state.geotechnical_results
                        )
                    }
                />
                <Form.Switch
                    id='chemical_test'
                    label='Chemical Tests Performed on Soil (TCL/TAL and/or Waste Classification)'
                    value={state.chemical_tests}
                    onChange={() =>
                        state.toggleChemicalTests(!state.chemical_tests)
                    }
                />
                <Form.Label htmlFor='ph_values'>pH Values</Form.Label>
                <Form.Control
                    id='ph_values'
                    value={state.ph_values}
                    onChange={(e) => state.setPhValues(e.target.value)}
                />
            </Form.Group>

            <Form.Group>
                <h3>Soil Type (CY)</h3>
                <div className='d-flex flex-row flex-wrap'>
                    <SoilInput onChange={(v) => state.setSoilTypes(v)} />
                </div>
            </Form.Group>
            <Form.Group>
                <h3>Miscellaneous Information</h3>
                <Form.Switch
                    id='direct-loading'
                    label='Direct Soil Loading to Trucks from Excavation'
                    value={state.direct_loading}
                    onChange={() =>
                        state.toggleDirectLoading(!state.direct_loading)
                    }
                />
                <Form.Switch
                    id='segregated-stockpile'
                    label='Loading to Trucks from Segregated Clean Soil Stockpile'
                    value={state.segregated_soil_stockpile}
                    onChange={() =>
                        state.toggleSegregatedSoilStockpile(
                            !state.segregated_soil_stockpile
                        )
                    }
                />
                <Form.Label className='mt-2' htmlFor='export-date'>
                    Expected Date of Excavation
                </Form.Label>
                <Form.Control
                    id='export-date'
                    type='date'
                    value={state.excavation_date}
                    onChange={(e) => state.setExcavationDate(e.target.value)}
                    required
                />

                <Form.Label className='mt-2' htmlFor='export-rate'>
                    Rate of Export (CY per Day)
                </Form.Label>
                <Form.Control
                    id='export-rate'
                    type='number'
                    value={state.rate_of_export}
                    onChange={(e) => state.setRateofExport(e.target.value)}
                    required
                />

                <Form.Label className='mt-2' htmlFor='additional-details'>
                    Other Details
                </Form.Label>
                <Form.Control
                    id='additional-details'
                    as='textarea'
                    value={state.notes}
                    onChange={(e) => state.setNotes(e.target.value)}
                />
            </Form.Group>
            <Button className='w-100' type='submit' id='subbtn'>
                Submit
            </Button>
        </Form>
    );
};

SoilAvailabilityForm.propTypes = {
    sid: PropTypes.number.isRequired,
    afterSubmit: PropTypes.func,
};

export const SoilAvailabilityEditForm = ({ availability, afterSubmit }) => {
    const state = useContext(AvailabilityFormContext);
    const { setQuery } = useContext(GlobalContext);
    const [showSoilAmounts, setShowSoilAmounts] = useState(false);
    const [showStockpileAmounts, setShowStockpileAmounts] = useState(false);

    useEffect(() => {
        state.setCsbEligible(availability.csb_eligible);
        state.setStockpileEligible(availability.stockpile_eligible);
        state.toggleRawpRap(availability.rawp_rap);
        state.setTopDepth(availability.top_depth);
        state.setBottomDepth(availability.bottom_depth);
        state.setGroundwaterDepth(availability.groundwater_depth);
        state.toggleBoringLogs(availability.boring_logs);
        state.toggleGeotechnicalSamples(availability.geotechnical_samples);
        state.toggleGeotechnicalResults(availability.geotechnical_results);
        state.toggleDirectLoading(availability.direct_loading);
        state.toggleSegregatedSoilStockpile(availability.truck_to_stockpile);
        state.setRateofExport(availability.rate_of_export);
        state.toggleChemicalTests(availability.chemical_tests);
        state.setPhValues(availability.ph_values);
        state.setNotes(availability.additional_notes);
        state.setExcavationDate(availability.excavation_date);
    }, []);

    return (
        <Form
            onSubmit={async (e) => {
                e.preventDefault();
                // debonce setting
                let btn = document.getElementById("editsubbtn")
                btn.disabled=true
                btn.innerText="Sending....."
                try {
                    const form = {
                        data: {
                            csb_eligible: state.csb_eligible,
                            stockpile_eligible: state.stockpile_eligible,
                            rawp_rap: state.rawp_rap,
                            top_depth: state.top_depth,
                            bottom_depth: state.bottom_depth,
                            groundwater_depth: state.groundwater_depth,
                            boring_logs: state.boring_logs,
                            geotechnical_samples: state.geotechnical_samples,
                            geotechnical_results: state.geotechnical_results,
                            direct_loading: state.direct_loading,
                            rate_of_export: state.rate_of_export,
                            chemical_tests: state.chemical_tests,
                            ph_values: state.ph_values,
                            additional_notes: state.notes,
                            excavation_date: state.excavation_date,
                            truck_to_stockpile: state.segregated_soil_stockpile,
                            soil_types: Array.from(
                                state.soil_types.entries()
                            ).map(([k, v]) => [k, Array.from(v)]),
                        },
                    };

                    if (state.stockpile_eligible) {
                        form.data.stockpile_types = Array.from(
                            state.stockpile_types.entries()
                        ).map(([k, v]) => [k, Array.from(v)]);
                    }

                    let url = `${API_URL}/availabilities/${availability.id}`;

                    const res = await fetch(url, {
                        method: 'PATCH',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(form),
                    });

                    const json = await res.json();

                    const data = await runQuery();
                    await setQuery(data);

                    if (afterSubmit) afterSubmit();

                    console.log(json);
                } catch (err) {
                    console.log(err);
                }
            }}
        >
            {eligibilityToggleRenderer(availability, state)}
            <Form.Switch
                id='rawp-rap'
                label='Excavation to be performed under an OER and/or NYSDEC approved RAWP or RAP'
                value={state.rawp_rap}
                onChange={() => state.toggleRawpRap(!state.rawp_rap)}
                defaultChecked={availability.rawp_rap}
            />
            <h3>Soil Physical Characteristics</h3>
            <Form.Group>
                <Form.Label htmlFor='top-depth'>
                    Estimated Top Depth of Clean Horizon (Below Adjacent Street
                    Grade)
                </Form.Label>
                <Form.Control
                    id='top-depth'
                    type='number'
                    onChange={(e) => state.setTopDepth(e.target.value)}
                    defaultValue={availability.top_depth}
                    required
                />

                <Form.Label htmlFor='bottom-depth'>
                    Estimated Bottom Depth (Below Adjacent Street Grade)
                </Form.Label>
                <Form.Control
                    id='bottom-depth'
                    type='number'
                    onChange={(e) => state.setBottomDepth(e.target.value)}
                    defaultValue={availability.bottom_depth}
                    required
                />

                <Form.Label htmlFor='groundwater-depth'>
                    Estimated Groundwater Depth
                </Form.Label>
                <Form.Control
                    id='groundwater-depth'
                    type='number'
                    onChange={(e) => state.setGroundwaterDepth(e.target.value)}
                    defaultValue={availability.groundwater_depth}
                    required
                />

                <Form.Switch
                    id='boring-logs'
                    label='Boring Logs for Clean Soil on File with OER in a Final RIR or Phase 2'
                    onChange={() => state.toggleBoringLogs(!state.boring_logs)}
                    defaultChecked={availability.boring_logs}
                />
                <Form.Switch
                    id='geotechnical-samples'
                    label='Soil Samples Available for Geotechnical Testing'
                    onChange={() =>
                        state.toggleGeotechnicalSamples(
                            !state.geotechnical_samples
                        )
                    }
                    defaultChecked={availability.geotechnical_samples}
                />
                <Form.Switch
                    id='geotechnical-results'
                    label='Geotechnical Test Results Available'
                    onChange={() =>
                        state.toggleGeotechnicalResults(
                            !state.geotechnical_results
                        )
                    }
                    defaultChecked={availability.geotechnical_results}
                />
                <Form.Switch
                    id='chemical_test'
                    label='Chemical Tests Performed on Soil (TCL/TAL and/or Waste Classification)'
                    onChange={() =>
                        state.toggleChemicalTests(!state.chemical_tests)
                    }
                    defaultChecked={availability.chemical_tests}
                />
                <Form.Label htmlFor='ph_values'>pH Values</Form.Label>
                <Form.Control
                    id='ph_values'
                    defaultValue={availability.ph_values}
                    onChange={(e) => state.setPhValues(e.target.value)}
                />
            </Form.Group>

            <Form.Group>
                <div
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={() => setShowSoilAmounts((curr) => !curr)}
                >
                    <div
                        className={showSoilAmounts ? 'arrow down' : 'arrow'}
                    ></div>
                    <h3>Overall Soil Type (CY)</h3>
                </div>
                <div
                    id='soil-amount-collapse'
                    className={`d-flex flex-row flex-wrap ${
                        !showSoilAmounts ? 'hide' : ''
                    }`}
                >
                    <SoilInput
                        onChange={(v) => state.setSoilTypes(v)}
                        defaultValues={getSoilInputs(availability, true)}
                    />
                </div>
                {availability.stockpile_eligible ? (
                    <>
                        <div
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            onClick={() =>
                                setShowStockpileAmounts((curr) => !curr)
                            }
                        >
                            <div
                                className={
                                    showStockpileAmounts
                                        ? 'arrow down'
                                        : 'arrow'
                                }
                            ></div>
                            <h3>Stockpile Eligible Soil Type (CY)</h3>
                        </div>
                        <div
                            id='stockpile-amount-collapse'
                            className={`d-flex flex-row flex-wrap ${
                                !showStockpileAmounts ? 'hide' : ''
                            }`}
                        >
                            <SoilInput
                                key='stockpile-soil-amounts'
                                onChange={(v) => state.setStockpileTypes(v)}
                                defaultValues={getSoilInputs(
                                    availability,
                                    true,
                                    true
                                )}
                            />
                        </div>
                    </>
                ) : (
                    []
                )}
            </Form.Group>
            <Form.Group>
                <h3>Miscellaneous Information</h3>
                <Form.Switch
                    id='direct-loading'
                    label='Direct Soil Loading to Trucks from Excavation'
                    onChange={() =>
                        state.toggleDirectLoading(!state.direct_loading)
                    }
                    defaultChecked={availability.direct_loading}
                />

                <Form.Switch
                    id='segregated-stockpile'
                    label='Loading to Trucks from Segregated Clean Soil Stockpile'
                    onChange={() =>
                        state.toggleSegregatedSoilStockpile(
                            !state.segregated_soil_stockpile
                        )
                    }
                    defaultChecked={availability.truck_to_stockpile}
                />
                <Form.Label className='mt-2' htmlFor='export-date'>
                    Expected Date of Excavation
                </Form.Label>
                <Form.Control
                    id='export-date'
                    type='date'
                    onChange={(e) => state.setExcavationDate(e.target.value)}
                    defaultValue={availability.excavation_date.split('T')[0]}
                    required
                />

                <Form.Label className='mt-2' htmlFor='export-rate'>
                    Rate of Export (CY per Day)
                </Form.Label>
                <Form.Control
                    id='export-rate'
                    type='number'
                    onChange={(e) => state.setRateofExport(e.target.value)}
                    defaultValue={availability.rate_of_export}
                    required
                />

                <Form.Label className='mt-2' htmlFor='additional-details'>
                    Other Details
                </Form.Label>
                <Form.Control
                    id='additional-details'
                    as='textarea'
                    onChange={(e) => state.setNotes(e.target.value)}
                    defaultValue={availability.additional_notes || ''}
                />
            </Form.Group>
            <Button className='w-100' type='submit' id='editsubbtn'>
                Submit
            </Button>
        </Form>
    );
};

SoilAvailabilityEditForm.propTypes = {
    availability: PropTypes.object,
    afterSubmit: PropTypes.func,
};
