// Global State
export const SET_LOADING = 'SET_LOADING';
export const SET_LOGGED = 'SET_LOGGED';
export const SET_CACHED_SET = 'SET_CACHED_SET';
export const SET_DB_QUERY = 'SET_DB_QUERY';
export const FORM_ACTION = 'FORM_ACTION';
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const MODIFY_TRANSACTION = 'MODIFY_TRANSACTION';

// Smart Matching
export const SET_SELECTED_SITE = 'SET_SELECTED_SITE';
export const SET_SECONDARY_SITE = 'SET_SECONDARY_SITE';
export const SET_MIN_AMOUNT_CY = 'SET_MIN_AMOUNT_CY';
export const SET_MIN_AMOUNT_TN = 'SET_MIN_AMOUNT_TN';
export const SET_MAX_AMOUNT_CY = 'SET_MAX_AMOUNT_CY';
export const SET_MAX_AMOUNT_TN = 'SET_MAX_AMOUNT_TN';
export const SET_MAX_DISTANCE = 'SET_MAX_DISTANCE';
export const SET_SORT = 'SET_SORT';
export const SET_FILTERED_SITES = 'SET_FILTERED_SITES';
export const SET_POSITION = 'SET_POSITION';
export const SET_ZOOM = 'SET_ZOOM';
export const SET_MODAL = 'SET_MODAL';
export const SET_CATEGORY = 'SET_CATEGORY';
