import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { runQuery } from '../../api/';
//import { getSessionEntry } from '../../utils/sessionStorage';
import { GlobalContext } from '../../context/GlobalState';
import './Loading.css';
import { SolidLoader } from '../Spinners/SolidLoader';
import { Container } from 'react-bootstrap';
// import {
//     cacheValid,
//     insertCachedSet,
//     getCachedSet,
// } from '../../utils/cacheAPI';
import { EPIC_URL,EPIC_URL_BACKUP } from '../../api/queries';

export const Loading = ({ children }) => {
    const {
        loading,
        query,
        form: { type },
        setLoading,
        setQuery,
        setCachedSet,
        alterForm,
    } = useContext(GlobalContext);

    const preflight = async () => {
        //console.log(EPIC_URL)
        //console.log(EPIC_URL_BACKUP)
        try {
            //next line fetch the epic.json
            const cachedSet = await fetch(EPIC_URL)
                                    // .then(async (res) => {
                                    //     //console.log(res)
                                    //     if(res.status === 404){
                                    //         console.log("404 fetch backup epic data")
                                    //         return await fetch(EPIC_URL_BACKUP)
                                    //     }
                                    // })
            //const backupData = await fetch('http://localhost:8080/api/v1/backup/epic.json');
            // if(cachedSet.status === 404){
            //     console.log('no epic file found or the file is empty');
            //     //call another route to fetch backup epic.json file
            //     cachedSet = await fetch('http://localhost:8080/api/v1/backup/epic.json')
            // }
            await setCachedSet(cachedSet ? await cachedSet.json() : {});

            if (query.sites.length <= 0 || query.transfers.length <= 0 || query.users.length <=0) {
                const data = await runQuery();
                await setQuery(data);
            }


            await setLoading(false);
        } catch (err) {
            alert('There Was an Error Loading Application Data\n'+err);
            await setLoading(false);
        }
    };

    useEffect(() => {
        if (loading) preflight();
    }, [loading]);

    if (loading)
        return (
            <Container
                className='d-flex justify-content-center h-100 align-items-center'
                style={{ minHeight: 'calc(100vh - 56px)' }}
            >
                <div>
                    <SolidLoader />
                    <h2>Loading...</h2>
                </div>
            </Container>
        );

    return children;
};

Loading.propTypes = {
    children: PropTypes.node,
};
