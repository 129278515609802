import React, { useContext } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { SiteSelector } from '../Forms/Shared/Inputs/SiteSelector';
import { Button, Alert, Container } from 'react-bootstrap';
import { GlobalContext } from '../../context/GlobalState';
import { SiteMeta } from './SiteMeta';
import { SiteTransfers } from './SiteTransfers';
import { SiteAvailabilityRequest } from './SiteAvailabilityRequest';
import { deepCopy } from '../../utils';
import { TransferFormProvider } from '../Forms/Transfer/context/TransferFormContext';
import { NewSiteFormProvider } from '../Forms/NewSite/context/NewSiteContext';

export const SiteOverview = () => {
    const { TMP_NUMBER } = useParams();
    const history = useHistory();
    const {
        query: { sites },
    } = useContext(GlobalContext);

    // Check if there is a TMP Number in the URL Param
    if (TMP_NUMBER) {
        // If there is a TMP Number
        if (!sites) return <p>Error Loading Sites!</p>;
        const matches = sites.filter(
            ({ meta: { oer_proj_num } }) => oer_proj_num === TMP_NUMBER
        );

        if (matches.length === 1) {
            const match = deepCopy(matches[0]); // Prevent Mutating State
            // If The TMP Number Corresponds to a Site
            return (
                <Container>
                    <Link to='/sites'>
                        <Button variant='info' className='mt-2 w-100'>
                            Pick Another Site
                        </Button>
                    </Link>
                    {/* Render the Site Information */}
                    <NewSiteFormProvider>
                        <SiteMeta site={match} />
                    </NewSiteFormProvider>
                    {/* Render the Site Transfers */}
                    <TransferFormProvider>
                        <SiteTransfers site={match} />
                    </TransferFormProvider>
                    {/* Render the Site Availabilities/Requests */}
                    <SiteAvailabilityRequest site={match} />
                </Container>
            );
        }

        // Otherwise
        // Render an Error Message
        return (
            <>
                <Alert variant='danger'>
                    Error! Invalid TMP Number. Please Select Another Site
                </Alert>
                <Link to='/sites'>
                    <Button variant='info' className='mt-2 w-100'>
                        Pick Another Site
                    </Button>
                </Link>
            </>
        );
    }

    // If there is not a TMP Number
    // Show the Site Selector
    return (
        <Container>
            <h2>Select a Site</h2>
            <SiteSelector
                id='site-selector'
                placeholder='Site'
                mode='all'
                onChange={(v) => {
                    if (v) history.push(`/sites/${v.tmp}`);
                }} // When Site Selected, Push the Page to History
            />
        </Container>
    );
};
